import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	LiCompanyActivity,
	LiCompanyActivityResponse,
	LiCompanyActivityResponseSchema,
	LiCompanyActivitySchema,
	UpdateLiCompanyActivity,
} from "../../pages/LiCompanyActivity/schema/liCompanyActivity";
import {
	LiCompanyActivityEntryResultsResponse,
	LiCompanyActivityEntryResultsResponseSchema,
} from "../../pages/LiCompanyActivity/schema/liCompanyActivityEntryResult";

export const liCompanyActivityApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiCompanyActivitys: builder.query<LiCompanyActivityResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liCompanyActivity",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiCompanyActivityResponseSchema,
			}),
			providesTags: ["LiCompanyActivity"],
		}),
		getLiCompanyActivityResults: builder.query<
			LiCompanyActivityEntryResultsResponse,
			RequestPaginationParams & { id: number }
		>({
			query: ({ id, ...params }) => ({
				url: `liCompanyActivity/results/${id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiCompanyActivityEntryResultsResponseSchema,
			}),
			providesTags: ["LiCompanyActivity"],
		}),
		getLiCompanyActivity: builder.query<LiCompanyActivity, number>({
			query: (id: number) => ({
				url: `liCompanyActivity/${id}`,
				params: {
					id,
				},
				responseSchema: LiCompanyActivitySchema,
			}),
			providesTags: ["LiCompanyActivity"],
		}),
		createLiCompanyActivity: builder.mutation<
			Partial<LiCompanyActivity>,
			Partial<LiCompanyActivity>
		>({
			query: (body) => ({
				url: `liCompanyActivity`,
				method: "POST",
				body,
				responseSchema: LiCompanyActivitySchema,
			}),
			invalidatesTags: ["LiCompanyActivity"],
		}),
		updateLiCompanyActivity: builder.mutation<Partial<LiCompanyActivity>, UpdateLiCompanyActivity>({
			query: ({ id, ...body }) => ({
				url: `liCompanyActivity/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiCompanyActivitySchema,
			}),
			invalidatesTags: ["LiCompanyActivity"],
		}),
		toggleLiCompanyActivity: builder.mutation<
			Partial<LiCompanyActivity>,
			Partial<LiCompanyActivity>
		>({
			query: ({ id, ...body }) => ({
				url: `liCompanyActivity/toggle/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiCompanyActivitySchema,
			}),
			invalidatesTags: ["LiCompanyActivity"],
		}),
		deleteLiCompanyActivity: builder.mutation<Partial<LiCompanyActivity>, number>({
			query: (id) => ({
				url: `liCompanyActivity/${id}`,
				method: "DELETE",
				responseSchema: LiCompanyActivitySchema,
			}),
			invalidatesTags: ["LiCompanyActivity"],
		}),
	}),
});

export const {
	useGetLiCompanyActivitysQuery,
	useGetLiCompanyActivityResultsQuery,
	useLazyGetLiCompanyActivitysQuery,
	useGetLiCompanyActivityQuery,
	useUpdateLiCompanyActivityMutation,
	useDeleteLiCompanyActivityMutation,
	useToggleLiCompanyActivityMutation,
	useCreateLiCompanyActivityMutation,
} = liCompanyActivityApi;
export const accountApiReducer = liCompanyActivityApi.reducer;
export const accountApiMiddleware = liCompanyActivityApi.middleware;
