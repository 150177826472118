import { z } from "zod";

import { CustomColumnTypes } from "@/enum/list.enum";

export const ColumnConfigSchema = z.object({
	id: z.number(),
	listId: z.number(),
	name: z.string(),
	type: z.nativeEnum(CustomColumnTypes),
	config: z.any(),
});

export type ColumnConfig = z.infer<typeof ColumnConfigSchema>;
