import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Link, Snackbar, Stack, Typography } from "@mui/material";

import { LinkedIn } from "@mui/icons-material";

import { faCopy, faEnvelope } from "@fortawesome/pro-regular-svg-icons";

import { useEnrichPersonMutation } from "@/pages/Private/redux/apollo/apollo.api";
import { Icon, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { Spinner } from "@/components/Spinner/Spinner";

import { ApolloPeopleResponse, SavedApolloPeopleResponse } from "../schema/get-apollos";

export const ApolloTable: FunctionComponent<{
	data: ApolloPeopleResponse;
	handleSearch: () => void;
}> = ({ data, handleSearch }) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`prospectSearch.${key}`), [t]);

	const { page, setPage, limit, setLimit } = useSearch();
	const [enriched, setEnriched] = useState<SavedApolloPeopleResponse>();

	const [enrichPerson, { isLoading: enrichmentLoading }] = useEnrichPersonMutation();

	const [openAlert, setOpenAlert] = useState(false);

	const handleCopyClick = (text: string) => {
		navigator.clipboard.writeText(text);
		setOpenAlert(true);
	};

	const handleClose = () => {
		setOpenAlert(false);
	};

	const columns: TableColumn<SavedApolloPeopleResponse>[] = useMemo(() => {
		const handleEnrichPerson = async (row: SavedApolloPeopleResponse) => {
			setEnriched(row);

			console.log(row);

			await enrichPerson({
				name: row.name,
				linkedinUrl: row.linkedin_url,
				domain: row?.organization?.primary_domain || "",
			}).unwrap();

			handleSearch();
		};

		return [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name}
							</Typography>
							<Typography color="inherit" mb={0} variant="body2">
								{row?.title}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("linkedin"),
				format: (row) => (
					<Stack alignItems="flex-start" className="relative group" direction="row">
						<div>
							<Link href={row?.linkedin_url} target="_blank">
								<LinkedIn />
							</Link>
						</div>
						<div className="ml-2">
							{enrichmentLoading && enriched?.id === row.id ? (
								<Spinner color="blue" />
							) : row?.email ? (
								<>
									{row?.email}
									<Icon
										className="w-[14px] h-[14px] cursor-pointer ml-1 invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-opacity duration-200"
										icon={faCopy}
										onClick={() => handleCopyClick(row?.email)}
									/>
								</>
							) : row.name && row.organization?.primary_domain ? (
								<Icon
									className="w-[24px] h-[24px] cursor-pointer"
									icon={faEnvelope}
									onClick={() => handleEnrichPerson(row)}
								/>
							) : (
								""
							)}
							{}
						</div>
					</Stack>
				),
			},
			{
				label: ts("company"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						{row.organization?.logo_url ? (
							<Box
								component="img"
								height={"32px"}
								mr={1}
								src={row?.organization?.logo_url}
								sx={{ borderRadius: 1 }}
							/>
						) : (
							<></>
						)}
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.organization?.name}
							</Typography>
							<Typography color="inherit" mb={0} variant="body2">
								{row?.organization?.primary_domain}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("add"),
				format: (row) => (
					<Stack alignItems={"center"} direction="row" justifyContent="space-between">
						<Typography color="inherit" mb={0} mr={1} variant="body2">
							{row?.organization?.founded_year}
						</Typography>
					</Stack>
				),
			},
		];
	}, [ts, enrichPerson, handleSearch, enrichmentLoading, enriched]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(0);
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<>
			<Card>
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page)}
					hidePagination={true}
					itemCount={data.meta.total_entries ?? 0}
					limit={limit}
					page={page}
				/>
			</Card>

			<Snackbar
				autoHideDuration={2000}
				message="Copied to clipboard"
				open={openAlert}
				onClose={handleClose}
			/>
		</>
	);
};
