import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

// import { useCreateApolloMutation } from "@/pages/Private/redux/apollo/apollo.api";

import { useSaveSearchApolloPeopleMutation } from "@/pages/Private/redux/apollo/apollo.api";
import { ApolloPeopleRequestParams, ApolloSavePeopleRequestParams } from "@/types/apollo";
import { Radio } from "@/components/Radio/Radio";
import { ApolloType } from "@/enum/apollo.enum";

interface createApolloModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	config: ApolloPeopleRequestParams;
	amount: number;
}

export const CreateOrganizationModal: FunctionComponent<createApolloModalProps> = ({
	isOpen,
	setIsOpen,
	config,
	amount,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);
	// const [createApollo, { isLoading, error }] = useCreateApolloMutation();
	const [saveSearch, { isLoading, error }] = useSaveSearchApolloPeopleMutation();

	const [filter, setFilter] = useState<ApolloType>(ApolloType.SOURCE);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<ApolloSavePeopleRequestParams>({
		defaultValues: {
			name: "",
			type: ApolloType.SOURCE,
			config,
			leadsPerDay: 0,
			amount: amount || 0,
		},
	});

	useEffect(() => {
		setValue("type", filter);
	}, [filter, setValue]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: ApolloSavePeopleRequestParams) => {
		try {
			await saveSearch(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	// console.log(error, formErrors);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<TextField
					error={!!errors.leadsPerDay?.message}
					label={ts("leadsPerDay")}
					placeholder={ts("leadsPerDay")}
					{...register("leadsPerDay")}
				/>
				<Radio
					isChecked={filter === ApolloType.SOURCE}
					onChange={() => setFilter(ApolloType.SOURCE)}
				>
					Source list
				</Radio>
				<Radio
					isChecked={filter === ApolloType.ACCOUNT_FILTER}
					onChange={() => setFilter(ApolloType.ACCOUNT_FILTER)}
				>
					Account filter
				</Radio>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
