import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faPenToSquare, faTrashCan, faCopy } from "@fortawesome/pro-regular-svg-icons";

import { useNavigate } from "react-router-dom";

import {
	useDeleteApolloMutation,
	useDuplicateMutation,
	useGetApollosQuery,
} from "@/pages/Private/redux/apollo/apollo.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { ApolloType } from "@/enum/apollo.enum";
import { useStickyParams } from "@/utils/useStickyParams";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { ROUTE_CONFIG } from "@/routes/config";

import { Apollo } from "../schema/get-apollos";

export const ApolloSearchesTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`prospectSearch.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedApollo, setSelectedApollo] = useState<Apollo | null>(null);
	const [type, setType] = useState<string>("");
	const navigate = useNavigate();
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteApollo] = useDeleteApolloMutation();
	const [duplicate] = useDuplicateMutation();

	useStickyParams("search", setSearchValue, searchValue);
	useStickyParams("type", setType, type);

	const { data, isLoading } = useGetApollosQuery({
		page,
		limit,
		searchValue: query,
		type: type ? (type as ApolloType) : undefined,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const columns: TableColumn<any>[] = useMemo(
		() => [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`${ROUTE_CONFIG.APOLLO_SEARCH_PEOPLE}/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("type"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{ts(row?.type)}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("found"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.amount}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									cursor: "pointer",
									color: "primary.main",
								},
							}}
							onClick={() => duplicate(row?.id)}
						>
							<Icon icon={faCopy} size="xl" />
						</Box>
						{[
							ApolloType.SOURCE,
							ApolloType.LEAD_FILTER,
							ApolloType.COUNTER,
							ApolloType.LEAD_EXCLUSION,
						].includes(row.type) ? (
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										cursor: "pointer",
										color: "primary.main",
									},
								}}
								onClick={() => navigate(`${ROUTE_CONFIG.APOLLO_SEARCH_PEOPLE}/${row?.id}`)}
							>
								<Icon icon={faPenToSquare} size="xl" />
							</Box>
						) : (
							<></>
						)}

						{row.type === ApolloType.ACCOUNT_FILTER ? (
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										cursor: "pointer",
										color: "primary.main",
									},
								}}
								onClick={() => navigate(`/app/apollo/organization/${row?.id}`)}
							>
								<Icon icon={faPenToSquare} size="xl" />
							</Box>
						) : (
							<></>
						)}

						<PermissionCheck requiredPermissions={[PermissionRoles.DELETE_PITCHDECK]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[duplicate, navigate, ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: Apollo) => {
		setShowDeleteModal(true);
		setSelectedApollo(row);
	};

	const handleDeleteApollo = async () => {
		try {
			await deleteApollo(selectedApollo?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex align-baseline justify-between w-1/2 mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="flex items-end w-[250px]">
					<Dropdown
						data={[
							{
								title: t("basics.pleaseSelect"),
								id: 0,
							},
							{
								title: ts(ApolloType.SOURCE),
								id: ApolloType.SOURCE,
							},
							{
								title: ts(ApolloType.LEAD_FILTER),
								id: ApolloType.LEAD_FILTER,
							},
							{
								title: ts(ApolloType.LEAD_EXCLUSION),
								id: ApolloType.LEAD_EXCLUSION,
							},
							{
								title: ts(ApolloType.COUNTER),
								id: ApolloType.COUNTER,
							},
						]}
						floating={true}
						handleSelect={function (value?: AutoCompleteItem) {
							if (value?.id && value?.id !== 0) {
								setType(value.id as ApolloType);
							} else {
								setType("");
							}
						}}
						label="Type"
						value={type ? { title: type?.toString(), id: 1 } : undefined}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteApollo}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedApollo?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</div>
	);
};
