import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { SavedApolloSchema } from "./get-apollos";

const { REQUIRED } = ERROR_TYPE;

export const UserSchema = z.object({
	id: z.number().gt(0),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	user: z.object({
		id: z.number().gt(0),
		firstName: z.string().min(1),
		lastName: z.string().min(1),
	}),
});

export const OrganizationSchema = z.object({
	id: z.number(),
	organizationId: z.string(),
	name: z.string(),
	result: z.record(z.string(), z.string()),
	startup: z.boolean(),
	foundedYear: z.number().nullable(),
	logoUrl: z.string().optional().nullable(),
	estimatedNumEmployees: z.number().optional().nullable(),
	annualRevenue: z.number().optional().nullable(),
	linkedinUrl: z.string().optional().nullable(),
	websiteUrl: z.string().optional().nullable(),
	industry: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	state: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
	phone: z.string().optional().nullable(),
	primaryDomain: z.string().optional().nullable(),
	locations: z.number().optional().nullable(),
	companyLocations: z
		.array(
			z.object({
				city: z.string().nullable(),
				state: z.string().nullable(),
				country: z.string().nullable(),
			})
		)
		.optional()
		.nullable(),
});

export const PeopleSchema = z.object({
	id: z.number(),
	apolloId: z.string().nullable(),
	email: z.string().nullable(),
	name: z.string().min(4, { message: REQUIRED }).trim().nullable(),
	linkedinUrl: z.string().nullable(),
	firstName: z.string().optional().nullable(),
	lastName: z.string().optional().nullable(),
	title: z.string().optional().nullable(),
	blacklist: z.boolean().optional().nullable(),
	headline: z.string().optional().nullable(),
	result: z.record(z.string(), z.string()).nullable(),
	organizationIdAsString: z.string().optional().nullable(),
	emailStatus: z.string().optional().nullable(),
	seniority: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	state: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
	gender: z.string().optional().nullable(),
	genderProbability: z.number().optional().nullable(),
	users: z.array(UserSchema).optional().nullable(),
	language: z.string().optional().nullable(),
	image: z.string().optional().nullable(),
	phone: z.string().optional().nullable(),
	organization: OrganizationSchema.nullable(),
	workflow: z
		.object({
			id: z.number(),
			amountOfPeople: z.number(),
			leadsPerDay: z.number(),
			status: z.string(),
			apollo: SavedApolloSchema,
		})
		.nullable(),
});

export const PeopleResponseSchema = z.object({
	data: z.array(PeopleSchema),
	meta: MetaDataSchema,
});

export type People = z.infer<typeof PeopleSchema>;
export type Organization = z.infer<typeof OrganizationSchema>;

export type PeopleResponse = z.infer<typeof PeopleResponseSchema>;
export type UserSchemaType = z.infer<typeof UserSchema>;
