import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Loader, Modal, ChipsList } from "@/components";
import { ERROR_TYPE, getAllErrors, getAllUserRoles, renderErrorMessages } from "@/utils";
import { useGetRolesQuery, useInviteUserMutation } from "@/pages/Private/redux/admin/admin.api";
import { DEFAULT } from "@/constants";

import { InviteForm, InviteFormSchema } from "../schema/invite-user";

interface InviteUserModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	companyId?: number;
}

export const InviteUserModal: FunctionComponent<InviteUserModalProps> = ({
	isOpen,
	setIsOpen,
	companyId,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`inviteUser.modal.${key}`);

	const { data: roles } = useGetRolesQuery();
	const [invite, { isLoading, error }] = useInviteUserMutation();

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<InviteForm>({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			roles: [],
			companyId,
		},
		resolver: zodResolver(InviteFormSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const {
		fields: userRoles,
		remove,
		append,
	} = useFieldArray({
		control,
		name: "roles",
	});

	const rolesOptions = useMemo(() => {
		return (roles?.data ?? [])
			.filter((role) => !userRoles.some((userRole) => userRole.identifier === role.code))
			.filter((role) => !companyId || role.code !== "ADMIN");
	}, [roles, userRoles, companyId]);

	const onSubmit = async (values: InviteForm) => {
		const { roles: selectedRoles } = values;

		try {
			await invite({ ...values, roles: selectedRoles.map((role) => role.identifier) }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleUserRoleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const selectedRole = roles?.data.find((role) => role.code === event.target.value);

		if (selectedRole) {
			append(getAllUserRoles([selectedRole]));
		}
	};

	const handleUnselectRole = (code: string) => {
		const indexOfRemovedRole = userRoles.findIndex((role) => role.identifier === code);

		remove(indexOfRemovedRole);
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("title")}
		>
			{!roles ? (
				<Loader />
			) : (
				<Stack spacing={2}>
					<TextField
						error={!!errors.firstName?.message}
						label={t("basics.firstName")}
						placeholder={t("basics.firstName")}
						{...register("firstName")}
					/>

					<TextField
						error={!!errors.lastName?.message}
						label={t("basics.lastName")}
						placeholder={t("basics.lastName")}
						{...register("lastName")}
					/>

					<TextField
						error={!!errors.email?.message}
						label={t("basics.email")}
						placeholder={t("basics.email")}
						{...register("email")}
					/>

					<TextField
						select
						error={!!errors.roles?.message}
						label={t("basics.role")}
						value={DEFAULT}
						onChange={handleUserRoleChange}
					>
						<MenuItem disabled value={DEFAULT}>
							{ts("rolePlaceholder")}
						</MenuItem>

						{rolesOptions.map((role) => (
							<MenuItem key={role.id} value={role.code}>
								{role.name}
							</MenuItem>
						))}
					</TextField>

					<ChipsList handleDelete={handleUnselectRole} selectedItems={userRoles} />

					{getAllErrors(error, formErrors).length
						? renderErrorMessages(getAllErrors(error, formErrors))
						: null}
				</Stack>
			)}
		</Modal>
	);
};
