import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";

import { PermissionRoles } from "@/enum";

import { useHasPermissions } from "@/utils/useHasPermissions";

import { CompanyInformation } from "./components/CompanyInformation";
import { CompanyType } from "./schema/company";
import { Users } from "../Users/Users";
import { UserRoles } from "../UserRoles/UserRoles";
import { ApiKeyApollo } from "./components/ApiKeyApollo";
import { ApiKeyLemlist } from "./components/ApiKeyLemlist";
import { Requests } from "../Request/Requests";
import { BlacklistOverview } from "../Blacklist/pages/BlacklistOverview/BlacklistOverview";
import { Usage } from "../Usage/Usage";

enum Tabs {
	GENERAL = "GENERAL",
	USER = "USER",
	ROLES = "ROLES",
	API_KEYS = "API_KEYS",
	COSTS = "COSTS",
	USAGE = "USAGE",
	BLACKLIST = "BLACKLIST",
}

export const Company: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`company.${key}`);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.GENERAL);

	const { user } = useAppSelector(authSelector);

	const { hasPermissions } = useHasPermissions();

	let tabs = [
		{ label: ts("tabs.general"), value: Tabs.GENERAL, permission: PermissionRoles.UPDATE_USER },
		{ label: ts("tabs.user"), value: Tabs.USER, permission: PermissionRoles.UPDATE_USER },
		{ label: ts("tabs.roles"), value: Tabs.ROLES, permission: PermissionRoles.UPDATE_USER },
		{ label: ts("tabs.apiKeys"), value: Tabs.API_KEYS, permission: PermissionRoles.UPDATE_USER },
		{ label: ts("tabs.costs"), value: Tabs.COSTS, permission: PermissionRoles.GLOBAL_ADMIN },
		{ label: ts("tabs.usage"), value: Tabs.USAGE, permission: PermissionRoles.LIST },
		{
			label: ts("tabs.blacklist"),
			value: Tabs.BLACKLIST,
			permission: PermissionRoles.BLACKLIST,
		},
	];

	tabs = tabs.filter((tab) => hasPermissions([tab.permission]));

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<Typography mb={4} variant="h4">
				{ts("title")}
			</Typography>

			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab key={index} label={tab.label} value={tab.value} />
					))}
				</TabList>

				<TabPanel sx={{ p: 0 }} value={Tabs.GENERAL}>
					<Stack spacing={4}>
						<CompanyInformation company={user?.company as CompanyType} />
					</Stack>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.USER}>
					<Users />
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.ROLES}>
					<UserRoles />
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.COSTS}>
					<Requests />
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.USAGE}>
					<Usage />
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.BLACKLIST}>
					<BlacklistOverview />
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.API_KEYS}>
					<Stack spacing={4}>
						<ApiKeyApollo company={user?.company as CompanyType} />
						<ApiKeyLemlist company={user?.company as CompanyType} />
					</Stack>
				</TabPanel>
			</TabContext>
		</>
	);
};
