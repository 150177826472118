import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";

import { Modal } from "@/components";

import {
	useCreateLiPeopleMutation,
	useLazyGetSearchPersonSuggestionQuery,
} from "@/pages/Private/redux/liPeople/liPeople.api";

import {
	LinkedinPersonAutoComplete,
	LinkedinPersonAutoCompleteItem,
} from "@/components/LinkedinPersonAutoComplete/LinkedinPersonAutoComplete";

import { useSearch } from "@/utils";

import { Radio } from "@/components/Radio/Radio";

import { LiEventType } from "@/enum/lievent.enum";

import { CreateLiPeople, CreateLiPeopleSchema, Suggestion } from "../schema/liPeople";
import { LinkedinParams, LinkedinTagAutocomplete } from "./LinkedinTagAutocomplete";

interface createLiPeopleModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateLiPeopleModal: FunctionComponent<createLiPeopleModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPeople.${key}`);
	const [createLiPeople, { isLoading }] = useCreateLiPeopleMutation();
	const [tempUrls, setTempUrls] = useState<string>();
	const [filter, setFilter] = useState<LiEventType>(LiEventType.SEARCH);
	const [schools, setSchools] = useState<LinkedinParams[]>();
	const [locations, setLocations] = useState<LinkedinParams[]>();
	const [languages, setLanguages] = useState<LinkedinParams[]>();
	const [serviceCategories, setServiceCategories] = useState<LinkedinParams[]>();
	const [industries, setIndustries] = useState<LinkedinParams[]>();
	const [followers, setFollowers] = useState<LinkedinPersonAutoCompleteItem[]>();

	const [follower, setFollower] = useState<LinkedinPersonAutoCompleteItem>();

	const { query, setSearchValue } = useSearch();

	const [fetchTags] = useLazyGetSearchPersonSuggestionQuery();

	useEffect(() => {
		fetchTags({ keyword: query }).then((results) => {
			const optionValues = results?.data?.people?.map((res) => {
				return {
					title: res.fullName,
					id: res.navigationUrl,
					icon: res.profilePicture,
				};
			});

			if (optionValues) {
				setFollowers([...optionValues]);
			}
		});
	}, [fetchTags, query]);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateLiPeople>({
		defaultValues: {
			name: "",
			urls: [],
		},
		resolver: zodResolver(CreateLiPeopleSchema),
	});

	const handleUrlsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
	};

	const handleUrlsOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
		setValue(
			"urls",
			event.target.value?.includes(",")
				? event.target.value?.split(",")
				: event.target.value?.includes("\n")
				? event.target.value?.split("\n")
				: [event.target.value]
		);
	};

	const onSubmit = async (values: CreateLiPeople) => {
		try {
			const newValue = {
				...values,
				schoolList: schools?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				locationList: locations?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				industryList: industries?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				serviceCategoryList: serviceCategories?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				languageList: languages?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				follows: follower
					? {
							fullName: follower.title,
							navigationUrl: follower.id,
							profilePicture: follower.icon,
					  }
					: undefined,
			};

			await createLiPeople(newValue).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	// console.log(error, formErrors);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<div className="flex flex-col">
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<div className="flex flex-col">
					<div className="mb-4">
						<Radio
							isChecked={filter === LiEventType.SEARCH}
							onChange={() => setFilter(LiEventType.SEARCH)}
						>
							{ts("defaultSearch")}
						</Radio>
					</div>
					<div className="mb-4">
						<Radio
							isChecked={filter === LiEventType.SPECIFIC}
							onChange={() => setFilter(LiEventType.SPECIFIC)}
						>
							{ts("specific")}
						</Radio>
					</div>
				</div>

				{filter === LiEventType.SPECIFIC && (
					<TextField
						multiline
						label={ts("urls")}
						placeholder={ts("urls")}
						required={false}
						value={tempUrls}
						onBlur={handleUrlsOnBlur}
						onChange={handleUrlsChange}
					/>
				)}

				{filter === LiEventType.SEARCH && (
					<>
						<div className="flex flex-row">
							<div className="flex flex-col  w-[50%]">
								<label className="text-sm mb-1 mt-1 block">{ts("personalDetails")}</label>

								<TextField
									error={!!errors.keyword?.message}
									label={ts("keyword")}
									placeholder={ts("keyword")}
									{...register("keyword")}
								/>

								<TextField
									error={!!errors.titleFreeText?.message}
									label={ts("titleFreeText")}
									placeholder={ts("titleFreeText")}
									{...register("titleFreeText")}
									required={false}
								/>

								<div className="text-sm flex justify-between">
									<TextField
										error={!!errors.firstName?.message}
										label={ts("firstName")}
										placeholder={ts("firstName")}
										style={{ width: "48%" }}
										{...register("firstName")}
										required={false}
									/>

									<TextField
										error={!!errors.lastName?.message}
										label={ts("lastName")}
										placeholder={ts("lastName")}
										style={{ width: "48%" }}
										{...register("lastName")}
										required={false}
									/>
								</div>

								<TextField
									error={!!errors.companyFreeText?.message}
									label={ts("companyFreeText")}
									placeholder={ts("companyFreeText")}
									{...register("companyFreeText")}
									required={false}
								/>

								<TextField
									error={!!errors.schoolFreeText?.message}
									label={ts("schoolFreeText")}
									placeholder={ts("schoolFreeText")}
									{...register("schoolFreeText")}
									required={false}
								/>
							</div>

							<div className="flex flex-col ml-4 w-[48%]">
								<label className="text-sm mb-1 mt-1">{ts("specificTags")}</label>
								<LinkedinTagAutocomplete
									handleValueChange={(value: LinkedinParams[]) => {
										setSchools(value);
									}}
									type={"school"}
									value={schools}
								/>

								<LinkedinTagAutocomplete
									handleValueChange={(value: LinkedinParams[]) => {
										setLocations(value);
									}}
									type={"location"}
									value={locations}
								/>

								<LinkedinTagAutocomplete
									handleValueChange={(value: LinkedinParams[]) => {
										setIndustries(value);
									}}
									type={"industry"}
									value={industries}
								/>

								<LinkedinTagAutocomplete
									handleValueChange={(value: LinkedinParams[]) => {
										setServiceCategories(value);
									}}
									type={"serviceCategory"}
									value={serviceCategories}
								/>

								<LinkedinTagAutocomplete
									handleValueChange={(value: LinkedinParams[]) => {
										setLanguages(value);
									}}
									type={"language"}
									value={languages}
								/>

								<label className="text-sm mb-1">{ts("followerOf")}</label>
								<LinkedinPersonAutoComplete
									data={followers || []}
									handleSearch={(val) => setSearchValue(val)}
									handleSelect={function (
										value?: LinkedinPersonAutoCompleteItem | undefined
									): void {
										setFollower(value);
									}}
									selectedItem={follower}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};
