import { z } from "zod";

import { MapBusinessStatus, MapScrapeType } from "@/enum/map.enum";

import { MetaDataSchema } from "@/schemas/meta-data";

import { Enrichments } from "@/enum/enrichments.enum";

import { ListStatus } from "@/enum/list.enum";

import { JobsScrapeStatus } from "@/enum/scrape-enum";

import { ApolloOrgSchema } from "../../Apollo/schema/apolloOrg";

import { ApolloPeopleResponseSchema } from "../../Apollo/schema/get-apollos";

export const MapBusinessSchema = z.object({
	id: z.number(),
	businessId: z.number(),
	googleId: z.number().nullable(),
	placeId: z.string().nullable(),
	map: z.object({
		id: z.number(),
		name: z.string(),
		query: z.string(),
		limit: z.number().nullable(),
		lat: z.number().nullable(),
		lng: z.number().nullable(),
		zoom: z.number().nullable(),
		language: z.string().nullable(),
		region: z.string().nullable(),
		subtypes: z.array(z.string()),
		status: z.nativeEnum(JobsScrapeStatus),
		mapScrapeType: z.nativeEnum(MapScrapeType),
		activeStatus: z.nativeEnum(ListStatus),
		repeatDaily: z.boolean(),
	}),
	googleMid: z.string().nullable(),
	phoneNumber: z.string().nullable(),
	name: z.string().nullable(),
	lat: z.number().nullable(),
	lng: z.number().nullable(),
	fullAddress: z.string().nullable(),
	reviewCount: z.number().nullable(),
	rating: z.number().nullable(),
	timezone: z.string().nullable(),
	verified: z.boolean().nullable(),
	placeLink: z.string().nullable(),
	cid: z.string().nullable(),
	reviewsLink: z.string().nullable(),
	ownerLink: z.string().nullable(),
	ownerId: z.string().nullable(),
	ownerName: z.string().nullable(),
	bookingLink: z.string().nullable(),
	reservationLink: z.string().nullable(),
	mapBusinessStatus: z.nativeEnum(MapBusinessStatus),
	// type: z.string().nullable(),
	subtypes: z.string().nullable(),
	photo: z.string().nullable(),
	reviewsFor1: z.number().nullable(),
	reviewsFor2: z.number().nullable(),
	reviewsFor3: z.number().nullable(),
	reviewsFor4: z.number().nullable(),
	reviewsFor5: z.number().nullable(),
	summary: z.string().nullable(),
	details: z.string().nullable(),
	mapBusinessLink: z.string().nullable(),
	companyLogo: z.string().nullable(),
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	linkedinUrl: z.string().nullable(),
	facebookUrl: z.string().nullable(),
	instagramUrl: z.string().nullable(),
	yelpUrl: z.string().nullable(),
	tiktokUrl: z.string().nullable(),
	snapchatUrl: z.string().nullable(),
	twitterUrl: z.string().nullable(),
	githubUrl: z.string().nullable(),
	youtubeUrl: z.string().nullable(),
	pinterestUrl: z.string().nullable(),
	email: z.string().nullable(),
	website: z.string().nullable(),
	companyEmail: z.string().nullable(),
	companyLocation: z.string().nullable(),
	companyCity: z.string().nullable(),
	companyState: z.string().nullable(),
	companyCountry: z.string().nullable(),
	companyName: z.string().nullable(),
	cleanedCompanyName: z.string().nullable(),
	companyDomain: z.string().nullable(),
	title: z.string().nullable(),
	headline: z.string().nullable(),
	location: z.string().nullable(),
	city: z.string().nullable(),
	state: z.string().nullable(),
	country: z.string().nullable(),
	employmentHistory: z.any().nullable(),
	educationHistory: z.any().nullable(),
	status: z.nativeEnum(Enrichments),
	prospect: ApolloPeopleResponseSchema.optional(),
	organization: ApolloOrgSchema.optional(),
	mapId: z.number(),
	type: z.string().nullable(),
	companyLinkedinUrl: z.string().nullable(),
});

export const MapBusinesssResponseSchema = z.object({
	data: z.array(MapBusinessSchema),
	meta: MetaDataSchema,
});

export type MapBusiness = z.infer<typeof MapBusinessSchema>;

export type MapBusinesssResponse = z.infer<typeof MapBusinesssResponseSchema>;
