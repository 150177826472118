import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	LiProfile,
	LiProfileResponse,
	LiProfileResponseSchema,
	LiProfileSchema,
	UpdateLiProfile,
} from "../../pages/LiProfile/schema/liProfile";
import {
	LiProfileEntryResultsResponse,
	LiProfileEntryResultsResponseSchema,
} from "../../pages/LiProfile/schema/liProfileEntryResults";

export const liProfileApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLiProfiles: builder.query<LiProfileResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "liProfile",
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiProfileResponseSchema,
			}),
			providesTags: ["LiProfile"],
		}),
		getLiProfile: builder.query<LiProfile, number>({
			query: (id: number) => ({
				url: `liProfile/${id}`,
				params: {
					id,
				},
				responseSchema: LiProfileSchema,
			}),
			providesTags: ["LiProfile"],
		}),
		getLiProfileResults: builder.query<
			LiProfileEntryResultsResponse,
			RequestPaginationParams & { id: number }
		>({
			query: ({ id, ...params }) => ({
				url: `liProfile/results/${id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: LiProfileEntryResultsResponseSchema,
			}),
			providesTags: ["LiProfile"],
		}),
		createLiProfile: builder.mutation<Partial<LiProfile>, Partial<LiProfile>>({
			query: (body) => ({
				url: `liProfile`,
				method: "POST",
				body,
				responseSchema: LiProfileSchema,
			}),
			invalidatesTags: ["LiProfile"],
		}),
		updateLiProfile: builder.mutation<Partial<LiProfile>, UpdateLiProfile>({
			query: ({ id, ...body }) => ({
				url: `liProfile/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiProfileSchema,
			}),
			invalidatesTags: ["LiProfile"],
		}),
		toggleLiProfile: builder.mutation<Partial<LiProfile>, Partial<LiProfile>>({
			query: ({ id, ...body }) => ({
				url: `liProfile/toggle/${id}`,
				method: "PATCH",
				body,
				responseSchema: LiProfileSchema,
			}),
			invalidatesTags: ["LiProfile"],
		}),
		deleteLiProfile: builder.mutation<Partial<LiProfile>, number>({
			query: (id) => ({
				url: `liProfile/${id}`,
				method: "DELETE",
				responseSchema: LiProfileSchema,
			}),
			invalidatesTags: ["LiProfile"],
		}),
	}),
});

export const {
	useGetLiProfilesQuery,
	useLazyGetLiProfilesQuery,
	useGetLiProfileQuery,
	useGetLiProfileResultsQuery,
	useUpdateLiProfileMutation,
	useDeleteLiProfileMutation,
	useToggleLiProfileMutation,
	useCreateLiProfileMutation,
} = liProfileApi;
export const accountApiReducer = liProfileApi.reducer;
export const accountApiMiddleware = liProfileApi.middleware;
