import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

import { useLazyGetApolloTagsQuery } from "@/pages/Private/redux/apollo/apollo.api";
import { useSearch } from "@/utils";

interface ApolloParams {
	name: string;
	id: string;
}

interface ApolloTagAutocompleteProps {
	value?: string[];
	kind: string;
	type: string;
	handleValueChange: (value: ApolloParams[]) => void;
}

export const ApolloTagAutocomplete: FunctionComponent<ApolloTagAutocompleteProps> = ({
	value,
	kind,
	type,
	handleValueChange,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`prospectSearch.${key}`);
	const { query, setSearchValue } = useSearch();
	const [inputValue, setInputValue] = useState<{ kind: string; q_tag_fuzzy_name: string }>({
		kind,
		q_tag_fuzzy_name: "",
	});
	const [options, setOptions] = useState<ApolloParams[]>([]);
	const [defaults, setDefaults] = useState<ApolloParams[]>([]);
	const [fetchTags] = useLazyGetApolloTagsQuery();

	useEffect(() => {
		setInputValue({
			kind,
			q_tag_fuzzy_name: query,
		});
	}, [query, kind]);

	useEffect(() => {
		if (value) {
			setDefaults(
				value?.map((value) => {
					return { name: value, id: value };
				}) || []
			);
		}
	}, [value]);

	useEffect(() => {
		if (inputValue) {
			fetchTags({ kind, q_tag_fuzzy_name: inputValue.q_tag_fuzzy_name }).then((results) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const optionValues = results?.data?.data?.map((res: any) => {
					return {
						name: res?.cleaned_name,
						id: res.cleaned_name,
					};
				});

				if (optionValues) {
					setOptions([
						...optionValues,
						{ name: inputValue.q_tag_fuzzy_name, id: inputValue.q_tag_fuzzy_name },
					]);
				} else {
					setOptions([{ name: inputValue.q_tag_fuzzy_name, id: inputValue.q_tag_fuzzy_name }]);
				}
			});
		}
	}, [inputValue, fetchTags, kind]);

	return (value && defaults) || !value ? (
		<>
			<Autocomplete
				multiple
				defaultValue={[]}
				getOptionLabel={(option) => option.name}
				id={type}
				options={options}
				renderInput={(params) => <TextField {...params} label={ts(`${type}.label`)} />}
				size="small"
				sx={{
					width: 250,
				}}
				value={defaults}
				onChange={(event, value) => {
					handleValueChange(value);
				}}
				onInputChange={(event, newInputValue) => {
					setSearchValue(newInputValue);
				}}
			/>
		</>
	) : (
		<></>
	);
};
