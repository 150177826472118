import { api } from "@/redux/api";

import { UsageResponse, UsageResponseSchema } from "../../pages/Usage/schema/usage";

export const usageApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUsage: builder.query<UsageResponse, void>({
			query: () => ({
				url: "list/usage",
				responseSchema: UsageResponseSchema,
			}),
			providesTags: ["Usage"],
		}),
	}),
});

export const { useGetUsageQuery } = usageApi;
export const accountApiReducer = usageApi.reducer;
export const accountApiMiddleware = usageApi.middleware;
