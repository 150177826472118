import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteLiPostMutation,
	useGetLiPostsQuery,
	useToggleLiPostMutation,
} from "@/pages/Private/redux/liPost/liPost.api";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";

import { ListStatus } from "@/enum/list.enum";

import { LiPost } from "../schema/liPost";
import { EditLiPostModal } from "./EditLiPostModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiPostTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`liPost.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [selectedLiPost, setSelectedLiPost] = useState<LiPost | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteLiPost] = useDeleteLiPostMutation();
	const [toggleLiPost] = useToggleLiPostMutation();

	const { data, isLoading } = useGetLiPostsQuery({
		page,
		limit,
		searchValue: query,
	});

	const handleToggle = async (id: number, status: ListStatus) => {
		const updater: Partial<LiPost> = {
			id: id,
		};

		if (status === ListStatus.ACTIVE) {
			updater.activeStatus = ListStatus.PAUSED;
		} else if (status === ListStatus.PAUSED) {
			updater.activeStatus = ListStatus.ACTIVE;
		}

		await toggleLiPost({ ...updater }).unwrap();
	};

	const columns: TableColumn<LiPost>[] = useMemo(
		() => [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row.id, row.activeStatus);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/linkedin-posts/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_EVENT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleEditModal(row)}
							>
								<Icon icon={faPen} size="xl" />
							</Box>
						</PermissionCheck>
						<PermissionCheck requiredPermissions={[PermissionRoles.LI_EVENT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleEditModal = (row: LiPost) => {
		setShowEditModal(true);
		setSelectedLiPost(row);
	};

	const handleDeleteModal = (row: LiPost) => {
		setShowDeleteModal(true);
		setSelectedLiPost(row);
	};

	const handleDeleteLiPost = async () => {
		try {
			await deleteLiPost(selectedLiPost?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteLiPost}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedLiPost?.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}

			{showEditModal && selectedLiPost && (
				<EditLiPostModal
					isOpen={showEditModal}
					liPost={selectedLiPost}
					setIsOpen={setShowEditModal}
				/>
			)}
		</div>
	);
};
