import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiProfileEntryType } from "@/enum/liprofile.enum";

import { ListStatus } from "@/enum/list.enum";

import { LiProfileEntrySchema } from "./liProfileEntry";

const { REQUIRED } = ERROR_TYPE;

export const LiProfileSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(LiProfileEntryType),
	activeStatus: z.nativeEnum(ListStatus),
	liProfileEntries: z.array(LiProfileEntrySchema).optional(),
});

export const LiProfilePostsSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfilePostsEntries: z.array(LiProfileEntrySchema).optional(),
});

export const LiProfileCommentsSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfileCommentsEntries: z.array(LiProfileEntrySchema).optional(),
});

export const LiProfileLikesToCommentSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfileCommentsEntries: z.array(LiProfileEntrySchema).optional(),
});

export const LiProfileLikesToPostSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfileCommentsEntries: z.array(LiProfileEntrySchema).optional(),
});

export const LiProfileCommentToPostSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	liProfileCommentsEntries: z.array(LiProfileEntrySchema).optional(),
});

export const CreateLiProfileSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(LiProfileEntryType),
});

export const UpdateLiProfileSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(LiProfileEntryType),
});

export const LiProfileResponseSchema = z.object({
	data: z.array(LiProfileSchema),
	meta: MetaDataSchema,
});

export type LiProfile = z.infer<typeof LiProfileSchema>;

export type CreateLiProfile = z.infer<typeof CreateLiProfileSchema>;

export type UpdateLiProfile = z.infer<typeof UpdateLiProfileSchema>;

export type LiProfileResponse = z.infer<typeof LiProfileResponseSchema>;
