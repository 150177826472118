import { FunctionComponent, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { CloseRounded, Menu } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import { classNames } from "@/utils/classNames";

import AppLogo from "@/assets/images/logo-small-blue.svg";
import { ROUTE_CONFIG } from "@/routes/config";

import LogoutIcon from "@assets/icons/logout.svg";

import { useNavigationGetter } from "@/constants/sidenav.config";

import { useHasPermissions } from "@/utils/useHasPermissions";

import { setAuth } from "@/pages/Public/redux/auth.slice";

import { useAppDispatch } from "@/redux/hooks";

import { ReactComponent as Sitemap } from "@assets/icons/sidebar/site-map.svg";

import { Logo } from "../Logo/Logo";
import { SidebarItem } from "../SidebarItem/SidebarItem";
import { Icon } from "../Icon/Icon";
import { SvgIcon } from "../Icon/SvgIcon";

const { LISTS } = ROUTE_CONFIG;

export const Sidebar: FunctionComponent = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { hasPermissions } = useHasPermissions();
	const ts = (key: string) => t(`sidebar.${key}`);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [showMiddle, setShowMiddle] = useState(false);

	const NavItemConfig = useNavigationGetter();

	const handleNavigateHome = () => {
		navigate(LISTS);
	};

	useEffect(() => {
		setSidebarOpen(false);
	}, [pathname]);

	const items = NavItemConfig.ALL;

	let initialUser = localStorage.getItem("initialUser");

	if (initialUser) {
		initialUser = JSON.parse(initialUser);
	}

	const resetInitialUser = () => {
		dispatch(setAuth(initialUser));
		localStorage.removeItem("initialUser");
	};

	return (
		<>
			{/* <div
				className={classNames(
					"sm:shadow-sm w-full sm:py-4 sm:bg-white px-4 py-2 md:py-5 h-full md:px-6 lg:hidden flex items-center justify-between bg-rb-gray z-30 print:hidden",
					!!initialUser ? "bg-mb-dark-green" : ""
				)}
			>
				<div className="h-[24px] w-[210px]">
					<img
						alt="logo"
						className="w-auto h-full cursor-pointer"
						src={AppLogo}
						onClick={handleNavigateHome}
					/>
				</div>
				<div className={classNames("text-white")} onClick={() => setSidebarOpen(!sidebarOpen)}>
					{sidebarOpen ? <CloseRounded width={22} /> : <Menu width={22} />}
				</div>
			</div> */}
			<div
				className={classNames(
					"sm:z-[21] flex-shrink-0 w-[280px] 13i:w-[293px] relative pt-0 right-0 duration-200 ease-in-out z-20 print:hidden overflow-auto bb-scrollbar flex flex-col",
					// sidebarOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0",
					"h-[calc(100vh)]",
					"bg-brand-white"
				)}
			>
				<div className="h-screen">
					<Logo handleClick={handleNavigateHome} />
					<div className="flex flex-col justify-between h-90">
						<div className="px-8">
							<div className="border-t border-t-gray-200">
								<div className="mt-[12px] ">
									{items.upper.map((item) => (
										<SidebarItem
											key={item.path}
											icon={item.icon}
											iconActive={item.iconActive}
											path={item.path}
											title={item.title}
											type={item.type || "icon"}
										/>
									))}
								</div>
							</div>
						</div>

						<div className="px-[30px] pb-3">
							<div className="border-t border-t-gray-200">
								<div
									className="cursor-pointer mt-[4px]  mb-1 w-full h-10 rounded-[4px] flex items-center justify-between px-2"
									onClick={() => setShowMiddle(!showMiddle)}
								>
									<div className="flex flex-row items-center">
										<div className="w-[33px]">
											<SvgIcon className="w-[24px] h-[24px] text-gray-700" svgIcon={Sitemap} />
										</div>
										<span className="text-gray-700 text-sm mr-2">{ts("dataSources")}</span>
									</div>
									<Icon
										className={classNames(
											"h-[14px]",
											showMiddle ? "transform rotate-0" : "transform rotate-180"
										)}
										color={"gray"}
										icon={faChevronDown}
									/>
								</div>
								<div className="ml-4">
									{showMiddle &&
										items.middle?.map((item) => (
											<SidebarItem
												key={item.path}
												icon={item.icon}
												iconActive={item.iconActive}
												path={item.path}
												title={item.title}
												type={item.type}
											/>
										))}
								</div>
							</div>
							{items.lower
								.filter((item) => {
									if (item.permission) {
										return hasPermissions([item.permission]);
									} else {
										return true;
									}
								})
								.map((item) => (
									<SidebarItem
										key={item.path}
										icon={item.icon}
										iconActive={item.iconActive}
										path={item.path}
										title={item.title}
										type={item.type}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
