import { IFilter } from "./general-filter";

const reviewCount: IFilter = {
	name: "reviewCount",
	filterParam: "map_business.reviewCount",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const rating: IFilter = {
	name: "rating",
	filterParam: "map_business.rating",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const name: IFilter = {
	name: "name",
	filterParam: "map_business.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const email: IFilter = {
	name: "email",
	filterParam: "map_business.email",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyEmail: IFilter = {
	name: "companyEmail",
	filterParam: "map_business.companyEmail",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyName: IFilter = {
	name: "companyName",
	filterParam: "map_business.companyName",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyCountry: IFilter = {
	name: "companyCountry",
	filterParam: "map_business.companyCountry",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const type: IFilter = {
	name: "type",
	filterParam: "map_business.type",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const facebookUrl: IFilter = {
	name: "facebookUrl",
	filterParam: "map_business.facebookUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const instagramUrl: IFilter = {
	name: "instagramUrl",
	filterParam: "map_business.instagramUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const inclusionStatus: IFilter = {
	name: "inclusionStatus",
	filterParam: "list_person.inclusionStatus",
	filterByType: ["dropdown"],
	filterByValue: [],
};

export const MapsFilter: IFilter[] = [
	name,
	email,
	companyName,
	companyCountry,
	type,
	reviewCount,
	rating,
	companyEmail,
	facebookUrl,
	instagramUrl,
	inclusionStatus,
];
