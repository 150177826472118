import { IFilter } from "./general-filter";

const jobTitle: IFilter = {
	name: "jobTitle",
	filterParam: "offer.jobTitle",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

export const JobsFilter: IFilter[] = [jobTitle];
