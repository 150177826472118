import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useUpdateCompaniesMutation } from "@/pages/Private/redux/companies/companies.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Companies, UpdateCompanies, UpdateCompaniesSchema } from "../schema/companies";

interface updateCompaniesModalProps {
	companies: Companies;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateCompaniesModal: FunctionComponent<updateCompaniesModalProps> = ({
	companies,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`companies.${key}`);
	const [update, { isLoading, error }] = useUpdateCompaniesMutation();

	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<UpdateCompanies>({
		defaultValues: {
			name: companies.name,
			linkedinUrl: companies.linkedinUrl || "",
			autoInvite: companies.autoInvite || false,
		},
		resolver: zodResolver(UpdateCompaniesSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: UpdateCompanies) => {
		try {
			await update({ ...values, id: companies.id }).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("update.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					fullWidth
					error={!!errors.linkedinUrl?.message}
					label={ts("linkedinUrl")}
					placeholder={ts("linkedin url of your company")}
					size="small"
					variant="filled"
					{...register("linkedinUrl")}
				/>

				<Checkbox
					className="mb-8"
					isChecked={getValues("autoInvite")}
					onChange={() =>
						setValue("autoInvite", !getValues("autoInvite"), {
							shouldDirty: true,
							shouldValidate: true,
						})
					}
				>
					{ts("autoInvite")}
				</Checkbox>
			</Stack>
			{getAllErrors(error, formErrors).length ? (
				<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
			) : null}
		</Modal>
	);
};
