import { Chip } from "@mui/material";
import { FunctionComponent } from "react";

import { JobScrapeStatus } from "@/enum/scrape-enum";
import { Spinner } from "@/components/Spinner/Spinner";

import { CustomEventJob } from "../schema/customEventPerson";
import { CustomEvent } from "../schema/customEvent";

interface JobsScrapeStatusProps {
	row: CustomEvent;
}

export const CustomEventStatus: FunctionComponent<JobsScrapeStatusProps> = ({ row }) => {
	const { person } = row;

	const draft = person.some((person: CustomEventJob) =>
		[JobScrapeStatus.DRAFT].includes(person.status)
	);

	const scheduled = person.some((person: CustomEventJob) =>
		[JobScrapeStatus.SCRAPED].includes(person.status)
	);

	const done = person.some((person: CustomEventJob) =>
		[JobScrapeStatus.APOLLO_ENRICHED].includes(person.status)
	);

	if (draft && !scheduled && !done) {
		return <Chip color="info" label="Not yet scraped" />;
	}

	if (draft && scheduled && !done) {
		return <Chip color="primary" label="Scheduled" />;
	}

	if (scheduled && done) {
		return (
			<div className="invert">
				<Spinner />
			</div>
		);
	}

	if (!scheduled && done) {
		return <Chip color="success" label="Done" />;
	}

	return <></>;
};
