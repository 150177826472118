import { z } from "zod";

import { UserStatus } from "@/enum";
import { FetchMeResponseSchema } from "@/pages/Public/pages/Login/schema/login";
import { ChipsListItemSchema } from "@/schemas/chips";
import { ERROR_TYPE } from "@/utils";
import { UserLanguage } from "@/enum/user.enum";

const { REQUIRED, INVALID_EMAIL } = ERROR_TYPE;

export const UserSchema = z.object({
	id: z.number().gt(0),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	email: z.string().email(INVALID_EMAIL),
	linkedinUrl: z.string().optional().nullable(),
	is2FAEnabled: z.boolean(),
	isBlocked: z.boolean(),
	status: z.nativeEnum(UserStatus),
	userlanguage: z.nativeEnum(UserLanguage),
	autoAccept: z.boolean(),
	profileVisits: z.boolean(),
	connectionScraping: z.boolean(),
});

export const InviteRequestSchema = z.object({
	firstName: z.string().min(4, { message: REQUIRED }).trim(),
	lastName: z.string().min(4, { message: REQUIRED }).trim(),
	linkedinUrl: z.string().trim().optional(),
	email: z.string().email(INVALID_EMAIL),
	roles: z.array(z.string()).min(1, { message: REQUIRED }),
});

export const CustomerSchema = z.object({
	firstName: z.string().min(4, { message: REQUIRED }).trim(),
	lastName: z.string().min(4, { message: REQUIRED }).trim(),
	email: z.string().email(INVALID_EMAIL),
});

export const InviteFormSchema = InviteRequestSchema.omit({ roles: true }).extend({
	roles: ChipsListItemSchema.array().min(1, { message: REQUIRED }),
	companyId: z.number().optional(),
});

export const InviteResponseSchema = FetchMeResponseSchema.partial();

export type InviteRequest = z.infer<typeof InviteRequestSchema>;
export type InviteForm = z.infer<typeof InviteFormSchema>;
export type Customer = z.infer<typeof CustomerSchema>;
