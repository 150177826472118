import { z } from "zod";

import { JobScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ApolloSearchResultPersonSchema } from "../../Apollo/schema/get-apollos";

const { REQUIRED } = ERROR_TYPE;

export const CustomEventJobSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	linkedinUrl: z.string().nullable(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	companyName: z.string().trim().nullable(),
	status: z.nativeEnum(JobScrapeStatus),
	prospect: z.record(ApolloSearchResultPersonSchema).nullable(),
});

export const CustomEventJobsResponseSchema = z.object({
	data: z.array(CustomEventJobSchema),
	meta: MetaDataSchema,
});

export type CustomEventJob = z.infer<typeof CustomEventJobSchema>;

export type CustomEventJobsResponse = z.infer<typeof CustomEventJobsResponseSchema>;
