import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/system";

import { TextField } from "@mui/material";

import { Modal } from "@/components";

import { useCreateCompaniesMutation } from "@/pages/Private/redux/companies/companies.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { CreateCompanies, CreateCompaniesSchema } from "../schema/companies";

interface createCompaniesModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateCompaniesModal: FunctionComponent<createCompaniesModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`companies.${key}`);
	const [createCompanies, { isLoading, error }] = useCreateCompaniesMutation();

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<CreateCompanies>({
		defaultValues: {
			name: undefined,
			linkedinUrl: undefined,
		},
		resolver: zodResolver(CreateCompaniesSchema),
	});

	const onSubmit = async (values: CreateCompanies) => {
		try {
			await createCompanies(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<TextField
					fullWidth
					error={!!errors.linkedinUrl?.message}
					label={ts("linkedinUrl")}
					placeholder={ts("linkedin url of your company")}
					size="small"
					variant="filled"
					{...register("linkedinUrl")}
				/>
			</Stack>
			{getAllErrors(error, formErrors).length ? (
				<Box sx={{ mt: 2 }}>{renderErrorMessages(getAllErrors(error, formErrors))}</Box>
			) : null}
		</Modal>
	);
};
