import { z } from "zod";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { CustomEventType } from "@/enum/custom-event.enum";

import { CustomEventJobSchema } from "./customEventPerson";

const { REQUIRED } = ERROR_TYPE;

export const CustomEventSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	status: z.nativeEnum(JobsScrapeStatus),
	type: z.nativeEnum(CustomEventType),
	person: z.array(CustomEventJobSchema),
	url: z.string(),
});

export const CreateCustomEventSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(CustomEventType),
});

export const CustomEventResponseSchema = z.object({
	data: z.array(CustomEventSchema),
	meta: MetaDataSchema,
});

export type CustomEvent = z.infer<typeof CustomEventSchema>;

export type CreateCustomEvent = z.infer<typeof CreateCustomEventSchema>;

export type CustomEventResponse = z.infer<typeof CustomEventResponseSchema>;
