import {
	ChangeEvent,
	Dispatch,
	FunctionComponent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateLiEventMutation } from "@/pages/Private/redux/liEvent/liEvent.api";

import { LiEventType } from "@/enum/lievent.enum";
import { Radio } from "@/components/Radio/Radio";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { CreateLiEvent, CreateLiEventSchema } from "../schema/liEvent";
import { ApolloTagAutocomplete } from "../../Apollo/components/ApolloTagAutocomplete/ApolloTagAutocomplete";
import {
	LinkedinTagAutocomplete,
	LinkedinParams,
} from "../../LiPeople/components/LinkedinTagAutocomplete";

interface CreateLiEventModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateLiEventModal: FunctionComponent<CreateLiEventModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liEvent.${key}`);
	const [createLiEvent, { error }] = useCreateLiEventMutation();
	const [filter, setFilter] = useState<LiEventType>(LiEventType.SEARCH);
	const [tempUrls, setTempUrls] = useState<string>();
	const [tempLocations, setTempLocations] = useState<LinkedinParams[]>();
	const [tempIndustry, setTempIndustry] = useState<string[]>();
	const [languages, setLanguages] = useState<{
		en: boolean;
		de: boolean;
		fr: boolean;
		es: boolean;
		pt: boolean;
	}>({
		en: true,
		de: true,
		fr: true,
		es: true,
		pt: true,
	});

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateLiEvent>({
		defaultValues: {
			name: "",
			type: LiEventType.SEARCH,
			profileLanguageEn: true,
			profileLanguageDe: true,
			profileLanguageFr: true,
			profileLanguageEs: true,
			profileLanguagePt: true,
			minDaysBeforeScrape: 7,
			minAttendeesBeforeScrape: undefined,
			location: [],
			industry: [],
		},
		resolver: zodResolver(CreateLiEventSchema),
	});

	const handleUrlsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
	};

	const handleUrlsOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
		setValue("eventUrls", event.target.value?.split(","));
	};

	useEffect(() => {
		setValue("type", filter);
	}, [filter, setValue]);

	useEffect(() => {
		setValue("profileLanguageEn", languages.en);
		setValue("profileLanguageDe", languages.de);
		setValue("profileLanguageFr", languages.fr);
		setValue("profileLanguageEs", languages.es);
		setValue("profileLanguagePt", languages.pt);
	}, [languages, setValue]);

	useEffect(() => {
		setValue("location", tempLocations?.map((l) => l.name) || []);
	}, [tempLocations, setValue]);

	useEffect(() => {
		setValue("industry", tempIndustry || []);
	}, [tempIndustry, setValue]);

	const onSubmit = async (values: CreateLiEvent) => {
		if (values.type === LiEventType.SEARCH) {
			values.eventUrls = [];
		} else {
			values.searchTerm = "";
		}

		try {
			await createLiEvent(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			// isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<Radio
					isChecked={filter === LiEventType.SEARCH}
					onChange={() => setFilter(LiEventType.SEARCH)}
				>
					{ts("defaultSearch")}
				</Radio>

				<Radio
					isChecked={filter === LiEventType.SPECIFIC}
					onChange={() => setFilter(LiEventType.SPECIFIC)}
				>
					{ts("specific")}
				</Radio>

				{filter === LiEventType.SEARCH ? (
					<TextField
						// error={!!errors.searchTerm?.message}
						label={ts("searchTerm")}
						placeholder={ts("searchTerm")}
						{...register("searchTerm")}
					/>
				) : (
					<TextField
						multiline
						label={ts("url")}
						placeholder={ts("urls")}
						value={tempUrls}
						onBlur={handleUrlsOnBlur}
						onChange={handleUrlsChange}
					/>
				)}

				<div className="text-sm">{ts("scrapeCriteria")}</div>
				<TextField
					// error={!!errors.minDaysBeforeScrape?.message}
					label={ts("minDaysBeforeScrape")}
					name="minDaysBeforeScrape"
					placeholder={ts("minDaysBeforeScrape")}
					type="number"
					value={getValues("minDaysBeforeScrape") || ""}
					onChange={(e) => {
						if (e.target.value === "") {
							return setValue("minDaysBeforeScrape", 7, {
								shouldValidate: true,
								shouldDirty: true,
							});
						} else {
							setValue("minDaysBeforeScrape", +e.target.value, {
								shouldValidate: true,
								shouldDirty: true,
							});
						}
					}}
				/>
				<TextField
					// error={!!errors.minAttendeesBeforeScrape?.message}
					label={ts("minAttendeesBeforeScrape")}
					name="minAttendeesBeforeScrape"
					placeholder={ts("minAttendeesBeforeScrape")}
					type="number"
					value={getValues("minAttendeesBeforeScrape") || ""}
					onChange={(e) => {
						if (e.target.value === "") {
							return setValue("minAttendeesBeforeScrape", undefined, {
								shouldValidate: true,
								shouldDirty: true,
							});
						} else {
							setValue("minAttendeesBeforeScrape", +e.target.value, {
								shouldValidate: true,
								shouldDirty: true,
							});
						}
					}}
				/>

				<div className="text-sm">{ts("filterCriteria")}</div>

				<LinkedinTagAutocomplete
					handleValueChange={(value: LinkedinParams[]) => {
						if (value.length > 0) {
							setTempLocations(value);
						} else if (tempLocations) {
							setTempLocations([]);
						}
					}}
					type={"location"}
					value={tempLocations || []}
				/>

				<ApolloTagAutocomplete
					handleValueChange={(value) => {
						if (value.length > 0) {
							setTempIndustry(value.map((v) => v.name));
						} else if (tempIndustry) {
							setTempIndustry([]);
						}
					}}
					kind="original_linkedin_industry"
					type="organization_industry_tag_ids"
					value={(tempIndustry as string[]) || []}
				/>

				<div className="text-sm">{ts("profileLanguages")}</div>

				<Checkbox
					isChecked={languages.en}
					onChange={() => {
						setLanguages({ ...languages, en: !languages.en });
					}}
				>
					{ts("english")}
				</Checkbox>

				<Checkbox
					isChecked={languages.de}
					onChange={() => {
						setLanguages({ ...languages, de: !languages.de });
					}}
				>
					{ts("german")}
				</Checkbox>

				<Checkbox
					isChecked={languages.fr}
					onChange={() => {
						setLanguages({ ...languages, fr: !languages.fr });
					}}
				>
					{ts("french")}
				</Checkbox>

				<Checkbox
					isChecked={languages.es}
					onChange={() => {
						setLanguages({ ...languages, es: !languages.es });
					}}
				>
					{ts("spanish")}
				</Checkbox>

				<Checkbox
					isChecked={languages.pt}
					onChange={() => {
						setLanguages({ ...languages, pt: !languages.pt });
					}}
				>
					{ts("portuguese")}
				</Checkbox>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
