import { Dispatch, SetStateAction, FunctionComponent, ChangeEvent, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Autocomplete, TextField } from "@mui/material";

import { format } from "date-fns";

import { Modal } from "@/components";
import { InputField } from "@/components/InputField/InputField";

import { AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { InclusionFilterStatus, ListSourceType } from "@/enum/list.enum";
import { useGetFiltersQuery } from "@/pages/Private/redux/list/list.api";
import { useSearch } from "@/utils";

import { FilterDropdown } from "@/components/FilterDropdown.tsx/FilterDropdown";

import {
	GeneralFilter,
	IBooleanFilterType,
	IFilter,
	IFilterType,
	INumericFilterType,
	ITextFilterType,
	LiEventFilter,
} from "../constans/general-filter";
import { MapsFilter } from "../constans/maps-filter";
import { JobsFilter } from "../constans/jobs-filter";
import { ListPerson } from "../schema/list";
import { LiPostFilter } from "../constans/li-post-filter";
import { LiProfileFilter } from "../constans/li-profile-filter";
import { LiCompanyActivityFilter } from "../constans/li-company-activity-filter";

export interface IFilterWithValues {
	[key: string]: {
		value?:
			| string
			| number
			| {
					name: string;
					id: number;
			  }[]
			| {
					name: string;
					id: number;
			  };
		filterParam: string;
		type: IFilterType;
	};
}

interface FilterModalProps {
	inboxData: ListPerson[];
	listId: number;
	showFilterModal: boolean;
	setShowFilterModal: Dispatch<SetStateAction<boolean>>;
	filter?: IFilterWithValues;
	setFilter: Dispatch<SetStateAction<IFilterWithValues | undefined>>;
	listType?: ListSourceType;
}

export const FilterModal: FunctionComponent<FilterModalProps> = ({
	inboxData,
	listId,
	showFilterModal,
	setShowFilterModal,
	filter,
	setFilter,
	listType,
}) => {
	const { t } = useTranslation();

	const [filterType, setFilterType] = useState<{ [key: string]: IFilterType }>(
		filter
			? {
					...Object.keys(filter || {}).reduce((acc, curr) => {
						return { ...acc, [curr]: filter[curr].type };
					}, {}),
			  }
			: {}
	);

	const [filterValue, setFilterValue] = useState<IFilterWithValues>({ ...filter });

	const { query, searchValue, setSearchValue } = useSearch();

	const { data } = useGetFiltersQuery({
		id: listId,
		searchTerm: query,
	});

	const onSubmit = () => {
		filterValue && setFilter(filterValue);
	};

	const resetFilter = () => {
		setFilterType({});
		setFilterValue({});
	};

	const FilterDataType = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (listType === ListSourceType.LI_EVENTS) {
			FilterDataType = LiEventFilter;
		}

		if (listType === ListSourceType.MAPS) {
			FilterDataType = MapsFilter;
		}

		if (listType === ListSourceType.JOBS) {
			FilterDataType = JobsFilter;
		}

		if (listType === ListSourceType.LI_POST) {
			FilterDataType = LiPostFilter;
		}

		if (
			listType &&
			[
				ListSourceType.LINKEDIN_PROFILE_COMMENTS,
				ListSourceType.LINKEDIN_PROFILE_POSTS,
				ListSourceType.LINKEDIN_PROFILE_COMMENTS_TO_POST,
				ListSourceType.LINKEDIN_PROFILE_LIKES_TO_POST,
				ListSourceType.LINKEDIN_PROFILE_LIKES_TO_COMMENT,
			].includes(listType)
		) {
			FilterDataType = LiProfileFilter;
		}

		if (listType === ListSourceType.LI_COMPANY_ACTIVITY) {
			FilterDataType = LiCompanyActivityFilter;
		}

		const foundPerson = inboxData.find((person) => person.prospect?.id);

		if (foundPerson) {
			FilterDataType = [...GeneralFilter, ...FilterDataType];
		}

		if (FilterDataType.length === 0) {
			FilterDataType = GeneralFilter;
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listType]);

	return (
		<Modal
			handleClear={() => resetFilter()}
			handleClose={() => setShowFilterModal(false)}
			handleSave={() => {
				onSubmit();
				setShowFilterModal(false);
			}}
			isOpened={showFilterModal}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={t("basics.filter")}
		>
			<div className="flex flex-col">
				{FilterDataType.map((filter) => {
					return (
						<div key={filter.name} className="flex flex-row items-center">
							<div className="flex flex-col w-[200px] mr-8">{t(`inbox.${filter.name}`)}</div>
							<div className="flex-col w-[370px] mr-8">
								<FilterDropdown
									className="w-full mb-3"
									data={[
										{
											id: "noFilter",
											title: t(`filter.noFilter`),
										},
										...filter.filterByType.map((type) => {
											return {
												id: type,
												title: t(`filter.${type}`),
											};
										}),
									]}
									defaultValue={{
										id: "noFilter",
										title: t(`filter.noFilter`),
									}}
									floating={true}
									handleSelect={(value: AutoCompleteItem) => {
										setFilterType({ ...filterType, [filter.name]: value.id as IFilterType });

										const filteredFilterValue =
											filterValue && Object.keys(filterValue)?.filter((f) => f !== filter.name);
										const newFilteredValues = filteredFilterValue?.reduce((acc, curr) => {
											return { ...acc, [curr]: filterValue?.[curr] };
										}, {});

										if (IBooleanFilterType.includes(value.id as IFilterType)) {
											setFilterValue({
												...newFilteredValues,
												[filter.name]: {
													filterParam: filter.filterParam,
													type: value.id as IFilterType,
												},
											});

											return;
										}

										setFilterValue(newFilteredValues);
									}}
									value={
										filterType?.[filter.name]
											? {
													id: filterType[filter.name] as string,
													title: t(`filter.${filterType?.[filter.name]}`),
											  }
											: {
													id: "noFilter",
													title: t(`filter.noFilter`),
											  }
									}
								/>
							</div>
							<div className="flex flex-col w-[370px] ">
								{filterType &&
									Object.keys(filterType).map((fT, index) => {
										if (fT !== filter.name) {
											return <></>;
										}

										if (ITextFilterType.some((type) => type === filterType[fT])) {
											return (
												<div key={`ft-${index}`}>
													<InputField
														className="mb-0"
														handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
															setFilterValue({
																...filterValue,
																[filter.name]: {
																	value: event.target.value,
																	type: filterType[fT],
																	filterParam: filter.filterParam,
																},
															});
														}}
														name={filter.name}
														placeholder={t(filter.name)}
														value={(filterValue?.[filter.name]?.value as string) || ""}
													/>
												</div>
											);
										}

										if (
											filterType[fT] !== "between" &&
											INumericFilterType.some((type) => type === filterType[fT])
										) {
											return (
												<div key={`ft-${index}`}>
													<InputField
														handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
															setFilterValue({
																...filterValue,
																[filter.name]: {
																	value: event.target.value,
																	type: filterType[fT],
																	filterParam: filter.filterParam,
																},
															});
														}}
														name={filter.name}
														placeholder={t(filter.name)}
														type="number"
														value={(filterValue?.[filter.name]?.value as number) || ""}
													/>
												</div>
											);
										}

										if (filterType[fT] === "dropdown" && fT === "inclusionStatus") {
											return (
												<div key={`ft-${index}`}>
													<FilterDropdown
														className="w-full mb-3"
														data={[
															{
																title: InclusionFilterStatus.ADDED,
																id: InclusionFilterStatus.ADDED,
															},
															{
																title: InclusionFilterStatus.EXCLUDED,
																id: InclusionFilterStatus.EXCLUDED,
															},
															{
																title: InclusionFilterStatus.MAYBE,
																id: InclusionFilterStatus.MAYBE,
															},
															{
																title: InclusionFilterStatus.BOTH,
																id: InclusionFilterStatus.BOTH,
															},
														]}
														floating={true}
														handleSelect={(value: AutoCompleteItem) => {
															if (value.id) {
																setFilterValue({
																	...filterValue,
																	[filter.name]: {
																		value: value.id,
																		type: filterType[fT],
																		filterParam: filter.filterParam,
																	},
																});
															}
														}}
													/>
												</div>
											);
										}

										if (
											listType === ListSourceType.LI_EVENTS &&
											filterType[fT] === "dropdown" &&
											fT === "event"
										) {
											return (
												<div key={`ft-${index}`}>
													<Autocomplete
														getOptionLabel={(option) => `${option.name} ${option.additionalInfo}`}
														id="event"
														options={
															data?.events.map(
																(e: {
																	liEventId: number;
																	liEventEntryId: number;
																	liEventEntryName: string;
																	attendees: number;
																	eventAt: Date;
																}) => {
																	return {
																		id: e.liEventEntryId,
																		name: e.liEventEntryName,
																		additionalInfo: `@ ${format(
																			new Date(e.eventAt),
																			"dd.MM.yyyy"
																		)}`,
																	};
																}
															) || []
														}
														renderInput={(params) => {
															return (
																<TextField
																	{...params}
																	label={"Events"}
																	placeholder={"Enter event name"}
																/>
															);
														}}
														size="small"
														sx={{
															width: "100%",
														}}
														value={
															(filterValue?.[filter.name]?.value as {
																name: string;
																additionalInfo?: string;
																id: number;
															}) || undefined
														}
														onChange={(event, value) => {
															if (value) {
																setFilterValue({
																	...filterValue,
																	[filter.name]: {
																		value: value,
																		type: filterType[fT],
																		filterParam: filter.filterParam,
																	},
																});
															}
														}}
														onInputChange={(event, newInputValue) => {
															setSearchValue(newInputValue);
														}}
													/>
												</div>
											);
										}

										if (filterType[fT] === "dropdown" && fT === "list") {
											return (
												<div key={`ft-${index}`}>
													<Autocomplete
														getOptionLabel={(option) => option.name}
														id="list"
														inputValue={searchValue}
														options={
															data?.lists.map((e: { listId: number; listName: string }) => {
																return {
																	id: e.listId,
																	name: e.listName,
																};
															}) || []
														}
														renderInput={(params) => {
															return (
																<TextField
																	{...params}
																	label={"List"}
																	placeholder={"Enter list name"}
																/>
															);
														}}
														size="small"
														sx={{
															width: "100%",
														}}
														value={
															(filterValue?.[filter.name]?.value as {
																name: string;
																id: number;
															}) || undefined
														}
														onChange={(event, value) => {
															if (value) {
																setFilterValue({
																	...filterValue,
																	[filter.name]: {
																		value: value,
																		type: filterType[fT],
																		filterParam: filter.filterParam,
																	},
																});
															}
														}}
														onInputChange={(event, newInputValue) => {
															setSearchValue(newInputValue);
														}}
													/>
												</div>
											);
										}

										return <></>;
									})}
							</div>
						</div>
					);
				})}
			</div>
		</Modal>
	);
};
