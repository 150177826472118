import { FunctionComponent, ReactNode } from "react";

interface TooltipProps {
	children: ReactNode;
	content: ReactNode | string;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({ children, content }) => {
	return (
		<div className="group inline-flex relative z-100">
			<div className="hidden absolute group-hover:flex translate-y-[-110%] translate-x-[0]  p-1 border rounded group-hover:bg-white mb-2 z-[150]">
				{content}
			</div>
			{children}
		</div>
	);
};
