import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateOpenAiMutation } from "@/pages/Private/redux/openAi/openAi.api";

import { AiEnrichmentTarget } from "@/enum/ai-enrichment.enum";
import { SelectField } from "@/components/SelectField/SelectField";

import { CreateOpenAi, CreateOpenAiSchema } from "../schema/openAi";

interface createOpenAiModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateOpenAiModal: FunctionComponent<createOpenAiModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`openAi.${key}`);
	const [createOpenAi, { isLoading }] = useCreateOpenAiMutation();
	const [curAiEnrichmentTarget, setCurAiEnrichmentTarget] = useState<AiEnrichmentTarget>(
		AiEnrichmentTarget.CONTACT
	);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateOpenAi>({
		defaultValues: {
			name: "",
			aiEnrichmentTarget: AiEnrichmentTarget.CONTACT,
		},
		resolver: zodResolver(CreateOpenAiSchema),
	});

	useEffect(() => {
		setValue("aiEnrichmentTarget", curAiEnrichmentTarget);
	}, [curAiEnrichmentTarget, setValue]);

	const onSubmit = async (values: CreateOpenAi) => {
		try {
			await createOpenAi(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<SelectField
					handleSelect={(role) => {
						console.log(role);
						setCurAiEnrichmentTarget(role as AiEnrichmentTarget);
					}}
					label={`${ts("type")}`}
					options={[
						{
							value: AiEnrichmentTarget.CONTACT,
							title:
								AiEnrichmentTarget.CONTACT.charAt(0).toUpperCase() +
								AiEnrichmentTarget.CONTACT.slice(1).toLowerCase(),
						},
						{
							value: AiEnrichmentTarget.ACCOUNT,
							title:
								AiEnrichmentTarget.ACCOUNT.charAt(0).toUpperCase() +
								AiEnrichmentTarget.ACCOUNT.slice(1).toLowerCase(),
						},
						{
							value: AiEnrichmentTarget.NORTHDATA,
							title:
								AiEnrichmentTarget.NORTHDATA.charAt(0).toUpperCase() +
								AiEnrichmentTarget.NORTHDATA.slice(1).toLowerCase(),
						},
					]}
					selectedOption={curAiEnrichmentTarget}
					showError={true}
				></SelectField>
				{/* {getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null} */}
			</Stack>
		</Modal>
	);
};
