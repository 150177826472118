import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiPostContentType, LiPostResultStatus, LiPostSort } from "@/enum/li-post.enum";
import { ListStatus } from "@/enum/list.enum";

import { SuggestionSchema } from "../../LiPeople/schema/liPeople";
import { LiPostResultSchema } from "./liPostResult";

const { REQUIRED } = ERROR_TYPE;

export const LiPostSchema = z.object({
	id: z.number(),
	name: z.string().min(3, { message: REQUIRED }).trim(),
	query: z.string().trim(),
	sortBy: z.nativeEnum(LiPostSort),
	authorJobTitle: z.string().trim().nullable(),
	contentType: z.nativeEnum(LiPostContentType).nullable(),
	fromMember: SuggestionSchema.optional().nullable(),
	fromOrganization: SuggestionSchema.optional().nullable(),
	authorCompany: SuggestionSchema.optional().nullable(),
	authorIndustry: SuggestionSchema.optional().nullable(),
	mentionsMember: SuggestionSchema.optional().nullable(),
	mentionsOrganization: SuggestionSchema.optional().nullable(),
	status: z.nativeEnum(LiPostResultStatus),
	activeStatus: z.nativeEnum(ListStatus),
	liPostResults: z.array(LiPostResultSchema),
	repeatDaily: z.boolean(),
});

export const CreateLiPostSchema = z.object({
	name: z.string().min(3, { message: REQUIRED }).trim(),
	query: z.string().trim(),
	sortBy: z.nativeEnum(LiPostSort),
	authorJobTitle: z.string().trim().optional(),
	contentType: z.nativeEnum(LiPostContentType).nullable().optional(),
	fromMember: SuggestionSchema.optional().nullable(),
	fromOrganization: SuggestionSchema.optional().nullable(),
	authorCompany: SuggestionSchema.optional().nullable(),
	authorIndustry: SuggestionSchema.optional().nullable(),
	mentionsMember: SuggestionSchema.optional().nullable(),
	mentionsOrganization: SuggestionSchema.optional().nullable(),
	repeatDaily: z.boolean(),
});

export const LiPostResponseSchema = z.object({
	data: z.array(LiPostSchema),
	meta: MetaDataSchema,
});

export type LiPost = z.infer<typeof LiPostSchema>;

export type CreateLiPost = z.infer<typeof CreateLiPostSchema>;

export type LiPostResponse = z.infer<typeof LiPostResponseSchema>;
