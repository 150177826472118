import { FunctionComponent, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import CheckIcon from "@assets/icons/check.svg";
import ChevronDownIcon from "@assets/icons/chevron_down.svg";
import { classNames } from "@/utils/classNames";
import { AiEnrichmentTarget, PromptRole } from "@/enum/ai-enrichment.enum";

export type SelectFieldValue = number | string | null | PromptRole | AiEnrichmentTarget;

export type SelectFieldOptions = {
	title: string | number;
	value: SelectFieldValue;
}[];

interface SelectFieldProps {
	disabled?: boolean;
	placeholder?: string;
	options: SelectFieldOptions;
	handleSelect: (value: SelectFieldValue) => void;
	selectedOption: SelectFieldValue;
	label?: string;
	name?: string;
	showError?: boolean;
	error?: string | boolean;
	touched?: boolean;
	handleBlur?: () => void;
	icon?: JSX.Element;
	hideEmptyValue?: boolean;
	isOptional?: boolean;
	bordered?: boolean;
	testId?: string;
}

export const SelectField: FunctionComponent<SelectFieldProps> = ({
	placeholder,
	options,
	handleSelect,
	selectedOption,
	label,
	name,
	showError = false,
	error = false,
	touched,
	handleBlur,
	hideEmptyValue,
	icon,
	isOptional,
	bordered,
	testId,
}) => {
	const { t } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	const selectFieldRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = ({ target }: MouseEvent) => {
			if (!(selectFieldRef.current && selectFieldRef.current?.contains(target as Node))) {
				if (showDropdown) {
					setShowDropdown(false);
				}
			}
		};

		window.addEventListener("click", handleClick);

		return () => window.removeEventListener("click", handleClick);
	}, [selectFieldRef, showDropdown]);

	console.log(selectedOption);

	const handleSelectValue = (value: number | string | null) => {
		if (handleBlur) {
			handleBlur();
		}

		handleSelect(value);
		setShowDropdown(false);
	};

	const renderOptions = () =>
		options.map((option) => {
			const { title, value } = option;
			const isActive = value === selectedOption;

			return (
				<div
					key={`option-select-${value}`}
					className={classNames(
						"py-2.5 px-3 pr-8 text-sm text-gray-900 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative",
						isActive ? "font-semibold" : ""
					)}
					onClick={() => handleSelectValue(value)}
				>
					{title}
					{isActive && (
						<div className="absolute right-[15px] top-0 bottom-0 flex items-center text-blue-action">
							<img alt="check icon" className="w-[14px]" src={CheckIcon} />
						</div>
					)}
				</div>
			);
		});

	const selectedValue =
		options.find((option) => option.value === selectedOption)?.title ||
		placeholder ||
		t("basics.pleaseSelect");

	return (
		<>
			<div ref={selectFieldRef} className="relative w-full">
				{label && (
					<label
						className="block mb-2 text-sm font-medium text-gray-900 appearance-none"
						htmlFor={name}
					>
						{label}
						{isOptional && " (Optional)"}
					</label>
				)}
				<div
					className={classNames(
						"sm:shadow-mb-card sm:border-none",
						"relative flex input-styles group bg-white pr-8",
						showDropdown ? "border-brand-light" : "",
						options.length
							? "cursor-pointer"
							: "cursor-not-allowed focus:border-gray-200 hover:border-gray-200 opacity-50",
						showError && !!error ? "!border-status-error" : "",
						touched && !error ? "!border-status-success" : "",
						bordered && !(showError && !!error) && !(touched && !error)
							? "!border-rb-light-gray"
							: ""
					)}
					data-test={testId}
					onClick={() => {
						if (options.length) {
							setShowDropdown(!showDropdown);
						}
					}}
				>
					{icon && (
						<div className="flex items-center justify-center h-full mr-2 -ml-1 text-gray-500">
							{icon}
						</div>
					)}
					<p
						className={classNames(
							"leading-[42px] overflow-hidden text-ellipsis",
							selectedOption ? "text-gray-900" : "text-gray-700"
						)}
					>
						{selectedValue}
					</p>
					<div
						className={classNames(
							"absolute top-0 bottom-0 right-0 flex items-center justify-center w-auto h-full pl-1 pr-4",
							showDropdown ? "text-brand-light" : "text-gray-500",
							options.length ? "group-hover:text-brand-light" : "group-hover:text-gray-500",
							bordered ? "!text-rb-light-gray" : ""
						)}
					>
						<img alt="ChevronDownIcon" className="w-[12px]" src={ChevronDownIcon} />
					</div>
				</div>
				{showDropdown && (
					<div
						className={classNames(
							"absolute top-full  z-50 mt-1 rounded-md py-1 border border-gray-200 bg-white w-full max-h-[150px] overflow-y-auto bb-scrollbar-darker"
						)}
						data-test="dropdown"
					>
						{!hideEmptyValue && (
							<div
								key="option-select-placeholder"
								className={classNames(
									"py-2.5 px-3 pr-8 text-sm text-gray-700 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative"
								)}
								onClick={() => handleSelectValue(null)}
							>
								{placeholder || t("basics.pleaseSelect")}
							</div>
						)}
						{renderOptions()}
					</div>
				)}
			</div>
		</>
	);
};
