export type IFilterType =
	| "dropdown"
	| "isEmpty"
	| "isNotEmpty"
	| "textContains"
	| "textDoesNotContain"
	| "textStartsWith"
	| "textEndsWith"
	| "textIsExactly"
	| "greaterEqual"
	| "lessEqual"
	| "between";

export const ITextFilterType = [
	"textContains",
	"textDoesNotContain",
	"textStartsWith",
	"textEndsWith",
	"textIsExactly",
];

export const IBooleanFilterType = ["isEmpty", "isNotEmpty"];

export const INumericFilterType = ["greaterEqual", "lessEqual", "between"];

export interface IFilter {
	name: string;
	filterParam: string;
	filterByType: IFilterType[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterByValue: any[];
}

const assignedTo: IFilter = {
	name: "assignedTo",
	filterParam: "list.name",
	filterByType: [
		"dropdown",
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const name: IFilter = {
	name: "name",
	filterParam: "prospect.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const email: IFilter = {
	name: "email",
	filterParam: "prospect.email",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const linkedinUrl: IFilter = {
	name: "linkedinUrl",
	filterParam: "prospect.linkedinUr",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const country: IFilter = {
	name: "country",
	filterParam: "prospect.country",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const city: IFilter = {
	name: "city",
	filterParam: "prospect.city",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const state: IFilter = {
	name: "state",
	filterParam: "prospect.state",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const company: IFilter = {
	name: "company",
	filterParam: "organization.name",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyCountry: IFilter = {
	name: "companyCountry",
	filterParam: "organization.country",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyState: IFilter = {
	name: "companyState",
	filterParam: "organization.state",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyCity: IFilter = {
	name: "companyCity",
	filterParam: "organization.city",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const companyLinkedinUrl: IFilter = {
	name: "companyLinkedinUrl",
	filterParam: "organization.linkedinUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const foundedYear: IFilter = {
	name: "foundedYear",
	filterParam: "organization.foundedYear",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const title: IFilter = {
	name: "title",
	filterParam: "prospect.title",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const seniority: IFilter = {
	name: "seniority",
	filterParam: "prospect.seniority",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const website: IFilter = {
	name: "website",
	filterParam: "organization.websiteUrl",
	filterByType: [
		"isEmpty",
		"isNotEmpty",
		"textContains",
		"textDoesNotContain",
		"textStartsWith",
		"textEndsWith",
		"textIsExactly",
	],
	filterByValue: [],
};

const estimatedNumEmployees: IFilter = {
	name: "estimatedNumEmployees",
	filterParam: "organization.estimatedNumEmployees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

const inclusionStatus: IFilter = {
	name: "inclusionStatus",
	filterParam: "list_person.inclusionStatus",
	filterByType: ["dropdown"],
	filterByValue: [],
};

const event: IFilter = {
	name: "event",
	filterParam: "li_event_entry.name",
	filterByType: ["dropdown"],
	filterByValue: [],
};

const list: IFilter = {
	name: "list",
	filterParam: "list_other.name",
	filterByType: ["dropdown", "isEmpty", "isNotEmpty"],
	filterByValue: [],
};

const attendees: IFilter = {
	name: "attendees",
	filterParam: "li_event_entry.attendees",
	filterByType: ["isEmpty", "isNotEmpty", "greaterEqual", "lessEqual", "between"],
	filterByValue: [],
};

export const GeneralFilter: IFilter[] = [
	assignedTo,
	name,
	title,
	seniority,
	email,
	linkedinUrl,
	country,
	city,
	state,
	company,
	companyCountry,
	companyState,
	companyCity,
	companyLinkedinUrl,
	foundedYear,
	website,
	estimatedNumEmployees,
	inclusionStatus,
	list,
];

export const LiEventFilter: IFilter[] = [attendees, event];
