import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	Blacklist,
	BlacklistResponse,
	BlacklistResponseSchema,
	BlacklistSchema,
} from "../../pages/Blacklist/schema/blacklist";

export const blacklistApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getBlacklists: builder.query<BlacklistResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "blacklist",
				params: {
					...(params && { ...params }),
				},
				responseSchema: BlacklistResponseSchema,
			}),
			providesTags: ["Blacklist"],
		}),
		getBlacklist: builder.query<Blacklist, number>({
			query: (id: number) => ({
				url: `blacklist/${id}`,
				params: {
					id,
				},
				responseSchema: BlacklistSchema,
			}),
			providesTags: ["Blacklist"],
		}),
		createBlacklist: builder.mutation<Partial<Blacklist>[], Partial<Blacklist>>({
			query: (body) => ({
				url: `blacklist`,
				method: "POST",
				body,
				responseSchema: BlacklistSchema,
			}),
			invalidatesTags: ["Blacklist"],
		}),
		uploadBlacklist: builder.mutation<Partial<Blacklist>, FormData>({
			query: (body) => ({
				url: `blacklist/upload`,
				method: "POST",
				body,
				responseSchema: BlacklistResponseSchema,
			}),
			invalidatesTags: ["Blacklist"],
		}),
		updateBlacklist: builder.mutation<Partial<Blacklist>, Partial<Blacklist>>({
			query: ({ id, ...body }) => ({
				url: `blacklist/${id}`,
				method: "PATCH",
				body,
				responseSchema: BlacklistSchema,
			}),
			invalidatesTags: ["Blacklist"],
		}),
		deleteBlacklist: builder.mutation<Partial<Blacklist>, number>({
			query: (id) => ({
				url: `blacklist/${id}`,
				method: "DELETE",
				responseSchema: BlacklistSchema,
			}),
			invalidatesTags: ["Blacklist"],
		}),
	}),
});

export const {
	useGetBlacklistsQuery,
	useLazyGetBlacklistsQuery,
	useGetBlacklistQuery,
	useUpdateBlacklistMutation,
	useDeleteBlacklistMutation,
	useUploadBlacklistMutation,
	useCreateBlacklistMutation,
} = blacklistApi;
export const accountApiReducer = blacklistApi.reducer;
export const accountApiMiddleware = blacklistApi.middleware;
