import { z } from "zod";

import { LiEventLocationType, LiEventScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiEventEntryAttendeeSchema } from "./liEventEntryAttendee";

const { REQUIRED } = ERROR_TYPE;

export const LiEventEntrySchema = z.object({
	id: z.number(),
	name: z.string().min(3, { message: REQUIRED }).trim(),
	url: z.string().min(3, { message: REQUIRED }).trim(),
	description: z.string().trim(),
	companyName: z.string().trim(),
	attendees: z.number().nullable(),
	eventAt: z.string().nullable(),
	eventTill: z.string().nullable(),
	type: z.nativeEnum(LiEventLocationType),
	status: z.nativeEnum(LiEventScrapeStatus),
	liEventEntryAttendees: z.array(LiEventEntryAttendeeSchema),
	ignorePattern: z.boolean(),
	liEvent: z.any(),
});

export const LiEventJobsResponseSchema = z.object({
	data: z.array(LiEventEntrySchema),
	meta: MetaDataSchema,
});

export type LiEventJob = z.infer<typeof LiEventEntrySchema>;

export type LiEventJobsResponse = z.infer<typeof LiEventJobsResponseSchema>;
