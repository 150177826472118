import { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Link, Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { faHandHoldingBox, faUserMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

import LinkedinIcon from "@assets/icons/linkedin.svg";
import GoogleMapsIcon from "@assets/icons/google-maps.svg";
import CalendarIcon from "@assets/icons/calendar.svg";

import { Icon, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import { useGetListSourcesQuery } from "@/pages/Private/redux/list/list.api";

import { ListSourceType } from "@/enum/list.enum";

import { useNonStickySearch } from "@/utils/useNonStickySearch";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { Radio } from "@/components/Radio/Radio";

import { ROUTE_CONFIG } from "@/routes/config";

import { ListSource } from "../schema/list";

export interface SourceListProps {
	id: number;
	type?: string;
	selectedSource?:
		| {
				id: number[];
				sourceType: ListSourceType;
		  }
		| undefined;
	setSelectedSource: (source: { id: number[]; sourceType: ListSourceType }) => void;
}

export const SourceList: FunctionComponent<SourceListProps> = ({
	id,
	selectedSource,
	setSelectedSource,
	type,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.sources.${key}`), [t]);
	const { page, setPage, limit, setLimit, searchValue, query, setSearchValue } =
		useNonStickySearch();

	const { data, isLoading } = useGetListSourcesQuery({
		id,
		page,
		limit,
		searchValue: query,
		additional: type === "additionalSources" ? true : false,
	});

	console.log("---page source list---", page);

	const columns: TableColumn<ListSource>[] = [
		{
			label: "",
			width: 20,
			format: (listSource: ListSource) => {
				if (type === "additionalSources") {
					return (
						<Radio
							isChecked={
								!!selectedSource?.id.find((obj) => obj === listSource.id) &&
								selectedSource?.sourceType === listSource.sourceType
							}
							name={`${listSource.id}-${listSource.sourceType}`}
							onChange={() => {
								setSelectedSource({
									id: [listSource.id],
									sourceType: listSource.sourceType,
								});
							}}
						/>
					);
				}

				return (
					<Checkbox
						isChecked={
							!!selectedSource?.id.find((obj) => obj === listSource.id) &&
							selectedSource?.sourceType === listSource.sourceType
						}
						name={`${listSource.id}-${listSource.sourceType}`}
						onChange={() => {
							if (!selectedSource?.id.find((obj) => obj === listSource.id)) {
								setSelectedSource({
									id: selectedSource ? [...selectedSource?.id, listSource.id] : [listSource.id],
									sourceType: listSource.sourceType,
								});
							} else {
								setSelectedSource({
									id: selectedSource?.id.filter((obj) => obj !== listSource.id) || [],
									sourceType: listSource.sourceType,
								});
							}
						}}
					/>
				);
			},
		},
		{
			label: ts("name"),
			format: (row) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						{row.sourceType === ListSourceType.PROSPECT_SEARCH && (
							<Link href={`${ROUTE_CONFIG.APOLLO_SEARCH_PEOPLE}/${row?.id}`} target="_blank">
								<div className="text-sm flex items-center">
									<Icon className="w-[24px] h-[24px]" icon={faUserMagnifyingGlass} />
									{row?.name}
								</div>
							</Link>
						)}
						{row.sourceType === ListSourceType.LI_EVENTS && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LI_PEOPLE && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LI_COMPANY_ACTIVITY && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LI_PROFILE_VISITS && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LINKEDIN_PROFILE_POSTS && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LINKEDIN_PROFILE_COMMENTS && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LINKEDIN_PROFILE_LIKES_TO_COMMENT && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LINKEDIN_PROFILE_LIKES_TO_POST && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LINKEDIN_PROFILE_COMMENTS_TO_POST && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.LI_POST && (
							<div className="text-sm flex items-center">
								<img alt="LinkedinIcon" className="w-5 h-5 mr-2 rounded" src={LinkedinIcon} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.CUSTOM_EVENTS && (
							<div className="text-sm flex items-center">
								<img alt="CalendarIcon" className="w-5 h-5 mr-2 rounded" src={CalendarIcon} />
								{row?.name}
							</div>
						)}

						{row.sourceType === ListSourceType.CSV_ENRICHMENTS && (
							<div className="text-sm flex items-center">
								<img alt="CalendarIcon" className="w-5 h-5 mr-2 rounded" src={CalendarIcon} />
								{row?.name}
							</div>
						)}

						{row.sourceType === ListSourceType.JOBS && (
							<div className="text-sm flex items-center">
								<Icon className="w-5 h-5 mr-2" icon={faHandHoldingBox} />
								{row?.name}
							</div>
						)}
						{row.sourceType === ListSourceType.MAPS && (
							<div className="text-sm flex items-center">
								<img alt="GoogleMapsIcon" className="w-5 h-5 mr-2 rounded" src={GoogleMapsIcon} />
								{row?.name}
							</div>
						)}
					</Stack>
				</Stack>
			),
		},
	];

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	return !data || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{ts("subtitle")}</p>
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					allowNavigation={false}
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
