import { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";

import { debounce } from "@mui/material";

import { Spinner } from "phosphor-react";

import { SearchField } from "@/components/SearchField/SearchField";
import { classNames } from "@/utils/classNames";
import { Icon, Loader } from "@/components";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { ReactComponent as ChevronUp } from "@assets/icons/inbox/chevron-up.svg";
import { ReactComponent as ChevronDown } from "@assets/icons/inbox/chevron-down.svg";
import { SvgIcon } from "@/components/Icon/SvgIcon";

import { ReactComponent as Tick } from "@assets/icons/inbox/tick.svg";
import { ReactComponent as LeadsASC } from "@assets/icons/inbox/leads-asc.svg";
import { ReactComponent as LeadsDESC } from "@assets/icons/inbox/leads-desc.svg";
import { ReactComponent as NameASC } from "@assets/icons/inbox/sort-asc.svg";
import { ReactComponent as NameDESC } from "@assets/icons/inbox/sort-desc.svg";
import { SortParams } from "@/types/types";

import { ListSourceTypeElem } from "./ListSourceTypeElem";
import { List } from "../schema/list";

export interface CustomListAutocompleteProps {
	handleSelect: (value?: Partial<List>) => void;
	handleSearch?: (value: string) => void;
	handleSortList: (value: AutoCompleteItem) => void;
	handleFetchMore: () => void;
	selectedItem: string;
	data: List[];
	label?: string;
	testId?: string;
	floating?: boolean;
	sortState?: SortParams;
	isFetching: boolean;
	isLoading?: boolean;
	isListLoading?: boolean;
	currentPage: number;
	totalPages: number;
}

export const CustomListAutocomplete: FunctionComponent<CustomListAutocompleteProps> = ({
	handleSelect,
	handleSearch,
	handleSortList,
	handleFetchMore,
	selectedItem,
	data,
	label,
	testId,
	floating,
	sortState,
	isFetching,
	isLoading,
	isListLoading,
	currentPage,
	totalPages,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.${key}`), [t]);
	const [searchValue, setSearchValue] = useState("");

	// Dropdown Logic
	const [showDropdown, setShowDropdown] = useState(false);
	const selectFieldRef = useRef<HTMLDivElement>(null);

	const handleSelectItem = (selected: Partial<List>) => {
		handleSelect(selected);
		setShowDropdown(false);
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedItem = data.find((item) => item.name === e.target.value);

		handleSearch && handleSearch(e.target.value);

		setSearchValue(e.target.value);

		if (selectedItem) {
			handleSelect(selectedItem);
		}

		setShowDropdown(true);
	};

	useEffect(() => {
		const handleClick = ({ target }: MouseEvent) => {
			if (target && target instanceof HTMLElement) {
				if (target.id === "closeIcon") {
					setSearchValue("");
					handleSearch && handleSearch("");
				} else if (selectFieldRef.current && !selectFieldRef.current.contains(target as Node)) {
					setShowDropdown(false);
				}
			}
		};

		window.addEventListener("click", handleClick);

		return () => window.removeEventListener("click", handleClick);
	}, [selectFieldRef, showDropdown]);

	const loadMoreData = useCallback(() => {
		if (!isFetching && currentPage < totalPages) {
			handleFetchMore();
		}
	}, [currentPage, handleFetchMore, isFetching, totalPages]);

	useEffect(() => {
		if (showDropdown) {
			const tableContainer = document.querySelector("#CustomListAutocomplete-element");

			const isLastElementVisible = () => {
				const lastElement = document.querySelector(
					"#CustomListAutocomplete-items  button:nth-last-child(1)"
				);

				const rect = lastElement?.getBoundingClientRect();

				if (rect) {
					const isVisible =
						rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

					if (isVisible) {
						handleFetchMore();
					}
				}
			};

			// Debounce isLastElementVisible to limit fetch calls during fast scrolling.
			const handleScroll = debounce(isLastElementVisible, 300);

			tableContainer?.addEventListener("scroll", handleScroll);

			isLastElementVisible();

			return () => {
				tableContainer?.removeEventListener("scroll", handleScroll);
			};
		}
	}, [loadMoreData, showDropdown]);

	// Result rendering
	const renderResults = () => {
		const results = data;

		return (
			<div className={classNames("flex flex-col w-full ")} id="CustomListAutocomplete-items">
				<div className="border-t border-t-border box-border mx-3"></div>
				{isLoading || isListLoading ? (
					<div className="mt-[15%]">
						<Loader />
					</div>
				) : (
					<>
						{results.map((item) => {
							const { id, name } = item;

							return (
								<button
									key={`option-select-${id}`}
									className={classNames(
										"w-full flex items-center flex-row justify-between py-2.5 px-3 text-sm text-gray-900 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative"
									)}
									onClick={() => {
										handleSelectItem(item);
									}}
								>
									<div className="flex items-center flex-row">
										<ListSourceTypeElem sourceType={item?.sourceType} />
										<div className="mr-3 ml-2">{name}</div>
										{item?.found ? (
											<div className="px-2 py-1 bg-light-blue text-medium-blue border-medium-blue border rounded-lg">
												+{item?.found ?? 0 - (item?.processed ?? 0) - (item?.savedForLater ?? 0)}{" "}
												New Leads
											</div>
										) : (
											<></>
										)}
									</div>

									{item.name === selectedItem && (
										<SvgIcon className="w-[20px] h-[20px] text-brand-light" svgIcon={Tick} />
									)}
								</button>
							);
						})}
						{!results.length && (
							<p className="py-2.5 px-3 text-sm italic text-gray-700">{t("basics.noResults")}</p>
						)}
						{isFetching && !isLoading && (
							<div className="flex items-center justify-center py-4">
								<Spinner color="#6B7280" />
							</div>
						)}
					</>
				)}
			</div>
		);
	};

	const handleClickInput = () => setShowDropdown(!showDropdown);

	return (
		<div ref={selectFieldRef} className="relative z-20">
			<div className="flex items-center cursor-pointer" onClick={handleClickInput}>
				<div className="text-lg font-semibold leading-8 mr-3">{label}</div>
				<SvgIcon
					className="w-[24px] h-[24px] text-gray-700"
					svgIcon={showDropdown ? ChevronUp : ChevronDown}
				/>
			</div>

			{showDropdown && (
				<div
					className={classNames(
						" w-[552px] mt-1 overflow-hidden bg-white border border-gray-200 rounded-md h-[426px] overflow-y-auto bb-scrollbar-darker",
						floating ? "absolute" : "relative"
					)}
					id="CustomListAutocomplete-element"
				>
					<div className="relative flex justify-between pt-[8px] px-[12px] pb-[12px] gap-3">
						<div className="w-full">
							<SearchField
								handleChange={handleSearchChange}
								handleReset={() => {
									setSearchValue("");
									handleSelect();
								}}
								icon={<Icon className="mt-1" icon={faSearch} />}
								iconPosition="left"
								name="item"
								placeholder={ts("searchInList")}
								testId={testId}
								value={searchValue}
							/>
						</div>

						<Dropdown
							classNameButton={
								sortState?.sortBy ? "bg-light-blue text-medium-blue border-medium-blue" : ""
							}
							classNameDropdown="w-[274px] right-0"
							closeByClick={false}
							data={[
								{
									title: ts("sorting.leadsASC"),
									id: "leadsASC",
									icon: <SvgIcon className="w-[24px] h-[24px]" svgIcon={LeadsASC} />,
								},
								{
									title: ts("sorting.leadsDESC"),
									id: "leadsDESC",
									icon: <SvgIcon className="w-[24px] h-[24px]" svgIcon={LeadsDESC} />,
								},
								{
									title: ts("sorting.nameASC"),
									id: "nameASC",
									icon: <SvgIcon className="w-[24px] h-[24px]" svgIcon={NameASC} />,
								},
								{
									title: ts("sorting.nameDESC"),
									id: "nameDESC",
									icon: <SvgIcon className="w-[24px] h-[24px]" svgIcon={NameDESC} />,
								},
							]}
							floating={true}
							handleSelect={(value: AutoCompleteItem) => {
								handleSortList(value);
							}}
							showTickIcon={true}
							title={ts("sort")}
						/>
					</div>
					{renderResults()}
				</div>
			)}
		</div>
	);
};
