import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import LinkedinPremium from "@/assets/icons/linkedinPremium.png";

import { useUpdateMeMutation } from "@/pages/Private/redux/account/account.api";

import {
	useCreateLiProfileVisitsMutation,
	useGetLiProfileVisitsQuery,
} from "@/pages/Private/redux/liProfileVisits/liProfileVisits.api";
import { useSearch } from "@/utils";

import { LiProfileVisits } from "../../LiProfileVisits/schema/liProfileVisits";
import { ToggleItem } from "./ToggleItem";

export const ProfileVisits: FunctionComponent<{ profileVisits: boolean }> = ({ profileVisits }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);
	const { page, limit, query } = useSearch();
	const basicProfileVisitsUrl = "https://www.linkedin.com/analytics/profile-views/";

	const [updateMe, { isLoading }] = useUpdateMeMutation();

	const [createLiProfileVisits] = useCreateLiProfileVisitsMutation();

	const { data } = useGetLiProfileVisitsQuery({
		page,
		limit,
		searchValue: query,
	});

	const handleProfileVisitsScrapingChange = async () => {
		await updateMe({ profileVisits: !profileVisits }).unwrap();

		const urlExists = data?.data?.some(
			(item: LiProfileVisits) => item.url === basicProfileVisitsUrl
		);

		if (!urlExists) {
			await createLiProfileVisits({
				name: "My Profile Visits",
				url: basicProfileVisitsUrl,
			}).unwrap();
		}
	};

	return (
		<ToggleItem
			Icon={<img alt="linkedin premium needed" className="h-[18px] mr-1" src={LinkedinPremium} />}
			description={ts("warningPremiumAccountRequired")}
			isLoading={isLoading}
			label={ts("enableProfileVisitsScraping")}
			name={ts("profileVisits")}
			value={profileVisits}
			onChange={handleProfileVisitsScrapingChange}
		/>
	);
};
