import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useCreateBlacklistMutation } from "@/pages/Private/redux/blacklist/blacklist.api";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";
import { BlacklistType } from "@/enum/blacklist.enum";

import { CreateBlacklist, CreateBlacklistSchema } from "../schema/blacklist";

interface createBlacklistModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateBlacklistModal: FunctionComponent<createBlacklistModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`blacklist.${key}`);
	const [createBlacklist, { isLoading, error }] = useCreateBlacklistMutation();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateBlacklist>({
		defaultValues: {
			domain: "",
			email: "",
			linkedinUrl: "",
		},
		resolver: zodResolver(CreateBlacklistSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateBlacklist) => {
		try {
			await createBlacklist(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	// console.log(error, formErrors);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.domain?.message}
					label={ts("domain")}
					placeholder={ts("domain")}
					{...register("domain")}
				/>

				<TextField
					error={!!errors.email?.message}
					label={ts("email")}
					placeholder={ts("email")}
					{...register("email")}
				/>

				<TextField
					error={!!errors.linkedinUrl?.message}
					label={ts("linkedinUrl")}
					placeholder={ts("linkedinUrl")}
					{...register("linkedinUrl")}
				/>

				<Dropdown
					data={[
						{
							title: BlacklistType.MARKED,
							id: 1,
						},
						{
							title: BlacklistType.BLOCKED,
							id: 2,
						},
					]}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.title) {
							setValue("type", value.title as BlacklistType);
						}
					}}
					label="Blacklist Type"
				/>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
