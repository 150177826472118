import { ChangeEvent, FunctionComponent } from "react";

import { CircularProgress } from "@mui/material";

import { InputField } from "@/components/InputField/InputField";
import { CustomColumnTypes } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";

import { useUpdateCostumColumnMutation } from "@/pages/Private/redux/list/list.api";

import { ListPerson } from "../schema/list";
import { ColumnValue } from "../schema/columnValue";
import { ColumnConfig } from "../schema/columnConfig";

export interface CustomTextColumnProps {
	showEdit?: { id: number; name: string };
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: ListPerson;
	customColumnTextValue?: string;
	setCustomColumnTextValue: (value: string | undefined) => void;
	setShowEdit: (value: { id: number; name: string }) => void;
	type: CustomColumnTypes;
}

export const CustomTextColumn: FunctionComponent<CustomTextColumnProps> = ({
	showEdit,
	columnValue,
	columnConfig,
	row,
	customColumnTextValue,
	setCustomColumnTextValue,
	setShowEdit,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateCostumColumnMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	if (showEdit?.id === row.id && showEdit?.name === columnConfig.name) {
		return (
			<InputField
				autoFocus
				handleBlur={() => {
					setShowEdit({ id: 0, name: "" });
				}}
				handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
					setCustomColumnTextValue(event.target.value);
				}}
				name={columnConfig.name}
				value={customColumnTextValue || ""}
				onKeyDown={async (e) => {
					if (e.key === "Enter" && customColumnTextValue) {
						await updatePerson({
							id: row.id,
							columnId: columnConfig.id,
							customColumnName: columnConfig.name,
							value: customColumnTextValue,
							customColumnType: type,
						}).unwrap();
						setCustomColumnTextValue(undefined);
						setShowEdit({ id: 0, name: "" });
					}
				}}
			/>
		);
	} else {
		let cVal = columnValue?.value ? (columnValue.value as string) : "";

		if (isLoading) {
			cVal = customColumnTextValue || cVal;
		}

		return (
			<button
				onClick={() => {
					setCustomColumnTextValue(columnValue?.value ? (columnValue.value as string) : "");

					setShowEdit({ id: row.id, name: columnConfig.name });
				}}
			>
				{
					<div
						className={classNames(
							"h-[26px] min-w-[100px] cursor-pointer hover:bg-brand-light hover:opacity-[0.7] leading-tight hover:text-white rounded p-1",
							"text-left"
						)}
					>
						{cVal}
					</div>
				}
			</button>
		);
	}
};
