/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { faArrowLeft, faArrowRight, faFolder, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Autocomplete, TextField } from "@mui/material";

import { BaseEditor, Descendant, Editor, Transforms } from "slate";

import { Icon, Modal } from "@/components";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";
import { AiApplyType, CustomColumnTypes, UpdateType } from "@/enum/list.enum";
import { InputField } from "@/components/InputField/InputField";
import {
	useRunAiEnrichmentMutation,
	useUpdateListColumnsMutation,
} from "@/pages/Private/redux/list/list.api";

import textIcon from "@/assets/icons/textIcon.svg";
import numbersIcon from "@/assets/icons/numbersIcon.svg";
import linkIcon from "@/assets/icons/linkIcon.svg";
import aiIcon from "@/assets/icons/aiIcon.svg";
import checkboxIcon from "@/assets/icons/checkboxIcon.svg";
import diceIcon from "@/assets/icons/diceIcon.svg";
import trashIcon from "@/assets/icons/trashIcon.svg";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Button } from "@/components/Button/Button";
import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { TextArea } from "@/components/TextArea/TextArea";

import { listSelector } from "@/pages/Private/redux/list/list.slice";

import { useAppSelector } from "@/redux/hooks";

import { CustomColumn, CustomColumnSchema } from "../schema/list";
import PromptEditor from "./PromptEditor";
import { getSuggestions, replaceWithRealValues } from "../utils/colum-utils";

export interface CreateCustomColumnModalProps {
	setOpen: (value: boolean) => void;
	open: boolean;
	listId: number;
}

export const CreateCustomColumnModal: FunctionComponent<CreateCustomColumnModalProps> = ({
	open,
	setOpen,
	listId,
}) => {
	const { t } = useTranslation();

	const { inbox } = useAppSelector(listSelector);

	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);
	const [columnType, setColumType] = useState<CustomColumnTypes>();

	const [update, { isLoading, error }] = useUpdateListColumnsMutation();
	const [runPrompt, { isLoading: promptIsLoading }] = useRunAiEnrichmentMutation();

	const [randomValues, setRandomValues] = useState<string[] | JSON>([]);
	const [randomValue, setRandomValue] = useState<string>();
	const [currentLead, setCurrentLead] = useState<number>(0);
	const [aiResult, setAiResult] = useState<string>();
	const [selectedVariable, setSelectedVariable] = useState<
		{ id: string; name: string } | undefined
	>();
	const [editorValue, setEditorValue] = useState<Descendant[]>();
	const [editor, setEditor] = useState<BaseEditor>();

	const suggestions = !!inbox[currentLead] && getSuggestions(inbox[currentLead]);

	const serialize = (nodes: any) => {
		return JSON.stringify(nodes);
	};

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		resolver: zodResolver(CustomColumnSchema),
	});

	const onSubmitSaveOnly = async (values: CustomColumn) => {
		try {
			if (columnType === CustomColumnTypes.RANDOM_VALUE) {
				values.value = randomValues;
			}

			if (columnType === CustomColumnTypes.AI_PROMPT) {
				values.value = editorValue ? serialize(editorValue) : "";
			}

			await update({
				updateType: UpdateType.CUSTOM_COLUMN,
				aiApplyType: AiApplyType.NONE,
				id: listId,
				prospectIds: inbox.slice(0, 10).map((p) => p.id),
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmit = async (values: CustomColumn) => {
		try {
			if (columnType === CustomColumnTypes.RANDOM_VALUE) {
				values.value = randomValues;
			}

			if (columnType === CustomColumnTypes.AI_PROMPT) {
				values.value = editorValue ? serialize(editorValue) : "";
			}

			await update({
				updateType: UpdateType.CUSTOM_COLUMN,
				id: listId,
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitFirst10 = async (values: CustomColumn) => {
		try {
			if (columnType === CustomColumnTypes.RANDOM_VALUE) {
				values.value = randomValues;
			}

			if (columnType === CustomColumnTypes.AI_PROMPT) {
				values.value = editorValue ? serialize(editorValue) : "";
			}

			await update({
				updateType: UpdateType.CUSTOM_COLUMN,
				aiApplyType: AiApplyType.FIRST_10,
				id: listId,
				prospectIds: inbox.slice(0, 10).map((p) => p.id),
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleRunPrompt = async () => {
		const result = await runPrompt({
			id: inbox[currentLead].id,
			prompt: replaceWithRealValues(editorValue, inbox[currentLead], suggestions),
			online: true,
		}).unwrap();

		setAiResult(result.data);
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const handlePreviousLead = () => {
		if (currentLead > 0) {
			setCurrentLead(currentLead - 1);
			setAiResult("");
		}
	};

	const handleNextLead = () => {
		if (currentLead < inbox.length - 1) {
			setCurrentLead(currentLead + 1);
			setAiResult("");
		}
	};

	return (
		<Modal
			handleClose={() => setOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={open}
			overflow={true}
			size="xs"
			submitButtonText={t("basics.confirm")}
			subtitle="Select the column type to proceed to the next step."
			title="Add Column"
		>
			<div className="flex flex-col space-y-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column’s Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>

				<Dropdown
					data={[
						{
							title: ts(CustomColumnTypes.TEXT),
							id: CustomColumnTypes.TEXT,
							icon: <img alt="textIcon" className="mr-1" src={textIcon} />,
						},
						{
							title: ts(CustomColumnTypes.NUMBER),
							id: CustomColumnTypes.NUMBER,
							icon: <img alt="numbersIcon" className="mr-1" src={numbersIcon} />,
						},
						{
							title: ts(CustomColumnTypes.LINK),
							id: CustomColumnTypes.LINK,
							icon: <img alt="linkIcon" className="mr-1" src={linkIcon} />,
						},
						{
							title: ts(CustomColumnTypes.BOOLEAN),
							id: CustomColumnTypes.BOOLEAN,
							icon: <img alt="linkIcon" className="mr-1" src={checkboxIcon} />,
						},
						{
							title: ts(CustomColumnTypes.AI_PROMPT),
							id: CustomColumnTypes.AI_PROMPT,
							icon: <img alt="linkIcon" className="mr-1" src={aiIcon} />,
						},
						{
							title: ts(CustomColumnTypes.RANDOM_VALUE),
							id: CustomColumnTypes.RANDOM_VALUE,
							icon: <img alt="linkIcon" className="mr-1" src={diceIcon} />,
						},
					]}
					floating={true}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id) {
							setValue("customColumnType", value.id as CustomColumnTypes);
							setColumType(value.id as CustomColumnTypes);
						}
					}}
					label="Custom Column Type"
				/>

				{columnType === CustomColumnTypes.RANDOM_VALUE && (
					<Modal
						handleClose={() => setOpen(false)}
						handleSave={handleSubmit(onSubmit)}
						isLoading={isLoading}
						isOpened={open}
						size="md"
						submitButtonText={t("basics.confirm")}
						title="Create Random Value Column"
					>
						<div className="text-ssm font-medium mb-4">
							<InputField
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setValue("customColumnName", event.target.value, {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
								label="Column’s Name"
								name={"name"}
								placeholder="Enter column name"
								value={getValues("customColumnName")}
							/>
						</div>

						<div className="flex flex-row items-start space-y-4">
							<InputField
								className="!mb-0 grow mr-4"
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setRandomValue(event.target.value);
								}}
								name={"randomValue"}
								placeholder="Enter value"
								value={randomValue ?? ""}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										if (randomValue) {
											setRandomValues([randomValue, ...(randomValues as string[])]);
										}

										setRandomValue("");
									}
								}}
							/>
							<div className="w-[140px] !mt-0 ml-4 flex items-start">
								<Button
									className="!mt-0"
									disabled={!randomValue}
									image={<Icon className="mr-2" icon={faPlus} />}
									size={ButtonSize.L}
									title="Add value"
									onClick={() => {
										setRandomValues([...(randomValues as string[]), randomValue ?? ""]);
										setRandomValue("");
									}}
								/>
							</div>
						</div>
						<div>
							<div className="text-ssm font-medium mt-4 mb-2">Values</div>
							{(randomValues as string[]).map((value, index) => (
								<div
									key={index}
									className="flex items-center justify-between h-[54px] px-2.5 py-4.5 rounded-xl border border-gray-200 mb-4"
								>
									<div>{value}</div>
									<button
										className="cursor-pointer"
										onClick={() => {
											const values = [...(randomValues as string[])];

											values.splice(index, 1);
											setRandomValues(values);
										}}
									>
										<img alt="trash" src={trashIcon} />
									</button>
								</div>
							))}
						</div>
					</Modal>
				)}

				{columnType === CustomColumnTypes.AI_PROMPT && (
					<Modal
						handleClose={() => setOpen(false)}
						handleSave={handleSubmit(onSubmit)}
						handleSecondSave={handleSubmit(onSubmitSaveOnly)}
						handleThirdSave={handleSubmit(onSubmitFirst10)}
						isLoading={isLoading}
						isOpened={open}
						secondSubmitButtonText={t("basics.save")}
						size="lg"
						submitButtonText={ts("applyToAll")}
						thirdSubmitButtonText={ts("applyTo10")}
						title="Create AI Column"
					>
						<div className="text-ssm font-medium mb-2">
							<InputField
								handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
									setValue("customColumnName", event.target.value, {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
								label="Column’s Name"
								name={"name"}
								placeholder="Enter column name"
								value={getValues("customColumnName")}
							/>
						</div>

						<div className="flex flex-col items-start">
							<div className="text-ssm font-medium mt-2 mb-1">Prompt editor</div>
							<div className="flex relative flex-col w-full border border-gray-300 rounded-xl">
								<div className="w-full min-h-[120px] ">
									<PromptEditor
										handleChange={(value) => {
											setEditor(value);
										}}
										handleValueChange={(value) => {
											setEditorValue(value);
										}}
										suggestions={suggestions}
									/>
								</div>
								<div className="p-2 w-full flex border-t border-gray-300 justify-between items-center">
									<div className="flex items-center">
										<div className="mr-2 text-sm text-gray-700">Select</div>
										<Autocomplete
											getOptionLabel={(option: { name: string; id: string }) => option.name}
											id="event"
											options={suggestions.map((s) => {
												return {
													name: s,
													id: s,
												};
											})}
											renderInput={(params) => {
												return (
													<TextField
														// sx={{
														// 	height: "40px",
														// }}
														{...params}
														placeholder="Variable"
													/>
												);
											}}
											size="small"
											sx={{
												width: "300px",
												"& .MuiFilledInput-root": {
													paddingTop: "4px!important",
													paddingBottom: "4px!important",
													marginBottom: "0px!important",
												},
												"&.MuiFormControl-root": {
													height: "40px",
												},
											}}
											value={selectedVariable}
											onChange={(event, value) => {
												if (value && editor) {
													// eslint-disable-next-line @typescript-eslint/no-explicit-any
													const [firstChild, ...otherChildren] = editor.children as any;

													const newChildren = [
														...(firstChild.children || []),
														{
															type: "mention",
															character: `${value.id}`,
															children: [
																{
																	text: ``,
																},
															],
														},
														{
															text: ``,
														},
													];

													const newVal = [{ children: newChildren }, ...otherChildren];

													Transforms.delete(editor as any, {
														at: {
															anchor: Editor.start(editor as any, []),
															focus: Editor.end(editor as any, []),
														},
													});

													Transforms.removeNodes(editor as any, {
														at: [0],
													});

													// Insert array of children nodes
													Transforms.insertNodes(editor as any, newVal);

													setSelectedVariable(undefined);
												}
											}}
										/>
										<div className="ml-2 text-sm text-gray-700">
											or enter{" "}
											<span className="bg-inactive-item px-1 border border-gray-200 rounded-md">
												{"{"}
											</span>{" "}
											to insert variable
										</div>
									</div>
									<div className="w-1/2 flex justify-end">
										<div className="w-1/2 mr-2 max-w-[150px]">
											<Button
												color={ButtonColor.DISABLED}
												disabled={true}
												image={<Icon className="mr-2" icon={faFolder} />}
												size={ButtonSize.S}
												title="Prompt library"
											/>
										</div>
										<div className="w-1/2 max-w-[110px]">
											<Button
												disabled={false}
												isLoading={promptIsLoading}
												size={ButtonSize.S}
												title="Run prompt"
												onClick={handleRunPrompt}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-row">
							<div className="flex flex-col grow mr-2">
								<div className="text-ssm font-medium mt-4 mb-2">Prompt preview</div>
								<TextArea
									className="!mb-0 grow mr-4 max-h-[192px]"
									name={"randomValue"}
									placeholder="Enter value"
									showError={false}
									value={replaceWithRealValues(editorValue, inbox[currentLead], suggestions) || ""}
								/>
							</div>
							<div className="flex flex-col grow ml-2">
								<div className="text-ssm font-medium mt-4 mb-2">Prompt result preview</div>
								<TextArea
									className="!mb-0 grow mr-4 max-h-[192px]"
									name={"randomValue"}
									showError={false}
									value={aiResult || ""}
								/>
							</div>
						</div>
						<div className="flex flex-row mt-5 border-t pt-5 border-gray-200">
							<div className="w-1/2 mr-2 grow">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									disabled={currentLead === 0}
									image={<Icon className="mr-2" icon={faArrowLeft} />}
									size={ButtonSize.S}
									title="Previous lead"
									onClick={handlePreviousLead}
								/>
							</div>
							<div className="w-1/2 ml-2 grow">
								<Button
									color={ButtonColor.ACTION_SECONDARY}
									disabled={currentLead === inbox.length - 1}
									iconRight={true}
									image={<Icon className="ml-2" icon={faArrowRight} />}
									size={ButtonSize.S}
									title="Next lead"
									onClick={handleNextLead}
								/>
							</div>
						</div>
					</Modal>
				)}

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</div>
		</Modal>
	);
};
