import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	ApolloPeopleRequestParams,
	ApolloSavePeopleRequestParams,
	ApolloTagRequestParams,
	MovePersonToWorkflowParams,
	UpdateApolloSavePeopleRequestParams,
} from "@/types/apollo";

import { ApollosRequestPaginationParams } from "@/types/apollos-request-params";

import { EnrichPerson } from "../../pages/Apollo/schema/enrich-person";
import {
	Apollo,
	ApolloDownloadSchema,
	ApolloPeopleResponse,
	ApolloPeopleResponseSchema,
	ApolloResponseSchema,
	ApolloSchema,
	ApolloTagResponse,
	ApolloTagResponseSchema,
	ApollosResponse,
	SavedApolloPeopleSchema,
	SavedApolloResponse,
	SavedApolloSchema,
	SavedApollosResponse,
	SavedApollosSchema,
} from "../../pages/Apollo/schema/get-apollos";
import { PeopleResponse, PeopleResponseSchema } from "../../pages/Apollo/schema/get-people";

export const apolloApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getApollos: builder.query<ApollosResponse, ApollosRequestPaginationParams>({
			query: (params) => ({
				url: "apollo",
				params: {
					...(params && { ...params }),
				},
				responseSchema: ApolloResponseSchema,
			}),
			providesTags: ["Apollos"],
		}),
		getApolloPeople: builder.query<PeopleResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "apollo/people",
				params: {
					...(params && { ...params }),
				},
				responseSchema: PeopleResponseSchema,
			}),
			providesTags: ["People"],
		}),
		getApollosForNode: builder.query<SavedApollosResponse, RequestPaginationParams>({
			query: () => ({
				url: "apollo",
				responseSchema: SavedApollosSchema,
			}),
			providesTags: [],
		}),
		getApolloTags: builder.query<ApolloTagResponse, ApolloTagRequestParams>({
			query: (body) => ({
				url: "apollo/search/tags",
				method: "POST",
				body,
				responseSchema: ApolloTagResponseSchema,
			}),
			providesTags: [],
		}),
		enrichPerson: builder.mutation<PeopleResponse, EnrichPerson>({
			query: (body) => ({
				url: "apollo/enrich/person",
				method: "POST",
				body,
				responseSchema: PeopleResponseSchema,
			}),
			invalidatesTags: ["Apollos"],
		}),
		searchApolloPeople: builder.query<ApolloPeopleResponse, ApolloPeopleRequestParams>({
			query: (body) => ({
				url: "apollo/search/people",
				method: "POST",
				body,
				responseSchema: ApolloPeopleResponseSchema,
			}),
			providesTags: [],
		}),
		searchOrganization: builder.query<ApolloPeopleResponse, ApolloPeopleRequestParams>({
			query: (body) => ({
				url: "apollo/search/organization",
				method: "POST",
				body,
				responseSchema: ApolloPeopleResponseSchema,
			}),
			providesTags: [],
		}),
		getApolloById: builder.query<SavedApolloResponse, number>({
			query: (id) => ({
				url: `apollo/${id}`,
				responseSchema: SavedApolloSchema,
			}),
			providesTags: [{ type: "Apollos", id: "DETAIL" }],
		}),
		downloadApolloById: builder.query<string, number>({
			query: (id) => ({
				url: `workflow/${id}`,
				responseSchema: ApolloDownloadSchema,
			}),
			providesTags: [],
		}),
		saveSearchApolloPeople: builder.mutation<
			ApolloPeopleRequestParams,
			ApolloSavePeopleRequestParams
		>({
			query: (body) => ({
				url: "apollo/search/people/save",
				method: "POST",
				body,
				responseSchema: SavedApolloPeopleSchema,
			}),
			invalidatesTags: ["Apollos"],
		}),
		saveSearchOrganization: builder.mutation<
			ApolloPeopleRequestParams,
			ApolloSavePeopleRequestParams
		>({
			query: ({ config, amount, name, leadsPerDay }) => ({
				url: "apollo/search/organization/save",
				method: "POST",
				body: {
					name,
					amount,
					config,
					leadsPerDay,
				},
				responseSchema: SavedApolloPeopleSchema,
			}),
			invalidatesTags: ["Apollos"],
		}),
		trigger: builder.mutation<void, number>({
			query: (id) => ({
				url: `workflow/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["Apollos"],
		}),
		duplicate: builder.mutation<void, number>({
			query: (id) => ({
				url: `apollo/duplicate/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["Apollos"],
		}),
		reset: builder.mutation<void, number>({
			query: (id) => ({
				url: `workflow/reset/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["Apollos"],
		}),
		updateApollo: builder.mutation<Partial<Apollo>, UpdateApolloSavePeopleRequestParams>({
			query: ({ id, ...body }) => ({
				url: `apollo/${id}`,
				method: "PATCH",
				body,
				responseSchema: ApolloSchema,
			}),
			invalidatesTags: ["Apollos"],
		}),
		updatePersonToNewWorkflow: builder.mutation<Partial<Apollo>, MovePersonToWorkflowParams>({
			query: ({ personId, workflowId, blacklist }) => ({
				url: `apollo/people/${personId}/assign/${workflowId}`,
				method: "PATCH",
				body: {
					blacklist: blacklist || false,
				},
				responseSchema: ApolloSchema,
			}),
			invalidatesTags: ["People"],
		}),
		deleteApollo: builder.mutation<Partial<Apollo>, number>({
			query: (id) => ({
				url: `apollo/${id}`,
				method: "DELETE",
				responseSchema: ApolloSchema,
			}),
			invalidatesTags: ["Apollos"],
		}),
	}),
});

export const {
	useGetApollosForNodeQuery,
	useGetApollosQuery,
	useGetApolloPeopleQuery,
	useLazyGetApolloTagsQuery,
	useLazySearchApolloPeopleQuery,
	useSearchApolloPeopleQuery,
	useSearchOrganizationQuery,
	useLazySearchOrganizationQuery,
	useGetApolloByIdQuery,
	useLazyGetApolloByIdQuery,
	useLazyDownloadApolloByIdQuery,
	useUpdateApolloMutation,
	useDeleteApolloMutation,
	useTriggerMutation,
	useResetMutation,
	useDuplicateMutation,
	useSaveSearchApolloPeopleMutation,
	useSaveSearchOrganizationMutation,
	useUpdatePersonToNewWorkflowMutation,
	useEnrichPersonMutation,
} = apolloApi;
export const accountApiReducer = apolloApi.reducer;
export const accountApiMiddleware = apolloApi.middleware;
