import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderMaybeColumn,
	renderAssignToColumn,
	renderConnectionsColumn,
	renderEstimatedNumEmployeesColumn,
	renderGenderColumn,
	renderGoogleMapsAverageRatingColumn,
	renderGoogleMapsColumn,
	renderGoogleMapsCompanyCityColumn,
	renderGoogleMapsCompanyCountryColumn,
	renderGoogleMapsCompanyStateColumn,
	renderGoogleMapsReviewAmountColumn,
	renderGoogleMapsSocialMediaColumn,
	renderGoogleMapsTypeColumn,
	renderGoogleMapsWebsiteColumn,
	renderIndustryColumn,
	renderLanguageColumn,
	renderMapCompanyColumn,
	renderNameColumn,
	renderOtherListsColumn,
	renderPersonCityColumn,
	renderPersonCountryColumn,
	renderPersonStateColumn,
	renderSelectColumn,
	renderTitleColumn,
	renderSeniorityColumn,
	renderMapNameColumn,
} from "../utils/allColumns";

export interface GoogleMapsTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	parts: string[];
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const GoogleMapsTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	parts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: GoogleMapsTableColumnsProps): TableColumn[] => {
	const columns: TableColumn[] = [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
	];

	if (parts.includes("prospect")) {
		columns.push(renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit));
		columns.push(renderMapCompanyColumn(ts, handleCopyClick, sortingInbox, "leftSixth"));
	} else if (parts.includes("managingDirector")) {
		columns.push(renderMapNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit));
		columns.push(renderMapCompanyColumn(ts, handleCopyClick, sortingInbox, "leftSixth"));
	} else {
		columns.push(renderMapCompanyColumn(ts, handleCopyClick, sortingInbox));
	}

	if (parts.includes("prospect")) {
		columns.push(renderGenderColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderTitleColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderSeniorityColumn(ts, handleCopyClick, sortingInbox));
	}

	columns.push(renderGoogleMapsColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsReviewAmountColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsAverageRatingColumn(ts, handleCopyClick, sortingInbox));
	columns.push(renderGoogleMapsTypeColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsSocialMediaColumn(ts));
	columns.push(renderGoogleMapsWebsiteColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsCompanyCountryColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsCompanyStateColumn(ts, sortingInbox));
	columns.push(renderGoogleMapsCompanyCityColumn(ts, sortingInbox));

	if (parts?.includes("prospect")) {
		columns.push(renderLanguageColumn(ts, sortingInbox));
		columns.push(renderPersonCityColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderPersonStateColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderPersonCountryColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderIndustryColumn(ts, handleCopyClick, sortingInbox));
		columns.push(renderConnectionsColumn(ts, sortingInbox));
	}

	columns.push(renderOtherListsColumn(ts, sortingInbox));

	return columns;
};
