import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, TextField, Typography } from "@mui/material";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteCustomEventMutation,
	useGetCustomEventsQuery,
} from "@/pages/Private/redux/customEvent/customEvent.api";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { JobScrapeStatus } from "@/enum/scrape-enum";

import { PARSED_ENV } from "@/constants";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { useAppSelector } from "@/redux/hooks";

import { CustomEvent } from "../schema/customEvent";
import { CustomEventStatus } from "./CustomEventStatus";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomEventTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`customEvent.${key}`), [t]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedCustomEvent, setSelectedCustomEvent] = useState<CustomEvent | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteCustomEvent] = useDeleteCustomEventMutation();
	const { token } = useAppSelector(authSelector);

	const { data, isLoading } = useGetCustomEventsQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<CustomEvent>[] = useMemo(() => {
		const downloadAll = async (url: string) => {
			const response = await fetch(PARSED_ENV.REACT_APP_API_URL + `/api/${url}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			});

			return await response.text();
		};

		const handleDownloadAll = async (id: number, name: string) => {
			const link = document.createElement("a");

			const csv = await downloadAll(`customEvent/download/${id}`);

			if (csv) {
				const blob = new Blob([csv], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);

				link.download = `${name}.csv` || "";

				link.href = url;

				link.click();
			}
		};

		return [
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.name}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<CustomEventStatus row={row} />
						</Stack>
					</Stack>
				),
			},

			{
				label: ts("found"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.person.filter((person) => !!person.prospect?.firstName).length} /{" "}
								{row?.person.length}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("processed"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{
									row?.person.filter((person) =>
										[JobScrapeStatus.APOLLO_ENRICHED, JobScrapeStatus.INVALID].includes(
											person.status
										)
									).length
								}{" "}
								/ {row?.person.length}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("downloads"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									cursor: "pointer",
									color: "primary.main",
								},
							}}
							onClick={() => {
								handleDownloadAll(row.id, `${row.name}`);
							}}
						>
							{`${row.name}`}
						</Box>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.CSV_ENRICHMENTS]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		];
	}, [ts, token]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleDeleteModal = (row: CustomEvent) => {
		setShowDeleteModal(true);
		setSelectedCustomEvent(row);
	};

	const handleDeleteCustomEvent = async () => {
		try {
			await deleteCustomEvent(selectedCustomEvent?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !data ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteCustomEvent}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedCustomEvent?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</div>
	);
};
