import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useCreateLiPostMutation } from "@/pages/Private/redux/liPost/liPost.api";

import { Radio } from "@/components/Radio/Radio";

import { ERROR_TYPE, getAllErrors, renderErrorMessages, useSearch } from "@/utils";

import { LiPostContentType, LiPostSort } from "@/enum/li-post.enum";

import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import {
	LinkedinPersonSuggestion,
	LinkedinPersonSuggestionItem,
} from "@/components/LinkedinPersonSuggestion/LinkedinPersonSuggestion";

import {
	useLazyGetCompanySuggestionQuery,
	useLazyGetPersonSuggestionQuery,
} from "@/pages/Private/redux/liPeople/liPeople.api";

import {
	LinkedinOrganizationSuggestion,
	LinkedinOrganizationSuggestionItem,
} from "@/components/LinkedinOrganizationSuggestion/LinkedinOrganizationSuggestion";

import { CreateLiPost, CreateLiPostSchema } from "../schema/liPost";

import { LinkedinTagAutocompleteSingle, LinkedinParams } from "./LinkedinTagAutocomplete";

interface CreateLiPostModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateLiPostModal: FunctionComponent<CreateLiPostModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPost.${key}`);
	const [createLiPost, { error, isLoading }] = useCreateLiPostMutation();
	const { query, setSearchValue } = useSearch();
	const [personSuggestions, setPersonSuggestions] = useState<LinkedinPersonSuggestionItem[]>();
	const [organizationSuggestions, setOrganizationSuggestions] =
		useState<LinkedinPersonSuggestionItem[]>();

	const [fetchPeople] = useLazyGetPersonSuggestionQuery();

	const [fetchCompanies] = useLazyGetCompanySuggestionQuery();

	useEffect(() => {
		if (query) {
			fetchCompanies({ keyword: query }).then((results) => {
				const optionValues = results?.data?.suggestions;

				if (optionValues) {
					setOrganizationSuggestions([...optionValues]);
				}
			});
		}
	}, [fetchCompanies, query]);

	useEffect(() => {
		if (query) {
			fetchPeople({ keyword: query }).then((results) => {
				const optionValues = results?.data?.suggestions;

				if (optionValues) {
					setPersonSuggestions([...optionValues]);
				}
			});
		}
	}, [fetchPeople, query]);

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateLiPost>({
		defaultValues: {
			name: "",
			query: "",
			sortBy: LiPostSort.DATE_POSTED,
			repeatDaily: false,
		},
		resolver: zodResolver(CreateLiPostSchema),
	});

	const onSubmit = async (values: CreateLiPost) => {
		try {
			await createLiPost(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<div className="text-sm">{ts("filterCriteria")}</div>
				<TextField
					error={!!errors.query?.message}
					label={ts("query")}
					name="query"
					placeholder={ts("query")}
					type="text"
					value={getValues("query") || ""}
					onChange={(e) => {
						setValue("query", e.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
				/>

				<Radio
					isChecked={getValues("sortBy") === LiPostSort.DATE_POSTED}
					name="sortBy"
					onChange={() => {
						setValue("sortBy", LiPostSort.DATE_POSTED, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
				>
					{ts(LiPostSort.DATE_POSTED)}
				</Radio>

				<Radio
					isChecked={getValues("sortBy") === LiPostSort.RELEVANCE}
					name="sortBy"
					onChange={() => {
						setValue("sortBy", LiPostSort.RELEVANCE, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
				>
					{ts(LiPostSort.RELEVANCE)}
				</Radio>

				<TextField
					error={!!errors.authorJobTitle?.message}
					label={ts("authorJobTitle")}
					name="authorJobTitle"
					placeholder={ts("authorJobTitle")}
					type="text"
					value={getValues("authorJobTitle") || ""}
					onChange={(e) => {
						setValue("authorJobTitle", e.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
				/>

				<Dropdown
					data={[
						{
							title: "None",
							id: null,
						},
						{
							title: ts(LiPostContentType.PHOTOS),
							id: LiPostContentType.PHOTOS,
						},
						{
							title: ts(LiPostContentType.VIDEOS),
							id: LiPostContentType.VIDEOS,
						},
						{
							title: ts(LiPostContentType.LIVE_VIDEOS),
							id: LiPostContentType.LIVE_VIDEOS,
						},
						{
							title: ts(LiPostContentType.COLLABORATIVE_ARTICLES),
							id: LiPostContentType.COLLABORATIVE_ARTICLES,
						},
						{
							title: ts(LiPostContentType.DOCUMENTS),
							id: LiPostContentType.DOCUMENTS,
						},
					]}
					floating={false}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id) {
							setValue("contentType", value.id as LiPostContentType);
						}
					}}
					label={ts("ContentType")}
				/>

				<div className="flex flex-col">
					<div className="text-smd font-semibold mb-2">{ts("scrapeDaily")}</div>
					<Checkbox
						className="mb-8"
						isChecked={getValues("repeatDaily")}
						onChange={() =>
							setValue("repeatDaily", !getValues("repeatDaily"), {
								shouldDirty: true,
								shouldValidate: true,
							})
						}
					>
						{ts("scrapeDaily")}
					</Checkbox>
				</div>

				<label className="text-sm mb-1">{ts("fromMember")}</label>
				<LinkedinPersonSuggestion
					data={personSuggestions || []}
					floating={true}
					handleSearch={(val) => setSearchValue(val)}
					handleSelect={function (value?: LinkedinPersonSuggestionItem | undefined): void {
						if (value) {
							setValue("fromMember", value, {
								shouldDirty: true,
								shouldValidate: true,
							});

							setPersonSuggestions([]);
							setSearchValue("");
						} else {
							setValue("fromMember", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}
					}}
					selectedItem={(getValues("fromMember") as LinkedinPersonSuggestionItem) || undefined}
				/>

				<label className="text-sm mb-1">{ts("fromOrganization")}</label>
				<LinkedinOrganizationSuggestion
					data={organizationSuggestions || []}
					floating={true}
					handleSearch={(val) => setSearchValue(val)}
					handleSelect={function (value?: LinkedinOrganizationSuggestionItem | undefined): void {
						if (value) {
							setValue("fromOrganization", value, {
								shouldDirty: true,
								shouldValidate: true,
							});

							setOrganizationSuggestions([]);
							setSearchValue("");
						} else {
							setValue("fromOrganization", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}
					}}
					selectedItem={
						(getValues("fromOrganization") as LinkedinOrganizationSuggestionItem) || undefined
					}
				/>

				<label className="text-sm mb-1">{ts("authorCompany")}</label>
				<LinkedinOrganizationSuggestion
					data={organizationSuggestions || []}
					floating={true}
					handleSearch={(val) => setSearchValue(val)}
					handleSelect={function (value?: LinkedinOrganizationSuggestionItem | undefined): void {
						if (value) {
							setValue("authorCompany", value, {
								shouldDirty: true,
								shouldValidate: true,
							});

							setOrganizationSuggestions([]);
							setSearchValue("");
						} else {
							setValue("authorCompany", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}
					}}
					selectedItem={
						(getValues("authorCompany") as LinkedinOrganizationSuggestionItem) || undefined
					}
				/>

				<LinkedinTagAutocompleteSingle
					handleValueChange={(value: LinkedinParams | null) => {
						if (value) {
							setValue("authorIndustry", value, {
								shouldDirty: true,
								shouldValidate: true,
							});
						} else {
							setValue("authorIndustry", null);
						}
					}}
					type={"industry"}
					value={getValues("authorIndustry") || null}
				/>

				<label className="text-sm mb-1">{ts("mentionsMember")}</label>
				<LinkedinPersonSuggestion
					data={personSuggestions || []}
					floating={true}
					handleSearch={(val) => setSearchValue(val)}
					handleSelect={function (value?: LinkedinPersonSuggestionItem | undefined): void {
						if (value) {
							setValue("mentionsMember", value, {
								shouldDirty: true,
								shouldValidate: true,
							});

							setPersonSuggestions([]);
							setSearchValue("");
						} else {
							setValue("mentionsMember", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}
					}}
					selectedItem={(getValues("mentionsMember") as LinkedinPersonSuggestionItem) || undefined}
				/>

				<label className="text-sm mb-1">{ts("mentionsOrganization")}</label>
				<LinkedinOrganizationSuggestion
					data={organizationSuggestions || []}
					floating={true}
					handleSearch={(val) => setSearchValue(val)}
					handleSelect={function (value?: LinkedinOrganizationSuggestionItem | undefined): void {
						if (value) {
							setValue("mentionsOrganization", value, {
								shouldDirty: true,
								shouldValidate: true,
							});

							setOrganizationSuggestions([]);
							setSearchValue("");
						} else {
							setValue("mentionsOrganization", null, {
								shouldDirty: true,
								shouldValidate: true,
							});
						}
					}}
					selectedItem={
						(getValues("mentionsOrganization") as LinkedinOrganizationSuggestionItem) || undefined
					}
				/>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
