import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import { useParams } from "react-router-dom";

import { Icon } from "@/components";

import { UsersTable } from "../../../Users/components/UsersTable";
import { InviteUserModal } from "../../../Users/components/InviteUserModal";

export const UserOverview: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`users.${key}`);
	const { id } = useParams();
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<>
			<Stack direction="row" justifyContent="space-between" mb={4}>
				<Typography variant="h2">{ts("title")}</Typography>
				<Button
					size="small"
					startIcon={<Icon icon={faPlus} />}
					variant="contained"
					onClick={() => setIsInviteModalOpen(true)}
				>
					{t("inviteUser.button")}
				</Button>
			</Stack>

			<UsersTable />

			{isInviteModalOpen && (
				<InviteUserModal
					companyId={id ? +id : undefined}
					isOpen={isInviteModalOpen}
					setIsOpen={setIsInviteModalOpen}
				/>
			)}
		</>
	);
};
