import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";

import { useParams } from "react-router-dom";

import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";

import downloadIcon from "@assets/icons/download.svg";
import launchIcon from "@assets/icons/launch.svg";

import {
	useGetLiEventForScrapeQuery,
	useUpdateLiEventEntryMutation,
} from "@/pages/Private/redux/liEvent/liEvent.api";
import { Icon, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { useSearch } from "@/utils";

import { LiEventScrapeStatus } from "@/enum/scrape-enum";

import { InputField } from "@/components/InputField/InputField";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { PARSED_ENV } from "@/constants";
import { Button } from "@/components/Button/Button";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { useAppSelector } from "@/redux/hooks";

import { LiEventStatus } from "./LiEventStatus";
import { LiEventJob } from "../schema/liEventEntry";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiEventJobTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`liEvent.${key}`), [t]);
	const { token } = useAppSelector(authSelector);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [filter, setFilter] = useState<LiEventScrapeStatus[]>([
		LiEventScrapeStatus.DONE,
		LiEventScrapeStatus.IN_PROGRESS,
		LiEventScrapeStatus.TRIGGERED,
	]);

	const { data: liEventData, isLoading } = useGetLiEventForScrapeQuery({
		id: +id,
		page,
		limit,
		searchValue: query,
		filter,
	});

	const [update] = useUpdateLiEventEntryMutation();

	const columns: TableColumn<LiEventJob>[] = useMemo(() => {
		const handleIgnorePatternChange = async (id: number, ignorePattern: boolean) => {
			await update({ id, ignorePattern });
		};

		return [
			{
				label: ts("allowScrape"),
				format: (row: LiEventJob) => {
					return (
						<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
							<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
								{row.status === LiEventScrapeStatus.DRAFT ? (
									<Checkbox
										isChecked={row.ignorePattern}
										onChange={() => {
											handleIgnorePatternChange(row.id, !row.ignorePattern);
										}}
									/>
								) : row.ignorePattern ? (
									<Icon icon={faCheck} />
								) : (
									<Icon icon={faXmark} />
								)}
							</Stack>
						</Stack>
					);
				},
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.status === LiEventScrapeStatus.DONE ? (
								<Link href={`/app/linkedin-events/attendees/${row.id}`}>
									<Typography color="inherit" mb={0} variant="body2">
										{row?.name}
									</Typography>
								</Link>
							) : (
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("attendees"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.liEventEntryAttendees.length > 0 ? row.liEventEntryAttendees.length - 1 : 0} /{" "}
								{row?.attendees}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<LiEventStatus status={row.status} />
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("company"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.companyName}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("eventAt"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row.eventAt
									? format(parseISO(row.eventAt), row.eventTill ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm")
									: ""}
								{row.eventTill ? ` - ${format(parseISO(row.eventTill), "dd.MM.yyyy")}` : ""}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 80,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={row.url} target="_blank">
								<Typography color="inherit" mb={0} variant="body2">
									<img alt="open link in new tab" src={launchIcon} />
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
		];
	}, [ts, update]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const downloadAll = async (url: string) => {
		const response = await fetch(PARSED_ENV.REACT_APP_API_URL + `/api/${url}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		});

		return await response.text();
	};

	const handleDownloadAllNow = async () => {
		const link = document.createElement("a");

		const csv = await downloadAll(`liEvent/${id}/download`);

		if (csv) {
			const blob = new Blob([csv], { type: "text/csv" });
			const url = window.URL.createObjectURL(blob);

			link.download = `all.csv` || "";

			link.href = url;

			link.click();
		}
	};

	const CreateCheckboxForFilter: FunctionComponent<{
		status: LiEventScrapeStatus;
		text: string;
	}> = ({ status, text }) => (
		<Checkbox
			isChecked={filter.includes(status)}
			onChange={() => {
				if (filter.includes(status)) {
					setFilter(filter.filter((f) => f !== status));
				} else {
					setFilter([...filter, status]);
				}
			}}
		>
			{text}
		</Checkbox>
	);

	return !liEventData || isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					{/* <h3 className="mb-3">{liEventData.name}</h3>
					<p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">
						<a href={liEventData.url} rel="noreferrer" target="_blank">
							{liEventData.url}
						</a>
					</p> */}
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex justify-end flex w-[auto]">
					<Button
						image={
							<img
								alt="download"
								className="mr-2 invert-[.97] sepia-[.99] saturate-[.13] hue-rotate-[221deg] brightness-[1.03];"
								src={downloadIcon}
							/>
						}
						title="Download All"
						onClick={() => {
							handleDownloadAllNow();
						}}
					/>
				</div>
			</div>
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="w-1/2 mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="w-1/2 mr-4 flex">
					<CreateCheckboxForFilter status={LiEventScrapeStatus.DONE} text="Done" />
					<CreateCheckboxForFilter status={LiEventScrapeStatus.TRIGGERED} text="Scheduled" />
					<CreateCheckboxForFilter status={LiEventScrapeStatus.IN_PROGRESS} text="In progress" />
					<CreateCheckboxForFilter status={LiEventScrapeStatus.DRAFT} text="Not yet scraped" />
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={liEventData.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={liEventData.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
		</div>
	);
};
