import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { ListOverview } from "./pages/ListOverview/ListOverview";
import { ListEdit } from "./pages/ListEdit/ListEdit";
import { ListDetail } from "./pages/ListDetail/ListDetail";
import { Unassigned } from "./pages/Unassigned/Unassigned";

export const List: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<ListOverview />} path="/" />
			<Route element={<ListEdit />} path="/:id" />
			<Route element={<ListDetail />} path="/settings/:id" />
			<Route element={<Unassigned />} path="/detail/:id" />
		</Routes>
	);
};
