import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Stack, Typography } from "@mui/material";

import { Error, CheckCircle, LinkedIn } from "@mui/icons-material";

import { useParams } from "react-router-dom";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

import { Icon, LoadingOverlay } from "@/components";

import { Enrichments } from "@/enum/enrichments.enum";

import { useGetLiProfileResultsQuery } from "@/pages/Private/redux/liProfile/liProfile.api";

import { Table, TableColumn } from "@/components/Table/Table";

import { useCopyToClipboard } from "@/utils/useCopyToClipboard";

import { LiProfileEntryResults } from "../schema/liProfileEntryResults";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiProfileResultsTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const ts = useCallback((key: string) => t(`liProfile.${key}`), [t]);
	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();

	const [page, setPage] = useState(1);
	const [allData, setAllData] = useState<LiProfileEntryResults[]>([]);

	const {
		data: liProfileData,
		isLoading,
		isFetching,
	} = useGetLiProfileResultsQuery({
		id: +id,
		page: page,
		limit: 15,
	});

	useEffect(() => {
		if (liProfileData?.data) {
			setAllData((prevData) => [...prevData, ...liProfileData.data]);
		}
	}, [liProfileData]);

	const renderStatus = (status: Enrichments) => {
		switch (status) {
			case Enrichments.DONE:
			case Enrichments.APOLLO_ENRICHED:
			case Enrichments.COMPANY_PROFILE:
				return (
					<>
						<CheckCircle color="primary" /> Found
					</>
				);
			case Enrichments.INVALID:
				return (
					<>
						<Error color="warning" />
						Not enriched
					</>
				);
			case Enrichments.DRAFT:
				return <>Draft</>;
			case Enrichments.IN_PROGRESS:
				return <>In Progress</>;
			default:
				return <></>;
		}
	};

	const handleFetchMore = () => {
		setPage(page + 1);
	};

	const tableColumns: TableColumn[] = useMemo(() => {
		const columns: TableColumn[] = [
			{
				title: ts("name"),
				width: "fit-content",
				orderTitle: "name",
				orderStatus: 0,
				uid: "name",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography
								color="inherit"
								mb={0}
								variant="body2"
								onClick={() => row?.name && handleCopyClick(row.name)}
							>
								{row?.name}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("titlePerson"),
				width: "fit-content",
				orderTitle: "title",
				orderStatus: 0,
				uid: "title",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography
								color="inherit"
								mb={0}
								variant="body2"
								onClick={() => row?.title && handleCopyClick(row.title)}
							>
								{row?.title}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("headline"),
				// width: "fit-content",
				orderTitle: "headline",
				orderStatus: 0,
				uid: "headline",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.headline}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("linkedinUrl"),
				width: "fit-content",
				orderTitle: "linkedinUrl",
				orderStatus: 0,
				uid: "linkedinUrl",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<div className="flex">
								{row.linkedinUrl && (
									<Link href={row?.linkedinUrl} target="_blank">
										<LinkedIn />
									</Link>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("content"),
				orderTitle: "content",
				orderStatus: 0,
				uid: "content",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.content && row?.content?.length > 120
									? row?.content?.slice(0, 120) + "..."
									: row.content}
							</Typography>
							<div className="flex mt-1">
								{row.contentUrl && (
									<Link href={row.contentUrl} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faArrowUpRightFromSquare} />
									</Link>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("contentResponse"),
				orderTitle: "contentResponse",
				orderStatus: 0,
				uid: "contentResponse",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.contentResponse && row?.contentResponse?.length > 120
									? row?.contentResponse?.slice(0, 120) + "..."
									: row.contentResponse}
							</Typography>
							<div className="flex mt-1">
								{row.contentResponseUrl && (
									<Link href={row.contentResponseUrl} target="_blank">
										<Icon className="h-[16px] flex mr-2" icon={faArrowUpRightFromSquare} />
									</Link>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("type"),
				width: "fit-content",
				orderTitle: "type",
				orderStatus: 0,
				uid: "type",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.type}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("country"),
				width: "fit-content",
				orderTitle: "country",
				orderStatus: 0,
				uid: "country",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.country && (
								<span
									className="cursor-pointer"
									onClick={() => row?.country && handleCopyClick(row.country)}
								>
									{row.country}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("city"),
				width: "fit-content",
				orderTitle: "city",
				orderStatus: 0,
				uid: "city",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.city && (
								<span
									className="cursor-pointer"
									onClick={() => row?.city && handleCopyClick(row.city)}
								>
									{row.city}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyName"),
				width: "fit-content",
				orderTitle: "companyName",
				orderStatus: 0,
				uid: "companyName",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyName && (
								<span
									className="cursor-pointer"
									onClick={() => row.companyName && handleCopyClick(row.companyName)}
								>
									{row.companyName}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyLinkedinUrl"),
				width: "fit-content",
				orderTitle: "companyLinkedinUrl",
				orderStatus: 0,
				uid: "companyLinkedinUrl",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<div className="flex">
								{row.companyLinkedinUrl && (
									<Link href={row?.companyLinkedinUrl} target="_blank">
										<LinkedIn />
									</Link>
								)}
							</div>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyCountry"),
				width: "fit-content",
				orderTitle: "companyCountry",
				orderStatus: 0,
				uid: "companyCountry",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyCountry && (
								<span
									className="cursor-pointer"
									onClick={() => row?.companyCountry && handleCopyClick(row.companyCountry)}
								>
									{row.companyCountry}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("companyCity"),
				width: "fit-content",
				orderTitle: "companyCity",
				orderStatus: 0,
				uid: "companyCity",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{row.companyCountry && (
								<span
									className="cursor-pointer"
									onClick={() => row?.companyCity && handleCopyClick(row.companyCity)}
								>
									{row.companyCity}
								</span>
							)}
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("status"),
				width: "fit-content",
				orderTitle: "status",
				orderStatus: 0,
				uid: "status",
				render: (row: LiProfileEntryResults) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							{renderStatus(row.status)}
						</Stack>
					</Stack>
				),
			},
		];

		return columns;
	}, [ts, handleCopyClick]);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					{/* <h3 className="mb-3">{liProfileData.data.name}</h3> */}
					{/* <p className="text-gray-700 lg:max-w-[650px] text-xs pr-5">{liProfileData.query}</p> */}
				</div>
			</div>
			<div className="relative w-full pr-10">
				<div className="w-[fit-content] overflow-visible pr-4">
					<Table
						columns={tableColumns}
						data={allData || []}
						handleFetchMore={() => {
							if (
								liProfileData?.meta?.itemCount &&
								allData.length < liProfileData?.meta?.itemCount &&
								!isFetching
							) {
								handleFetchMore();
							}
						}}
						// handleSorting={handleSorting}
						hidePagination={true}
						isFetching={isFetching}
						isLoading={isLoading}
						page={page}
						totalPages={liProfileData?.meta?.pageCount || 0}
					/>
				</div>
			</div>
			{renderSnackbar()}
		</div>
	);
};
