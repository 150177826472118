import { z } from "zod";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { CsvEnrichmentType } from "@/enum/csv-enrichment.enum";

import { ListStatus } from "@/enum/list.enum";

import { CsvEnrichmentOrganizationSchema } from "./csvEnrichmentOrganization";
import { CsvEnrichmentPersonSchema } from "./csvEnrichmentPerson";

const { REQUIRED } = ERROR_TYPE;

export const CsvEnrichmentSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	status: z.nativeEnum(JobsScrapeStatus),
	people: z.array(CsvEnrichmentPersonSchema),
	organizations: z.array(CsvEnrichmentOrganizationSchema),
	allCount: z.number().nullable(),
	doneCount: z.number().nullable(),
	processedCount: z.number().nullable(),
	amountInCsv: z.number(),
	type: z.nativeEnum(CsvEnrichmentType),
	activeStatus: z.nativeEnum(ListStatus),
});

export const CreateCsvEnrichmentSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	file: z.string(),
	withEmail: z.boolean(),
});

export const CsvEnrichmentResponseSchema = z.object({
	data: z.array(CsvEnrichmentSchema),
	meta: MetaDataSchema,
});

export type CsvEnrichment = z.infer<typeof CsvEnrichmentSchema>;

export type CreateCsvEnrichment = z.infer<typeof CreateCsvEnrichmentSchema>;

export type CsvEnrichmentResponse = z.infer<typeof CsvEnrichmentResponseSchema>;
