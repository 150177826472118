import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

import BookIcon from "@assets/icons/dashboard.svg";
import { SearchField } from "@/components/SearchField/SearchField";
import { classNames } from "@/utils/classNames";

import { Icon } from "../Icon/Icon";

export interface AutoCompleteItem {
	id: number | string;
	title: string;
}

export interface AutoCompleteProps {
	handleSelect: (value?: AutoCompleteItem) => void;
	handleSearch?: (value: string) => void;
	selectedItem: string;
	data: AutoCompleteItem[];
	label?: string;
	testId?: string;
	floating?: boolean;
	icon?: IconDefinition;
}

export const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
	handleSelect,
	handleSearch,
	selectedItem,
	data,
	label,
	testId,
	floating,
	icon,
}) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");

	// Dropdown Logic
	const [showDropdown, setShowDropdown] = useState(false);
	const selectFieldRef = useRef<HTMLDivElement>(null);

	const handleSelectItem = (selected: AutoCompleteItem) => {
		handleSelect(selected);
		setShowDropdown(false);
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectedItem = data.find((item) => item.title === e.target.value);

		handleSearch && handleSearch(e.target.value);

		setSearchValue(e.target.value);

		if (selectedItem) {
			handleSelect(selectedItem);
		}

		setShowDropdown(true);
	};

	useEffect(() => {
		const handleClick = ({ target }: MouseEvent) => {
			if (!(selectFieldRef.current && selectFieldRef.current?.contains(target as Node))) {
				if (showDropdown) {
					setShowDropdown(false);
				}
			}
		};

		window.addEventListener("click", handleClick);

		return () => window.removeEventListener("click", handleClick);
	}, [selectFieldRef, showDropdown]);

	useEffect(() => {
		if (selectedItem) {
			setSearchValue(selectedItem);
		}
	}, [selectedItem]);

	// Result rendering
	const renderResults = () => {
		const results = data.filter((item) => {
			return item.title
				.toLowerCase()
				.replace(" ", "")
				.includes(searchValue.toLowerCase().replace(" ", ""));
		});

		if (!data.length) {
			return <p className="py-2.5 px-3 text-sm italic text-gray-700">{t("basics.noResults")}</p>;
		}

		return (
			<div className={results.length ? "border-b border-b-border" : ""}>
				{results.map((item) => {
					const { id, title } = item;

					return (
						<button
							key={`option-select-${id}`}
							className={classNames(
								"py-2.5 px-3 pr-8 text-sm text-gray-900 bg-white hover:bg-gray-100 duration-200 cursor-pointer relative"
							)}
							onClick={() => handleSelectItem({ id, title })}
						>
							{title}
						</button>
					);
				})}
			</div>
		);
	};

	const handleClickInput = () => setShowDropdown(!showDropdown);

	return (
		<div ref={selectFieldRef} className="relative z-20 w-full">
			{label && (
				<label className="block mb-2 text-sm font-medium text-gray-900 appearance-none">
					{label}
				</label>
			)}
			<div className="relative">
				{handleSearch ? (
					<SearchField
						handleChange={handleSearchChange}
						handleReset={() => {
							setSearchValue("");
							handleSelect();
						}}
						icon={
							icon ? (
								<Icon className="h-[14px] mt-1" icon={icon} />
							) : (
								<img alt="Book icon" className="mt-0.5" src={BookIcon} width={15} />
							)
						}
						name="item"
						placeholder={t("basics.pleaseSelect")}
						testId={testId}
						value={searchValue}
						onClick={handleClickInput}
					/>
				) : (
					""
				)}
			</div>
			{showDropdown && (
				<div
					className={classNames(
						"w-full mt-1 overflow-hidden bg-white border border-gray-200 rounded-md max-h-[220px] overflow-y-auto bb-scrollbar-darker",
						floating ? "absolute" : "relative"
					)}
				>
					{renderResults()}
				</div>
			)}
		</div>
	);
};
