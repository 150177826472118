import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ListStatus } from "@/enum/list.enum";

import { LiCompanyActivityEntrySchema } from "./liCompanyActivityEntry";

const { REQUIRED } = ERROR_TYPE;

export const LiCompanyActivitySchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	scrapeFollowers: z.boolean(),
	activeStatus: z.nativeEnum(ListStatus),
	liCompanyActivityEntries: z.array(LiCompanyActivityEntrySchema).optional(),
});

export const CreateLiCompanyActivitySchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	scrapeFollowers: z.boolean(),
});

export const UpdateLiCompanyActivitySchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	url: z.string().min(4, { message: REQUIRED }).trim(),
	scrapeFollowers: z.boolean(),
});

export const LiCompanyActivityResponseSchema = z.object({
	data: z.array(LiCompanyActivitySchema),
	meta: MetaDataSchema,
});

export type LiCompanyActivity = z.infer<typeof LiCompanyActivitySchema>;

export type CreateLiCompanyActivity = z.infer<typeof CreateLiCompanyActivitySchema>;

export type UpdateLiCompanyActivity = z.infer<typeof UpdateLiCompanyActivitySchema>;

export type LiCompanyActivityResponse = z.infer<typeof LiCompanyActivityResponseSchema>;
