export default {
	translation: {
		usage: {
			filtered: "Filtered",
			identified: "Identified",
			time: "Time",
			used: "Used",
			notUsed: "Not Used",
			filteredProspects: "Filtered Prospects",
			identifiedProspects: "Identified Prospects",
			aiInputTokens: "AI Input Tokens",
			aiOutputTokens: "AI Output Tokens",
		},
		liPost: {
			update: {
				title: "Update Linkedin Posts",
			},
			create: {
				title: "Create Linkedin Posts",
				button: "Create Linkedin Posts",
			},
			delete: {
				title: "Delete Linkedin Posts",
			},
			scrapeCriteria: "Scrape Criteria",
			filterCriteria: "Filter Criteria",
			name: "Name",
			search: "Search",
			title: "Linkedin Posts",
			subtitle: "Your Linkedin Post scrape jobs",
			query: "Query",
			date_posted: "Date Posted",
			relevance: "Relevance",
			authorJobTitle: "Author Job Title",
			contentType: "Content Type",
			ContentType: "Content Type",
			photos: "Photos",
			videos: "Videos",
			liveVideos: "Live Videos",
			collaborativeArticles: "Collaborative Articles",
			documents: "Documents",
			status: "Status",
			fromMember: "From Member",
			fromOrganization: "From Organization",
			authorCompany: "Author Company",
			authorIndustry: "Author Industry",
			mentionsMember: "Mentions Member",
			mentionsOrganization: "Mentions Organization",
			scrapeDaily: "Scrape Daily",
		},
		cron: {
			title: "Cron Jobs",
			subtitle: "Here you can find all your cron jobs",
			search: "Search for cron job",
			name: "Name",
			status: "Status",
			recordId: "Record ID",
			recordName: "Record Name",
			priority: "Priority",
			nextExecution: "Next Execution",
			startedAt: "Started At",
		},
		requests: {
			application: "Application",
			requests: "Requests",
			costs: "Costs",
			time: "Time",
			companyId: "Company ID",
		},
		map: {
			title: "Google Maps",
			subtitle: "Here you can find all your Google Maps scrapings",
			companyName: "Name",
			reviewCount: "Amount of ratings",
			rating: "Rating",
			type: "Type",
			socialMedia: "Social Media",
			companyEmail: "Email",
			website: "Website",
			companyCountry: "Company Country",
			companyCity: "Company City",
			companyState: "Company State",
			status: "Status",
			countOfResults: "Count of Results",
			scrapeDaily: "Scrape daily",
			repeatDaily: "Repeat daily",
			create: {
				title: "Create new Google Maps scraping",
			},
			delete: {
				title: "Delete Google Maps scraping",
			},
			name: "Name",
			query: "Search query",
			limit: "Limit",
			lat: "Latitude",
			lng: "Longitude",
			region: "Region",
			zoom: "Zoom",
			language: "Language",
			getInfoAt: "Search for company-wide Info@ e-mail address",
			getEnrichment: "Complete enrichment to search for special positions later on",
			getManagingDirector: "Search managing director from imprint etc",
			moreInformation: "For more information visit:",
			latPlaceholder: "41,12456",
			lngPlaceholder: "29,12456",
			limitPlaceholder: "100",
			queryPlaceholder: "Restaurants in London, United Kingdom",
			zoomPlaceholder: "10",
			languagePlaceholder: "en",
			regionPlaceholder: "uk",
			scrapeLevel: "Scrape Level",
			phoneNumber: "Phone Number",
			tabs: {
				results: "Results",
				costs: "Costs",
			},
		},
		companies: {
			title: "Other Organizations",
			search: "Search for company",
			name: "Name",
			linkedinUrl: "LinkedIn URL",
			users: "Users",
			autoInvite: "Auto Invite",
			create: {
				button: "Create New Company",
				title: "Create New Company",
			},
			delete: {
				title: "Delete Company",
			},
			update: {
				title: "Update Company",
			},
		},
		company: {
			title: "Your Company",
			tabs: {
				general: "General",
				user: "Users",
				roles: "User roles",
				apiKeys: "API Keys",
				costs: "Costs",
				blacklist: "Blacklist",
				usage: "Usage",
			},
			companyInformation: "Company Information",
			autoInvite: "Auto Invite to your company linkedin page",
			apolloApiKey: "Apollo API Key",
			lemlistApiKey: "Lemlist API Key",
		},
		blacklist: {
			title: "Blacklist",
			search: "Search for blacklist",
			domain: "Domain",
			email: "Email",
			linkedinUrl: "LinkedIn URL",
			type: "Type",
			create: {
				button: "Create New Blacklist",
				title: "Create New Blacklist",
				upload: "Upload Blacklist",
			},
			delete: {
				title: "Delete Blacklist",
				confirmation: "Are you sure you want to delete this blacklist?",
			},
		},
		score: {
			title: "Lead Scoring",
			subtitle: "Here you can find all your lead scores",
			create: {
				button: "Create New Score",
				title: "Create New Score",
			},
			description: "Description",
			search: "Search for score",
			name: "Name",
			TITLE: "Title",
			SENIORITY: "Seniority",
			LOCATION: "Location",
			COMPANY_LOCATION: "Company Location",
			EMPLOYEES: "Employees",
			INDUSTRY: "Industry",
			KEYWORDS: "Keywords",
			NUMBER_IN_LEAD_FILTER: "Number in Lead Filter",
		},
		inbox: {
			headline: "Inbox",
			subtitle: "Here you can find all your potential prospects",
			list: "List",
			status: "Status",
			name: "Name",
			title: "Title",
			level: "Level",
			campaign: "Campaign",
			company: "Company",
			connections: "Connections",
			industry: "Industry",
			companyStats: "Company Stats",
			companyFounded: "Company Founded",
			companyEmployees: "#Employees",
			personLocation: "Person Location",
			companyLocation: "Company Location",
			language: "Language",
			gender: "Gender",
			founded: "Founded",
			jobs: "Jobs",
			tabs: {
				unassigned: "Unassigned",
				assigned: "Assigned",
				blacklist: "Blacklist",
				notNow: "Contact later",
			},
			otherLists: "Other Lists",
			otherEvents: "Events",
			event: "Event",
			eventAttendees: "Attendees",
			eventAt: "Event at",
			assignTo: "Assign to",
			companyName: "Company Name",
			googleMaps: "Google Maps",
			reviewCount: "Amount of ratings",
			rating: "Rating",
			type: "Type",
			socialMedia: "Social Media",
			website: "Website",
			phoneNumber: "Phone Number",
			companyCountry: "Company Country",
			companyCity: "Company City",
			companyState: "Company State",
			city: "City",
			state: "State",
			country: "Country",
			selectColumns: "Select columns",
			close: "Close",
			companyPhone: "Company Phone",
			jobTitle: "Job Title",
			postedAt: "Posted At",
			employmentType: "Employment Type",
			TEXT: "Text",
			NUMBER: "Number",
			LINK: "Link",
			BOOLEAN: "Checkbox",
			AI_PROMPT: "AI Prompt",
			RANDOM_VALUE: "Random Value",
			delete: {
				title: "Delete Column",
				confirmation: "Are you sure you want to delete this column?",
			},
			POST: "LinkedIn Profile Posts",
			COMMENT: "LinkedIn Profile Comments",
			LIKE_TO_POST: "LinkedIn Profile Likes to Post",
			LIKE_TO_COMMENT: "LinkedIn Profile Likes to Comment",
			COMMENT_TO_POST: "LinkedIn Profile Comments to Post",
			content: "Post",
			contentResponse: "Comment",
			confirmAssignment: "Confirm Assignment",
			revertAssignment: "Revert Assignment",
			confirmRevertAssignment: "Confirm Revert Assignment",
			reassign: "Reassign",
			saveForLater: "Save for later",
			excludeFromList: "Exclude from list",
			addToList: "Add to list",
			toBlackList: "To Blacklist",
			reportRecord: "Report Record",
			bulkStatusChangeSuccess: "Status changed successfully",
			addToBlackListSuccess: "Added to blacklist successfully",
			reportReasonSent: "Report reason sent successfully",
			bulkAssignSuccess: "Assigned successfully",
			bulkAssignRevertSuccess: "Reverted successfully",
			addingToBlacklist: "Adding to blacklist",
			addingToBlacklistConfirmationMessage: `Are you sure you want to add this lead to the Blacklist? This action is permanent and
			cannot be undone. Please specify if you want to add only this person to the blacklist
			or the entire company.`,
			addEntireCompanyToBlacklist: "Add entire company to blacklist",
			saveForLaterConfirmationMessage: "Add this lead to check later?",
			excludeFromListConfirmationMessage:
				"Are you sure you want to exclude this lead from the list?",
			addToListConfirmationMessage: "Are you sure you want to add this lead to the list?",
			specifyReason: "Please, specify the reason of reporting record.",
			specifyProblem: "Please, specify the problem.",
			revert: "Revert",
			checkLater: "Check later",
			addColumn: "Add Column",
			filters: "Filters",
			columns: "Columns",
			foundedYear: "Founded Year",
			likes: "Likes",
			comments: "Comments",
			shares: "Shares",
		},
		dashboard: {
			title: "Welcome",
		},
		list: {
			title: "Lead Lists",
			search: "Search for lists...",
			searchInList: "Search in list...",
			sort: "Sort",
			name: "Name",
			source: "Source",
			sourceType: "Source Type",
			leadFilter: "Lead Filter",
			accountFilter: "Account Filter",
			processingStatus: "Processing Status",
			enrichments: "Enrichments",
			downloads: "Downloads",
			allIncludingExcluded: "All including excluded",
			editList: "Edit List",
			deleteList: "Delete List",
			resetList: "Reset List",
			download: "Download",
			create: {
				button: "Create New List",
				title: "Create New List",
			},
			delete: {
				title: "Delete List",
				confirmation: "Are you sure you want to delete this list?",
			},
			reset: {
				title: "Reset List and remove assigned prospects",
				confirmation: "Are you sure you want to reset this list?",
			},
			processed: "Processed",
			found: "Found",
			downloadSelected: "Download selected",
			subtitle: "Your Lead Lists",
			sources: {
				title: "Sources",
				search: "Search for a source",
				name: "Name",
				subtitle: "Choose which source you want to use",
			},
			account: {
				title: "Company Filter",
				search: "Search for company filter",
				name: "Name",
				subtitle: "Choose a company filter",
			},
			scores: {
				title: "Lead Scoring",
			},
			lead: {
				title: "Department Filter",
				search: "Search for department filter",
				name: "Name",
				subtitle: "Choose a department filter",
				type: "Typ",
			},
			aiEnrichment: {
				title: "Enrichments",
				search: "Search for Enrichment",
				name: "Name",
				subtitle: "Choose Enrichments",
				type: "Enrichment Type",
			},
			campaign: {
				title: "Campaigns",
				search: "Search for campaigns",
				name: "Name",
				subtitle: "Choose a target campaign",
				sync: "Sync campaigns",
			},
			settings: {
				title: "Settings",
				subtitle: "Settings",
				name: "Name",
				maxProspects: "Maximum prospects overall",
				maxProspectsPerDay: "Maximum prospects per day",
				withEmail: "with email addresses",
				once: "Once",
				ongoing: "On Going",
			},
			additionalSources: {
				title: "Person filter",
				search: "Search for additional source",
				name: "Name",
				subtitle: "Choose additional sources",
			},
			status: {
				title: "Status",
				DONE: "Done",
				WAITING: "Waiting",
				IDLE: "Idle",
				PROCESSING: "Processing",
				IN_PROGRESS: "Processing",
				DRAFT: "Created",
				ERROR: "Error",
			},
			ListType: "Source Type",
			searchBase: "Search and filter basis",
			regularity: "Regularity",
			log: {
				tabs: {
					settings: "Configuration",
					logs: "Logs",
					costs: "Costs",
				},
			},
			iteration: "Iteration",
			automaticUpdate: "Automatic update",
			loadMore: "Load more",
			lastModifiedAt: "Last modified at",
			lastModifiedBy: "Last modified by",
			newLeads: "New leads",
			savedForLater: "Saved for later",
			activity: {
				all: "All",
				running: "Running",
				stopped: "Stopped",
			},
			sorting: {
				nameASC: "Sort A -> Z",
				nameDESC: "Sort Z -> A",
				leadsASC: "Leads Ascending",
				leadsDESC: "Leads Descending",
			},
		},
		enum: {
			searchBase: {
				PERSON: "People from source. Can be filtered.",
				COMPANY: "Companies from source. Needs person filter to get employees.",
				SOURCE: "Google Maps / Jobs without people. Only info@ email addresses.",
			},
		},
		northdata: {
			title: "Federal Gazette",
			search: "Search for Federal Gazette",
			name: "Name",
			lastDays: "Last Days",
			status: "Status",
			type: "Type",
			create: {
				button: "Create New Federal Gazette Search",
				title: "Create New Federal Gazette Search",
			},
			subtitle: "Your Federal Gazette Searches",
			delete: {
				title: "Delete Federal Gazette Search",
			},
		},
		stepstone: {
			title: "Stepstone",
			search: "Search for Stepstone",
			name: "Name",
			status: "Status",
			subtitle: "Your Stepstone Data",
			company: "Company",
			url: "URL of Stepstone data",
			maxJobOffers: "Max Job Offers",
			email: "Email",
			found: "Found",
			processed: "Processed",
			create: {
				button: "Create new Stepstone",
				title: "Create new Stepstone",
			},
		},
		indeed: {
			title: "Indeed",
			search: "Search for indeed",
			name: "Name",
			status: "Status",
			subtitle: "Your indeed Data",
			company: "Company",
			url: "URL of Indeed data",
			maxJobOffers: "Max Job Offers",
			email: "Email",
			found: "Found",
			processed: "Processed",
			create: {
				button: "Create new Indeed",
				title: "Create new Indeed",
			},
		},
		customEvent: {
			title: "Custom Events",
			search: "Search for event",
			name: "Name",
			status: "Status",
			subtitle: "Your Сustom events",
			url: "URL of Сustom Event ",
			email: "Email",
			found: "Found",
			downloads: "Downloads",
			processed: "Processed",
			personList: "Person List",
			organizationList: "Organization List",
			create: {
				button: "Create new Сustom Event",
				title: "Create new Сustom Event",
			},
		},
		liEvent: {
			allowScrape: "Allow scrape",
			title: "LinkedIn Events",
			search: "Search for event",
			name: "Name",
			attendees: "Attendees",
			status: "Status",
			company: "Company",
			eventAt: "Event at",
			subtitle: "Your LinkedIn events",
			url: "URL of LinkedIn Event Search",
			urls: "URLs of Linkedin Event Search",
			searchTerm: "Searchterm for event search",
			lastRunAt: "Last run at",
			nextRunAt: "Next run at",
			email: "Email",
			employees: "Employees",
			annualRevenue: "Annual revenue",
			specific: "Specific Search",
			defaultSearch: "Default search",
			create: {
				button: "Create new Event Search Scraper",
				title: "Create new Event Search Scraper",
			},
			update: {
				title: "Update Event Search Scraper",
			},
			minDaysBeforeScrape: "Scrape X days before event start",
			minAttendeesBeforeScrape: "Scrape if X attendees are attending despite date",
			scrapeCriteria: "Scrape criteria",
			filterCriteria: "Filter criteria",
			profileLanguages: "Profil languages",
			english: "English",
			german: "German",
			spanish: "Spanish",
			french: "French",
			portuguese: "Portuguese",
		},
		liPeople: {
			title: "LinkedIn People Search",
			search: "Search for people",
			name: "Name",
			subtitle: "Your LinkedIn people searches",
			urls: "URLs of Linkedin People Search",
			urlTitle: "URLs",
			create: {
				button: "Create new People Search Scraper",
				title: "Create new People Search Scraper",
			},
			update: {
				title: "Update People Search Scraper",
			},
			defaultSearch: "Default search",
			specific: "Specific Search Url(s)",
			keyword: "Keyword",
			titleFreeText: "Title Freetext",
			companyFreeText: "Company Freetext",
			firstName: "Firstname",
			lastName: "Lastname",
			location: {
				label: "Location",
			},
			language: {
				label: "Language",
			},
			serviceCategory: {
				label: "Service Category",
			},
			industry: {
				label: "Industry",
			},
			schoolFreeText: "School Freetext",
			school: {
				label: "School",
			},
			specificTags: "Specific Tags",
			personalDetails: "Personal Details",
			followerOf: "Follower of",
		},
		liCompanyActivity: {
			title: "LinkedIn Company Activity",
			search: "Search for Company Activity",
			name: "Name",
			subtitle: "Your LinkedIn Company Activity",
			url: "URL of Linkedin Company Activity",
			urlTitle: "URLs",
			status: "Status",
			type: "Type",
			titlePerson: "Title",
			headline: "Headline",
			linkedinUrl: "LinkedIn URL",
			content: "Content",
			contentResponse: "Content Response",
			country: "Country",
			city: "City",
			companyName: "Company Name",
			companyLinkedinUrl: "Company LinkedIn URL",
			companyCountry: "Company Country",
			companyCity: "Company City",
			create: {
				button: "Create new Company Activity Scraper",
				title: "Create new Company Activity Scraper",
			},
			update: {
				title: "Update Company Activity Scraper",
			},
			tabs: {
				results: "Results",
				costs: "Costs",
			},
			scrapeFollowers: "Scrape Followers",
			scrapeFollowersWarning: "Attention: You need admin rights on the LinkedIn page for this",
		},
		liProfile: {
			title: "LinkedIn Profile",
			search: "Search for Profile",
			name: "Name",
			subtitle: "Your LinkedIn Profile",
			url: "URL of Linkedin Profile",
			urlTitle: "URLs",
			type: "Type",
			observeIndividual: "Observe Individual",
			individualExample: `"Hey Person, I saw you posted..."`,
			reactionsWithPerson: "Reactions with Person",
			reactedWithExample: `"Hey Xyz, I saw you liked Person's post..."`,
			titlePerson: "Title",
			headline: "Headline",
			linkedinUrl: "LinkedIn URL",
			content: "Content",
			contentResponse: "Content Response",
			country: "Country",
			city: "City",
			companyName: "Company Name",
			companyLinkedinUrl: "Company LinkedIn URL",
			companyCountry: "Company Country",
			companyCity: "Company City",
			create: {
				button: "Create new Profile Scraper",
				title: "Create new Profile Scraper",
			},
			update: {
				title: "Update Profile Scraper",
			},
			tabs: {
				results: "Results",
				costs: "Costs",
			},
			linkedinProfilePosts: "LinkedIn Profile Posts",
			linkedinProfileComments: "LinkedIn Profile Comments",
			linkedinProfileLikesToPost: "LinkedIn Profile Likes to Post",
			linkedinProfileLikesToComment: "LinkedIn Profile Likes to Comment",
			linkedinProfileCommentsToPost: "LinkedIn Profile Comments to Post",
		},
		openAi: {
			title: "OpenAI Enrichments",
			subtitle: "Enrich contacts or accounts with GPT4 from OpenAI",
			search: "Search for OpenAI",
			name: "Name",
			type: "Type",
			aiEnrichmentTarget: "Enrichments for",
			aiEnrichmentTargets: {
				contact: "Contact level",
				account: "Account level",
			},
			create: {
				button: "Create new OpenAI Enrichment",
				title: "Create new OpenAI Enrichment",
			},
			update: {
				title: "Update OpenAI Enrichment",
			},
		},
		cookie: {
			title: "Cookie",
			subtitle: "Cookie settings",
			search: "Search",
			cookie: "Cookie",
			application: "Application",
			initialConfig: "Cookie Configuration",
			username: "Username",
			password: "Password",
			apiKey: "API Key",
			user: "User",
			create: {
				button: "Create new Cookie",
				title: "Create new Cookie",
			},
			update: {
				title: "Update Cookie",
			},
		},
		sidebar: {
			searches: "Search Prospects",
			prospects: "Prospects",
			stepstone: "Stepstone",
			northdata: "Federal Gazette",
			indeed: "Indeed",
			users: "Users",
			roles: "User roles",
			logout: "Logout",
			inbox: "Inbox",
			cookie: "Cookie",
			csvEnrichment: "CSV Enrichment",
			linkedinEvents: "LinkedIn Events",
			linkedinCompanyActivity: "Company Profile Tracker",
			linkedinProfileVisits: "LinkedIn Profile Visits",
			linkedinProfile: "Person Profile Tracker",
			linkedinPeopleSearch: "LinkedIn People Search",
			openai: "AI Enrichments",
			accounts: "Accounts",
			workflow: "Workflow",
			settings: "Settings",
			accountSettings: "Account Settings",
			profile: "My Profile",
			list: "Lists",
			listBuilder: "List Builder",
			customEvent: "Custom Events",
			leadScoring: "Lead Scoring",
			dataSources: "Data Sources",
			organization: "Your organization",
			blacklist: "Blacklist",
			companies: "Other organizations",
			back: "Back to your account",
			jobs: "Jobs offers",
			maps: "Google Maps",
			linkedinPosts: "LinkedIn Posts",
			all: "All",
			otherOrganizations: "Other Organizations",
		},
		csvEnrichment: {
			title: "CSV File Enrichment",
			type: "Type",
			name: "Filename",
			amountInCsv: "Lines in CSV",
			downloads: "Download",
			found: "Found",
			processed: "Processed",
			status: "Status",
			search: "Search by filename",
			withEmail: "with email addresses",
			subtitle:
				"Enrich your CSV file with additional information. Please only use CSV files that use ',' as a separator. <br /><br />If you want to enrich a person, the file should contain at least one of the following columns or combinations of columns: <br /><strong>1. linkedinUrl, 2. email, 3. name and companyName</strong>. <br /><br />If you want to enrich a company, the file should contain the following columns: <br /><strong>1. companyLinkedinUrl, 2. website</strong>. <br /><br />If you want to enrich people based on their position in predefined companies, the file should contain the following columns: <br /><strong>1. companyName, 2. title</strong>.",
			create: {
				title: "Create New CSV Enrichment",
				button: "Upload CSV file",
			},
			delete: {
				title: "Delete CSV Enrichment",
			},
			foundFields: "The following fields have been found",
			companyEnrichment:
				"Enough data for to search for people in companies. The following methods can be applied:",
			personByTitle:
				"Enough data for to search for specified positions in companyies. The following methods can be applied:",
			tabs: {
				results: "Results",
				costs: "Costs",
			},
		},
		prospectSearch: {
			save: "Save",
			tabs: {
				people: "People",
				companies: "Organizations",
			},
			organization: {
				title: "Search Organizations",
				subtitle: "Your prospect searches",
				search: "Search",
				save: "Save",
			},
			company: "Organization",
			employees: "Employees",
			industry: "Industry",
			keywords: "Keywords",
			location: "Location",
			title: "Prospect searches",
			person_titles: {
				label: "Job Titles",
			},
			person_not_titles: {
				label: "exclude job titles",
			},
			person_seniorities: {
				label: "Seniority Level",
			},
			person_locations: {
				label: "Person location",
			},
			organization_locations: {
				label: "Company location",
			},
			organization_industry_tag_ids: {
				label: "Industry",
			},
			downloads: "Downloads",
			type: "Type",
			name: "Name",
			linkedin: "LinkedIn",

			add: "Add. Inf.",
			found: "#Prospects",
			download: "Downloads",
			leadsPerDay: "Leads per day",
			amount: "Amount",
			create: {
				button: "Create new Search",
				title: "New List/Filter",
			},
			searchButton: "Direct Search",
			scrapeProspects: "#Scrape",
			withoutEmail: "allow without email",
			status: "Status",
			search: "Search for saved prospect search",
			campaignSearch: "Search for campaign name",
			companySearch: "Search for company name",
			fromTo: "From - To",
			timeFrame: "TimeFrame",
			fromDate: "From",
			toDate: "To",
			SOURCE: "Source / Filter",
			LEAD_FILTER: "Department filter",
			LEAD_EXCLUSION: "Department exclusion",
			COUNTER: "Department size",
		},
		prospects: {
			title: "Prospects",
			search: "Search for prospect",
			campaignSearch: "Search for campaign name",
			companySearch: "Search for company name",
			company: "Company",
			campaign: "Campaign",
			email: "Email",
			name: "Name",
			linkedin: "LinkedIn",
			add: "Add. Inf.",
		},
		errors: {
			requiredFields: "Not all necessary fields are filled.",
			expectedNumber: "Number expected / enter 0 if not needed.",
			customColumnAlreadyExists: "Custom column already exists.",
			invalidEmail: "Invalid email",
			invalidPassword: "Invalid password",
			passwordGuidelinesNotMet: "Password guidelines not met",
			termsAcceptanceMissing: "Terms acceptance missing",
			invalidValue: "Invalid value",
			invalidData: "Invalid data",
			userNotConfirmed: "User not confirmed",
			passwordMismatch: "Passwords do not match",
			invalidToken: "Invalid token",
			emailAlreadyTaken: "Email already taken",
			wentWrong: "Something went wrong",
			unauthorized: "Unauthorized",
			invalidAuthCode: "Invalid auth code",
			nameMissing: "Name missing",
		},
		account: {
			connectionScraping: "LinkedIn-Connections",
			enableConnectionScraping: "active the scraping of your linkedin connections",
			enableConnectionScrapingDescription:
				"This allows you to identify which prospects you or your colleagues are already connected to on Linkedin.",
			linkedinAutomation: "LinkedIn Automation",
			enableAutoAccept: "Enable Auto Accept",
			enableAutoAcceptDescription: "Automatically accept connection requests",
			profileVisits: "Profile Visits",
			warningPremiumAccountRequired: "This feature is only available for premium accounts",
			enableProfileVisitsScraping: "Enable Profile Visits Scraping",
			changePassword: "Change password",
			currentPassword: "Current password",
			disableTwoFactorAuth: "Disable 2FA",
			enableTwoFactorAuth: "Enable 2FA",
			enableTwoFactorAuthDescription: "Scan the QR code with your authenticator app",
			newPassword: "New Password",
			profileInformation: "Profile information",
			profileSecurity: "Profile security",
			repeatPassword: "Repeat new password",
			tabs: {
				general: "General",
				notifications: "Notifications",
				linkedinConnections: "LinkedIn Connections",
			},
			title: "Account",
			linkedinConnections: "LinkedIn Connections",
		},
		job: {
			title: "Job Offers",
			jobTitle: "Job Title",
			subtitle: "Here you can search for companies that published job offers",
			search: "Search for job offer",
			name: "Name",
			status: "Status",
			company: "Company",
			url: "URL of job offer",
			employmentType: "Employment Type",
			countOfResults: "Count of Results",
			create: {
				button: "Create New Job Offer Search",
				title: "Create New Job Offer Search",
			},
			delete: {
				title: "Delete Job Offer Search",
			},
			query: "Query",
			queryPlaceholder: "Heizungsbauer in Germany",
			scrapeLevel: "Scrape Level",
			getInfoAt: "Search for company-wide Info@ e-mail address",
			getEnrichment: "Complete enrichment to search for specific positions later on",
			getManagingDirector: "Search managing director from imprint etc",
			getPersonFromOffer: "Search for person from offer",
			tabs: {
				results: "Results",
				costs: "Costs",
			},
			email: "E-mail",
			phoneNumber: "Phone Number",
		},
		basics: {
			selectColumns: "Select columns",
			wait: "Please wait",
			reset: "Reset",
			pleaseSelect: "Please select",
			noResults: "No results",
			account: "Account",
			actions: "Actions",
			admin: "Admin",
			back: "Back",
			block: "block",
			cancel: "Cancel",
			change: "Edit",
			code: "Code",
			confirm: "Confirm",
			confirmPassword: "Confirm Password",
			createSomething: "Create {{something}}",
			delete: "Delete",
			description: "Description",
			dragAndDropImage: "Drag & Drop",
			edit: "Edit",
			editSomething: "Edit {{something}}",
			email: "E-mail Address",
			enterSomething: "Enter {{something}}",
			firstName: "First Name",
			home: "Home",
			label: "Label",
			lastName: "Last Name",
			linkedinUrl: "LinkedIn URL",
			logout: "Logout",
			name: "Name",
			noData: "No Data",
			password: "Password",
			permissions: "Permissions",
			resetPassword: "Reset password",
			role: "Role",
			save: "Save",
			saveChanges: "Save changes",
			search: "Search {{something}}...",
			select: "Select",
			selectSomething: "Select {{something}}",
			setPassword: "Set password",
			status: "Status",
			termsAndConditions: "<noLink>I have read the</noLink> <link1>Terms and Conditions</link1>",
			upload: "Upload",
			value: "Value",
			warning: "Attention",
			filter: "Filter",
			clear: "Reset All Filters",
			close: "Close",
			rows: "Rows",
			columns: "Columns",
			columnsShown: "Columns shown",
			export: "Export",
		},
		errorPages: {
			buttonText: "Take Me Home",
			description:
				"You either tried some shady route or you came hee by mistake. Whichever it is, try using the navigation.",
			notFound: "404: The page you are looking for isn't here",
			serverError: "500: Internal Server Error",
			unauthorize: "401: Authorization required",
			forbidden: "403: Forbidden",
		},
		forgotPassword: {
			forgotPasswordCta: "Send reset link",
			submittedFeedbackMessage:
				"Check your inbox. We send an e-mail with instructions on how to reset your password!",
			title: "Forgot password",
		},
		inviteUser: {
			button: "Invite new user",
			modal: {
				cta: "Invite",
				emailPlaceholder: "Enter email",
				firstNamePlaceholder: "Enter first name",
				lastNamePlaceholder: "Enter last name",
				rolePlaceholder: "Select role",
				title: "Invite user",
			},
		},
		login: {
			emailPlaceholder: "Your e-mail address",
			forgotPasswordCta: "Forgot Password?",
			loginCta: "Login",
			passwordPlaceholder: "Your password",
			title: "Log in",
			invalidData: "email or password wrong",
		},
		modals: {
			addAvatar: {
				description: "<underline>Click to upload</underline> or drag and drop",
				fileSize: "Max file size is 3 MB",
				title: "Upload File",
			},
			deleteRole: {
				title: "Delete role",
			},
			deleteUser: {
				title: "Delete user",
			},
			editUser: {
				rolePlaceholder: "Select Roles",
				title: "Edit user",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Confirm",
			title: "Enter 2FA Code",
		},
		users: {
			title: "Users",
			search: "users",
		},
		userRoles: {
			title: "Roles",
			button: "User roles",
			modal: {
				create: {
					permissionPlaceholder: "Select Permissions",
					title: "Create role",
				},
				edit: {
					title: "Update role",
				},
			},
			placeholders: {
				codePlaceholder: "Enter",
				namePlaceholder: "Enter name",
			},
		},
	},
};
