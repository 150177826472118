import React from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";

import { worker as serverWorker } from "@/mocks/browser";
import { PARSED_ENV } from "@/constants/common";

import "src/utils/string.extensions";

import App from "./App";

Sentry.init({
	dsn: "https://96c447c3bd0137df325e6b63597a0965@o4506931394707456.ingest.us.sentry.io/4506931398311936",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/api\-stage\-outreachmetatool\.digital\-now\.dev\/api/],
	allowUrls: ["digital-now.dev", "signalist.de"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (PARSED_ENV.REACT_APP_NODE_ENV === "development") {
	serverWorker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
