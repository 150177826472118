import { Stack } from "@mui/material";

import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { LiEventJob } from "../../LiEvent/schema/liEventEntry";
import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderAssignToColumn,
	renderCompanyCityColumn,
	renderCompanyColumn,
	renderCompanyCountryColumn,
	renderCompanyStateColumn,
	renderConnectionsColumn,
	renderEstimatedNumEmployeesColumn,
	renderEventColumn,
	renderFoundedYearColumn,
	renderGenderColumn,
	renderIndustryColumn,
	renderLanguageColumn,
	renderLiEventAttendeesColumn,
	renderLiEventDateColumn,
	renderMaybeColumn,
	renderNameColumn,
	renderOtherListsColumn,
	renderPersonCityColumn,
	renderPersonCountryColumn,
	renderPersonStateColumn,
	renderSelectColumn,
	renderSeniorityColumn,
	renderTitleColumn,
} from "../utils/allColumns";

export interface LiEventTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const LiEventTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: LiEventTableColumnsProps): TableColumn[] => {
	return [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
		renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderGenderColumn(ts, handleCopyClick, sortingInbox),
		renderTitleColumn(ts, handleCopyClick, sortingInbox),
		renderSeniorityColumn(ts, handleCopyClick, sortingInbox),
		renderEventColumn(ts, handleCopyClick, sortingInbox),
		renderLiEventAttendeesColumn(ts, handleCopyClick, sortingInbox),
		renderLiEventDateColumn(ts, handleCopyClick, sortingInbox),
		renderConnectionsColumn(ts, sortingInbox),
		renderIndustryColumn(ts, handleCopyClick, sortingInbox),
		renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox),
		renderFoundedYearColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCityColumn(ts, handleCopyClick, sortingInbox),
		renderPersonStateColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCountryColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyStateColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
		renderLanguageColumn(ts, sortingInbox),
		renderOtherListsColumn(ts, sortingInbox),
		{
			title: ts("otherEvents"),
			width: "fit-content",
			uid: "otherEvents",
			hideable: true,
			moveable: true,
			show: true,
			render: (row: ListPerson) => (
				<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
					<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
						<div>
							{row.otherEvents?.map((liEventEntry: LiEventJob) => liEventEntry.name)?.join(",")}
						</div>
					</Stack>
				</Stack>
			),
		},
	];
};
