import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateMap,
	Map,
	MapResponse,
	MapResponseSchema,
	MapSchema,
	ToggleMap,
	ToggleMapSchema,
} from "../../pages/Map/schema/map";
import {
	MapBusinesssResponse,
	MapBusinesssResponseSchema,
} from "../../pages/Map/schema/mapBusiness";

export const mapApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMaps: builder.query<MapResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "map",
				params: {
					...(params && { ...params }),
				},
				responseSchema: MapResponseSchema,
			}),
			providesTags: ["Map"],
		}),
		getMap: builder.query<Map, number>({
			query: (id: number) => ({
				url: `map/${id}`,
				responseSchema: MapSchema,
			}),
			providesTags: ["Map"],
		}),
		getMapBusinesses: builder.query<MapBusinesssResponse, RequestPaginationParams & { id: number }>(
			{
				query: ({ id, ...params }) => ({
					url: `map/businesses/${id}`,
					params: {
						...(params && { ...params }),
					},
					responseSchema: MapBusinesssResponseSchema,
				}),
				providesTags: ["Map"],
			}
		),
		createMap: builder.mutation<Partial<Map>, CreateMap>({
			query: (body) => ({
				url: `map`,
				method: "POST",
				body,
				responseSchema: MapSchema,
			}),
			invalidatesTags: ["Map"],
		}),
		updateMap: builder.mutation<Partial<Map>, Partial<Map>>({
			query: ({ id, ...body }) => ({
				url: `map/${id}`,
				method: "PATCH",
				body,
				responseSchema: MapSchema,
			}),
			invalidatesTags: ["Map"],
		}),
		toggleMap: builder.mutation<Partial<Map>, ToggleMap>({
			query: ({ id, ...body }) => ({
				url: `map/${id}`,
				method: "PATCH",
				body,
				responseSchema: ToggleMapSchema,
			}),
		}),
		deleteMap: builder.mutation<Partial<Map>, number>({
			query: (id) => ({
				url: `map/${id}`,
				method: "DELETE",
				responseSchema: MapSchema,
			}),
			invalidatesTags: ["Map"],
		}),
	}),
});

export const {
	useGetMapsQuery,
	useLazyGetMapsQuery,
	useGetMapQuery,
	useGetMapBusinessesQuery,
	useUpdateMapMutation,
	useDeleteMapMutation,
	useCreateMapMutation,
	useToggleMapMutation,
} = mapApi;
export const accountApiReducer = mapApi.reducer;
export const accountApiMiddleware = mapApi.middleware;
