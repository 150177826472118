import { FunctionComponent } from "react";

import Email from "@assets/icons/emailIcon.svg";
import Checked from "@assets/icons/checked.svg";
import Failed from "@assets/icons/failed.svg";
import Refresh from "@assets/icons/refresh.svg";

export interface EmailIconProps {
	status: "checked" | "failed" | "refresh";
}

export const EmailIcon: FunctionComponent<EmailIconProps> = ({ status }) => {
	return (
		<div className="relative mr-1 w-[20px]">
			{status === "checked" && (
				<img alt="checked" className="absolute right-[-1px] top-[-1px]" src={Checked} />
			)}
			{status === "failed" && (
				<img alt="failed" className="absolute right-[-1px] top-[-1px]" src={Failed} />
			)}
			{status === "refresh" && (
				<img alt="refresh" className="absolute right-[-1px] top-[-1px]" src={Refresh} />
			)}
			<img alt="linkedin" src={Email} />
		</div>
	);
};
