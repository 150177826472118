import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { Modal } from "@/components";

import { useUpdateLiProfileMutation } from "@/pages/Private/redux/liProfile/liProfile.api";

import { Radio } from "@/components/Radio/Radio";
import { LiProfileEntryType } from "@/enum/liprofile.enum";

import { CreateLiProfile, CreateLiProfileSchema, LiProfile } from "../schema/liProfile";

interface updateLiProfileModalProps {
	liProfile: LiProfile;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateLiProfileModal: FunctionComponent<updateLiProfileModalProps> = ({
	liProfile,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liProfile.${key}`);

	const [filter, setFilter] = useState<LiProfileEntryType>(
		LiProfileEntryType.LINKEDIN_PROFILE_POSTS
	);
	const [selectedLiProfile, setSelectedLiProfile] = useState<LiProfile>(liProfile);

	const [updateLiProfile] = useUpdateLiProfileMutation();

	useEffect(() => {
		if (liProfile) {
			setSelectedLiProfile(liProfile);
			setFilter(liProfile.type);
		}
	}, [liProfile]);

	const {
		register,
		handleSubmit,
		// setValue,
		formState: { errors },
	} = useForm<CreateLiProfile>({
		defaultValues: {
			name: selectedLiProfile?.name ?? "",
			url: selectedLiProfile?.url ?? "",
			type: selectedLiProfile?.type ?? LiProfileEntryType.LINKEDIN_PROFILE_POSTS,
		},
		resolver: zodResolver(CreateLiProfileSchema),
	});

	const onSubmit = async (values: CreateLiProfile) => {
		try {
			await updateLiProfile({
				id: selectedLiProfile.id,
				name: values.name,
				url: values.url,
				type: filter,
			}).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	// console.log(error, formErrors);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			// isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("update.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>
				<TextField
					error={!!errors.url?.message}
					label={ts("url")}
					placeholder={ts("url")}
					{...register("url")}
				/>
				<Typography variant="subtitle1">{ts(`observeIndividuals`)}</Typography>
				<Typography className="text-gray-700 lg:max-w-[650px] text-xs" variant="subtitle2">
					{ts(`individualExample`)}
				</Typography>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_POSTS}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_POSTS)}
				>
					{t(`liProfile.linkedinProfilePosts`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS)}
				>
					{t(`liProfile.linkedinProfileComments`)}
				</Radio>
				<Typography variant="subtitle1">{ts(`reactionsWithPerson`)}</Typography>
				<Typography className="text-gray-700 lg:max-w-[650px] text-xs" variant="subtitle2">
					{ts(`reactedWithExample`)}
				</Typography>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_COMMENT}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_COMMENT)}
				>
					{t(`liProfile.linkedinProfileLikesToComment`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_POST}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_POST)}
				>
					{t(`liProfile.linkedinProfileLikesToPost`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS_TO_POST}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS_TO_POST)}
				>
					{t(`liProfile.linkedinProfileCommentsToPost`)}
				</Radio>
			</Stack>
		</Modal>
	);
};
