import { z } from "zod";

import { JobsScrapeStatus } from "@/enum/scrape-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ListStatus } from "@/enum/list.enum";

import { MapScrapeType } from "@/enum/map.enum";

import { MapBusinessSchema } from "./mapBusiness";

const { REQUIRED } = ERROR_TYPE;

export const MapSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	query: z.string().min(4, { message: REQUIRED }).trim(),
	limit: z.number().nullable(),
	lat: z.number().nullable(),
	lng: z.number().nullable(),
	zoom: z.number().nullable(),
	language: z.string().nullable(),
	region: z.string().nullable(),
	subtypes: z.array(z.string()),
	mapBusiness: z.array(MapBusinessSchema),
	status: z.nativeEnum(JobsScrapeStatus),
	mapScrapeType: z.nativeEnum(MapScrapeType),
	activeStatus: z.nativeEnum(ListStatus),
	repeatDaily: z.boolean(),
});

export const CreateMapSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	query: z.string().min(4, { message: REQUIRED }).trim(),
	limit: z.number().optional(),
	lat: z.number().optional(),
	lng: z.number().optional(),
	zoom: z.number().optional(),
	language: z.string().optional(),
	region: z.string().optional(),
	subtypes: z.array(z.string()).optional(),
	mapScrapeType: z.nativeEnum(MapScrapeType),
	repeatDaily: z.boolean(),
});

export const ToggleMapSchema = z.object({
	id: z.number(),
	activeStatus: z.nativeEnum(ListStatus),
});

export const MapResponseSchema = z.object({
	data: z.array(MapSchema),
	meta: MetaDataSchema,
});

export type Map = z.infer<typeof MapSchema>;

export type ToggleMap = z.infer<typeof ToggleMapSchema>;

export type CreateMap = z.infer<typeof CreateMapSchema>;

export type MapResponse = z.infer<typeof MapResponseSchema>;
