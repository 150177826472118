import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { LiPeopleEntrySchema } from "./liPeopleEntry";

const { REQUIRED } = ERROR_TYPE;

export const SuggestionSchema = z.object({
	urn: z.string(),
	title: z.string(),
	subtitle: z.string().optional(),
	image: z.string().optional(),
});

export const SuggestionsSchema = z.object({
	suggestions: z.array(SuggestionSchema),
});

export const PersonSearchSchema = z.object({
	fullName: z.string(),
	primarySubtitle: z.string().optional(),
	secondarySubtitle: z.string().optional(),
	profilePicture: z.string().optional(),
	navigationUrl: z.string(),
	summary: z.string().optional(),
});

export const PersonSearchesSchema = z.object({
	people: z.array(PersonSearchSchema),
	totalResults: z.number(),
});

export const LiPeopleSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	keyword: z.string().optional(),
	titleFreeText: z.string().optional(),
	companyFreeText: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	locationList: z.array(SuggestionSchema).optional(),
	languageList: z.array(SuggestionSchema).optional(),
	serviceCategoryList: z.array(SuggestionSchema).optional(),
	schoolFreeText: z.string().optional(),
	industryList: z.array(SuggestionSchema).optional(),
	schoolList: z.array(SuggestionSchema).optional(),
	follows: PersonSearchSchema.optional(),
	liPeopleEntries: z.array(LiPeopleEntrySchema).optional(),
});

export const UpdateLiPeopleSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	urls: z.array(z.string()),
});

export const LiPeopleResponseSchema = z.object({
	data: z.array(LiPeopleSchema),
	meta: MetaDataSchema,
});

export const CreateLiPeopleSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	urls: z.array(z.string()),
	keyword: z.string().optional(),
	titleFreeText: z.string().optional(),
	companyFreeText: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	locationList: z.array(SuggestionSchema).optional(),
	languageList: z.array(SuggestionSchema).optional(),
	serviceCategoryList: z.array(SuggestionSchema).optional(),
	schoolFreeText: z.string().optional(),
	industryList: z.array(SuggestionSchema).optional(),
	schoolList: z.array(SuggestionSchema).optional(),
	follows: PersonSearchSchema.optional(),
});

export type LiPeople = z.infer<typeof LiPeopleSchema>;

export type Suggestion = z.infer<typeof SuggestionSchema>;
export type Suggestions = z.infer<typeof SuggestionsSchema>;

export type PersonSearch = z.infer<typeof PersonSearchSchema>;
export type PersonSearches = z.infer<typeof PersonSearchesSchema>;

export type CreateLiPeople = z.infer<typeof CreateLiPeopleSchema>;

export type UpdateLiPeople = z.infer<typeof UpdateLiPeopleSchema>;

export type LiPeopleResponse = z.infer<typeof LiPeopleResponseSchema>;
