import { ChangeEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";

import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { Box } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { InputField } from "@/components/InputField/InputField";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { ListSourceType, ListType, SearchBase, UpdateType } from "@/enum/list.enum";
import { Radio } from "@/components/Radio/Radio";

import { ButtonColor } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useUpdateListMutation } from "@/pages/Private/redux/list/list.api";

import {
	SaveListSettings,
	SaveListSettingsValidationSchema,
	UpdateList,
	UpdateSettingsList,
} from "../schema/list";

interface SettingsListProps {
	list: UpdateSettingsList;
	setList: (param: UpdateSettingsList) => void;
	activeStepIs: (step: number) => string;
	handleBack: () => void;
	handleComplete: () => void;
	activeStep: number;
	isLoading: boolean;
}

export const SettingsList: FunctionComponent<SettingsListProps> = ({
	list,
	setList,
	activeStepIs,
	activeStep,
	handleBack,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`list.settings.${key}`);
	const tsList = (key: string) => t(`list.${key}`);
	const navigate = useNavigate();

	const [withEmail, setWithEmail] = useState<boolean>(list.withEmail || false);

	const [update, { isLoading }] = useUpdateListMutation();

	const {
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<SaveListSettings>({
		defaultValues: {
			name: list.name,
			searchBase: list.searchBase,
			maxProspects: list.maxProspects?.toString() || "",
			maxProspectsPerDay: list.maxProspectsPerDay?.toString() || "",
			type: list.type,
			withEmail: list.withEmail || false,
		},
		resolver: zodResolver(SaveListSettingsValidationSchema),
	});

	const onSubmit = async (values: SaveListSettings) => {
		try {
			let updater: Partial<UpdateList> = {
				id: list.id,
				updateType: UpdateType.SETTINGS,
			};

			if (list) {
				updater = {
					...updater,
					...{
						id: list.id,
						name: values.name,
						maxProspects: values.maxProspects ? +values.maxProspects : null,
						maxProspectsPerDay: values.maxProspectsPerDay ? +values.maxProspectsPerDay : null,
						withEmail: withEmail,
						type: values.type,
						searchBase: values.searchBase,
					},
				};
			}

			await update({ ...updater }).unwrap();
			navigate("/app/lists");
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<div className="w-full mt-8">
			<div className="flex items-end justify-between w-full mb-8">
				<div className="flex-grow">
					<h3 className="mb-3">{ts("title")}</h3>
				</div>
			</div>
			<Stack spacing={2}>
				<div className="flex flex-col">
					<div className="text-sm mb-2">
						<InputField
							handleChange={(event: ChangeEvent<HTMLInputElement>) => {
								if (event.target.value) {
									setValue("name", event.target.value, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							label={ts("name")}
							name="name"
							placeholder={ts("name")}
							value={getValues("name") || ""}
						/>
					</div>
				</div>

				<div className="flex flex-col">
					<div className="text-sm mb-2">{tsList("regularity")}</div>
					<div className="mb-2 mt-2">
						<Radio
							isChecked={getValues("type") === ListType.ONCE}
							onChange={() => {
								setValue("type", ListType.ONCE, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}}
						>
							{ts("once")}
						</Radio>
					</div>
					<div className="mb-2">
						<Radio
							isChecked={getValues("type") === ListType.ON_GOING}
							onChange={() => {
								setValue("type", ListType.ON_GOING, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}}
						>
							{ts("ongoing")}
						</Radio>
					</div>
				</div>

				{getValues("type") === ListType.ONCE && (
					<InputField
						handleChange={(event: ChangeEvent<HTMLInputElement>) => {
							if (event.target.value) {
								setList({ ...list, maxProspects: +event.target.value });
								setValue("maxProspects", event.target.value, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}
						}}
						label={ts("maxProspects")}
						name="maxProspects"
						placeholder={ts("maxProspects")}
						value={getValues("maxProspects") || ""}
					/>
				)}

				{getValues("type") === ListType.ON_GOING && (
					<InputField
						handleChange={(event: ChangeEvent<HTMLInputElement>) => {
							if (event.target.value) {
								setList({ ...list, maxProspectsPerDay: +event.target.value });
								setValue("maxProspectsPerDay", event.target.value, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}
						}}
						label={ts("maxProspectsPerDay")}
						name="maxProspectsPerDay"
						placeholder={ts("maxProspectsPerDay")}
						value={getValues("maxProspectsPerDay") || ""}
					/>
				)}

				{list.sourceType !== ListSourceType.PROSPECT_SEARCH && (
					<div className="flex flex-col">
						<div className="text-sm">{tsList("ListType")}</div>
						<div className="mb-2 mt-2">
							<Radio
								isChecked={getValues("searchBase") === SearchBase.PERSON}
								name="searchBase"
								onChange={() =>
									setValue("searchBase", SearchBase.PERSON, {
										shouldDirty: true,
										shouldValidate: true,
									})
								}
							>
								{t(`enum.searchBase.PERSON`)}
							</Radio>
						</div>
						<div className="mb-2">
							<Radio
								isChecked={getValues("searchBase") === SearchBase.COMPANY}
								name="searchBase"
								onChange={() =>
									setValue("searchBase", SearchBase.COMPANY, {
										shouldDirty: true,
										shouldValidate: true,
									})
								}
							>
								{t(`enum.searchBase.COMPANY`)}
							</Radio>
						</div>
						{[ListSourceType.MAPS, ListSourceType.JOBS].includes(list.sourceType) && (
							<div className="mb-2">
								<Radio
									isChecked={getValues("searchBase") === SearchBase.SOURCE}
									name="searchBase"
									onChange={() =>
										setValue("searchBase", SearchBase.SOURCE, {
											shouldDirty: true,
											shouldValidate: true,
										})
									}
								>
									{t(`enum.searchBase.SOURCE`)}
								</Radio>
							</div>
						)}
					</div>
				)}
				<Checkbox
					isChecked={withEmail}
					label={ts("withEmail")}
					name="withEmail"
					onChange={() => {
						setWithEmail(!withEmail);
					}}
				/>
				{getAllErrors(undefined, formErrors).length
					? renderErrorMessages(getAllErrors(undefined, formErrors))
					: null}
				<div className="w-[250px]">
					<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
						<Button
							className="mr-2"
							color={ButtonColor.ACTION_SECONDARY}
							title="Back"
							onClick={handleBack}
						/>

						<Button
							isLoading={isLoading}
							title={activeStepIs(activeStep) === "final" ? "Save" : "Next"}
							onClick={handleSubmit(onSubmit)}
						/>
					</Box>
				</div>
			</Stack>
		</div>
	);
};
