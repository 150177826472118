import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import { Sidebar } from "../Sidebar/Sidebar";
import { Topbar } from "../Topbar/Topbar";

export const MainLayout = () => {
	return (
		<Box
			sx={{
				width: "100%",
				minHeight: "100vh",
			}}
		>
			<div className="w-full flex flex-row lg:h-[100vh] bg-brand-white">
				<Sidebar />
				<div className="sm:z-10 flex-grow min-w-0 h-full bg-brand-white relative">
					<div className="flex flex-row h-12 w-full">
						<Topbar />
					</div>
					<div
						className="bg-white h-[calc(100vh-3rem)] px-8 py-8 overflow-y-visible overflow-x-auto lg:overflow-auto rounded-tl-32"
						id="outlet-container"
					>
						<Outlet />
					</div>
				</div>
			</div>
		</Box>
	);
};
